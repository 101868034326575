import React, {Component} from 'react';

import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@material-ui/icons/Clear';

import { ValidateEmail } from '../Util/Regex';
import API, {
  GetCompanyEmployeesPathForApi,
  GetCompanyEmployeePathForApi,
} from '../Util/api';
import debounce from 'es6-promise-debounce';
import ProgressIndicator from './ProgressIndicator';
import SelectControl from './SelectControl';
import AsyncSelectControl from './AsyncSelectControl';

import { IsMobile } from '../Util/MobileDetector';

const styles = theme => ({
  displayName: {
    fontSize:18,
    fontWeight:500,
  }
});

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

class EmployeesDialog extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      Employees: [],
      ShowProgressIndicatorImmediately: false,
    }
  }

  handleLoadEmployees = () => {
    this.setState({ ShowProgressIndicatorImmediately: true });
    API.get(GetCompanyEmployeesPathForApi(this.props.companyId))
      .then(resp => {
        this.setState({
          Employees: resp.data,
          ShowProgressIndicatorImmediately: false, 
        });
      })
      .catch(this.handleApiError);
  }

  updateEmployee = debounce(employee => {
    if (!employee) {
      return;
    }
    API.put(GetCompanyEmployeePathForApi(this.props.companyId, employee.EmployeeID), employee)
      .then(resp => {
      })
      .catch(this.handleApiError);
  }, 500)

  handleSignInEmailChange = employeeId => e => {
    const Employees = [...this.state.Employees];
    const employee = Employees.find(e => e.EmployeeID === employeeId);
    if (employee) {
      employee.SignInEmail = e.target.value;
      this.setState({Employees});
      this.updateEmployee(employee);
    }
  }

  handleExpenseApproverChange = employeeId => valueOrOptions => {
    const Employees = [...this.state.Employees];
    const employee = Employees.find(e => e.EmployeeID === employeeId);
    if (employee) {
      if (typeof valueOrOptions === "string") {
        employee.ExpenseApproverEmployeeID = valueOrOptions;
      } else if (valueOrOptions && valueOrOptions.value) {
        employee.ExpenseApproverEmployeeID = valueOrOptions.value;
      } else {
        employee.ExpenseApproverEmployeeID = "";
      }
      this.setState({Employees});
      this.updateEmployee(employee);
    }
  }

  handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleApiError = err => {
    this.setState({ ShowProgressIndicatorImmediately: false, });
    this.props.onApiError(err);
  }

  componentDidMount() {
    this.handleLoadEmployees();
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    const {
      Employees,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      theme,
    } = this.props;

    const getApproverListForEmployee = employee => {
      const getOption = employee => ({ label: employee.DisplayName, value: employee.EmployeeID });
      const allOptions = Employees.map(e => getOption(e)); 
      const onGetOptionsFilterPromise = filter => {
        const filteredOptions = (filter)
          ? allOptions.filter(o => o.label.toLowerCase().startsWith(filter.toLowerCase()))
          : allOptions;
        return Promise.resolve(filteredOptions);
      };
      const getOptionByEmployeeId = employeeId => {
        const employees = [...this.state.Employees];
        const employee = employees.find(e => e.EmployeeID === employeeId);
        if (employee) {
          return getOption(employee);
        }
      }
      if (IsMobile()) {
        return (
          <SelectControl
            id={`select_${employee.ID}_approver`}
            label="Expense approver" 
            options={allOptions} 
            value={employee.ExpenseApproverEmployeeID}
            onValueChange={this.handleExpenseApproverChange(employee.EmployeeID)}
          />
        );
      } else {
        return (
          <AsyncSelectControl
            // floatingOptions
            label="Expense approver" 
            onGetOptionsFilterPromise={onGetOptionsFilterPromise} 
            value={getOptionByEmployeeId(employee.ExpenseApproverEmployeeID)}
            onValueChange={this.handleExpenseApproverChange(employee.EmployeeID)}
          />
        );
      }
    }

    const employeeGridItems = Employees && Employees.map(emp => {
      const primaryEmail = (emp.PrimaryEmail)
        ? (
          <div>
            {emp.PrimaryEmail}
          </div>
        ) : null;
      return (
        <Grid item key={`gi_${emp.ID}`} xs={12}>
          <Grid container spacing={2} style={{border:"1px dashed #ccc",borderRadius:16,padding:theme.spacing(1)}}>
            <Grid item key={`gi_${emp.ID}_display`} xs={(IsMobile()) ? 12 : 3}>
              <div className={classes.displayName}>
                {emp.DisplayName}
              </div>
              {primaryEmail}
            </Grid>
            <Grid item key={`gi_${emp.ID}_signInEmail`} xs={(IsMobile()) ? 12 : 5}
              style={{marginTop:(IsMobile()) ? theme.spacing(1) : undefined}}>
              <TextField
                label="Sign-in email"
                fullWidth
                variant="outlined"
                onChange={this.handleSignInEmailChange(emp.EmployeeID)}
                helperText={(emp.SignInEmail && !ValidateEmail(emp.SignInEmail)) ? "Enter a valid e-mail" : ""}
                value={emp.SignInEmail}
                InputProps={{
                  endAdornment: (emp.SignInEmail)
                    ? (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="clear"
                          onClick={() => this.handleSignInEmailChange(emp.EmployeeID)({target:{value:""}})}
                        >
                          <ClearIcon style={{fontSize:18}} />
                        </IconButton>
                      </InputAdornment> 
                    ) : undefined,
                }}
              />
            </Grid>
            <Grid item key={`gi_${emp.ID}_approver`} xs={(IsMobile()) ? 12 : 4}>
              {getApproverListForEmployee(emp)}
            </Grid>
          </Grid>
        </Grid>
      );
    });

    const content = (
      <Grid container spacing={4} style={{alignItems:"center",marginBottom:theme.spacing(3)}}>
        {employeeGridItems}
      </Grid>
    );

    const progressIndicator = (ShowProgressIndicatorImmediately)
      ? (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      ): null;

    return (
      <Dialog
        open
        fullScreen={IsMobile()}
        fullWidth={!IsMobile()}
        maxWidth="lg"
        // TransitionComponent={Transition}
        onClose={this.handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">
          <div style={{display:"flex",textAlign:"left"}}>
            <div style={{flexGrow:1}}>
              Employees
            </div>
            <div style={{marginTop:-8,marginRight:-16}}>
              <IconButton onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {progressIndicator}

          {content}          
        </DialogContent>
      </Dialog>
    );
  }
}

EmployeesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
  onApiError: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(EmployeesDialog);
