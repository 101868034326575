import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {
  GetSingleUserPreference_String,
  SaveSingleUserPreference_String,
} from './SingleUserPreference';

export const ViewType_Card = "Card";
export const ViewType_List = "List";
// Update GetViewTypeName() after adding

export const GetIdSubPrefix = (collectionName, isMobile, isWorkspace) => {
  return ``;
}

export const GetCurrentViewType = (idPrefix, idSubPrefix, setStateFunc, defaultValue, handleApiErrorFunc) => {
  if (idPrefix) {
    return GetSingleUserPreference_String(`${idPrefix}${(idSubPrefix) ? idSubPrefix : ""}ViewType`)
      .then(viewType => {
        if (setStateFunc && typeof setStateFunc === "function") {
          if (viewType) {
            setStateFunc(viewType);
          } else if (defaultValue) {
            setStateFunc(defaultValue);
          }
        }
        return viewType || defaultValue;
      })
      .catch(err => {
        if (handleApiErrorFunc && typeof handleApiErrorFunc === "function") {
          handleApiErrorFunc(err);
        }
        if (setStateFunc && typeof setStateFunc === "function" && defaultValue) {
          setStateFunc(defaultValue);
        }
        return defaultValue;
      });
  } else {
    return Promise.resolve(null);
  }
}

export const GetNextViewType = curViewType => {
  let nextViewType = ViewType_List;
  switch (curViewType) {
    case ViewType_List:
      nextViewType = ViewType_Card;
      break;
    case ViewType_Card:
    default:
      nextViewType = ViewType_List;
      break;
  }
  return nextViewType;
}

export const HandleSetViewTypeAnchorEl = (onSetState, ViewTypeMenuAnchorEl) => {
  onSetState({ViewTypeMenuAnchorEl});
}

export const GetViewTypeName = viewType => {
  switch (viewType) {
    case ViewType_List:
      return "List view";
    case ViewType_Card:
      return "Card view";
    default:
      return "";
  }
}

export const GetViewTypeMenuForDesktopTasks = (onGetState, onSetState, onApiError, onViewTypeChanged) => {
  const anchorEl = onGetState("ViewTypeMenuAnchorEl");
  const handleSetViewType = (idPrefix, idSubPrefix, viewType) => {
    HandleSetViewTypeAnchorEl(onSetState, null);
    HandleSetViewType(onSetState, onApiError, idPrefix, idSubPrefix, viewType);
    onViewTypeChanged(viewType);
  }
  return (
    <Menu
      id="viewtype-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => HandleSetViewTypeAnchorEl(onSetState, null)}
    >
      <MenuItem onClick={() => handleSetViewType("Tasks", "Desktop", ViewType_List)}>
        {GetViewTypeName(ViewType_List)}
      </MenuItem>
      <MenuItem onClick={() => handleSetViewType("Tasks", "Desktop", ViewType_Card)}>
        {GetViewTypeName(ViewType_Card)}
      </MenuItem>
    </Menu>
  );
}

export const HandleSetViewType = (onSetState, onApiError, idPrefix, idSubPrefix, ViewType, skipServerCalls) => {
  if (idPrefix) {
    onSetState({ViewType});
    if (!skipServerCalls) {
      SaveSingleUserPreference_String(`${idPrefix}${(idSubPrefix) ? idSubPrefix : ""}ViewType`, ViewType)
        .catch(onApiError);
    }
  }
}

export const HandleCycleViewType = (onSetState, onApiError, idPrefix, idSubPrefix, curViewType, skipServerCalls) => {
  const nextViewType = GetNextViewType(curViewType);
  HandleSetViewType(onSetState, onApiError, idPrefix, idSubPrefix, nextViewType, skipServerCalls);
}