import {
  createMuiTheme,
} from '@material-ui/core/styles';

export const GetDarkMode = cookies => {
  if (cookies) {
    const darkMode = cookies.get("darkMode");
    return (darkMode === "true");
  }
  return false;
}

const saveDarkMode = (cookies, useDarkMode) => {
  if (cookies) {
    cookies.set("darkMode", useDarkMode, { path: "/", sameSite: "lax", maxAge: 60 * 60 * 24 * 365 });
  }
}

export const CycleTheme = cookies => {
  const darkMode = !GetDarkMode(cookies);
  saveDarkMode(cookies, darkMode);
}

export const hsl = (h,s,l) => `hsl(${h},${100*s}%,${100*l}%)`;

const getBaseTheme = (cookies, forceDarkMode) => {
  const useDarkMode = forceDarkMode || GetDarkMode(cookies);
  
  const textColor = hsl(0,0,.5);
  const primaryHue = 210;
  const secondaryHue = 249;
  const primaryColor = hsl(primaryHue,.8,.5);
  const secondaryColor = hsl(secondaryHue,.44,.39);
  const backgroundHue = 210;
  const background_default = "#f2f2fa";
  const background_default_dark = hsl(backgroundHue,.267,.098); 
  const background_paper = hsl(0,0,1);
  const background_paper_dark = hsl(backgroundHue,.238,.147); 
  const background_appBar_dark = hsl(backgroundHue,.292,.024); 
  const background_pane_dark = hsl(backgroundHue,.289,.049);
  const background_navRail_dark = hsl(backgroundHue,.29,.022); 
  const background_kanbanColumn_dark = hsl(backgroundHue,.267,.076); 
  const background_chip_dark = hsl(backgroundHue,.235,.233); 

  return {
    palette: {
      type: useDarkMode ? "dark" : "light",
      primary: { 
        main: primaryColor,
        contrastText: "#fff",
      },
      secondary: {
        main: secondaryColor,
        contrastText: "#fff",
      },
      background: {
        default_dark: background_default_dark,
        default: useDarkMode ? background_default_dark : background_default,
        pane_dark: background_pane_dark,
        pane: useDarkMode ? background_pane_dark : hsl(0,0,.92), 
        navRail: useDarkMode ? background_navRail_dark : hsl(0,0,.88),
        toolBar: useDarkMode ? background_paper_dark : hsl(0,0,1),
        appBar_dark: background_appBar_dark,
        appBar: useDarkMode ? background_appBar_dark : primaryColor,
        paper_dark: background_paper_dark,
        paper: useDarkMode ? background_paper_dark : background_paper,
        treeNodeHighlight: useDarkMode ? hsl(1,1,1) : background_default,//background_navRail_dark : hsl(0,0,.8), 
        cardActionBar: useDarkMode ? background_pane_dark : hsl(0,0,.92), 
        kanbanColumn: useDarkMode ? background_kanbanColumn_dark : hsl(0,0,.88),
      },
      text: {
        primary: textColor,
        primary_dark: "#fff", // This should match official value, used in one-off pages like /form submission
        disabled_dark: "hsl(0,0%,100%,50%)",
        purple: "#4137a9",
        lightBlue: "#18a6e2",
        teal: "#2cb88e",
        darkBlue: "#355bbb",
      },
    },
    typography: {
      // fontFamily: [
      //   "Roboto",
      //   "-apple-system",
      //   "BlinkMacSystemFont",
      //   "Segoe UI",
      //   "Arial",
      //   "sans-serif"
      // ].join(","),
      // fontSize: 20,
      useNextVariants: true
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          "*::-webkit-scrollbar": {
            width: 10,
            height: 10,
          },
          "*::-webkit-scrollbar-track": {
            // boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
            borderRadius: 6,
            backgroundColor: "rgba(0,0,0,0.2)",
            opacity: 0.3,
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,0.3)",
            borderRadius: 6,
          },
        },
      },
      MuiTableRow: {
        root: {
          '&.Mui-selected': {
            backgroundColor: useDarkMode ? background_default_dark : "#e0e0e0",
            "&:hover": {
              backgroundColor: useDarkMode ? background_default_dark : "#e0e0e0",
            },
          },
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: useDarkMode ? "1px solid rgba(255, 255, 255, 0.12)" : "1px solid rgba(0, 0, 0, 0.12)", // Color should be equivalent to theme.palette.divider
        },
      },
      MuiCard: {
        root: {
          borderRadius:24,
        },
      },
      MuiCardContent: {
        root: {
          padding:"24px 32px",
          '&:last-child': {
            paddingBottom:undefined, // Corrects issue with cards having mixed action area and no action area e.g. roles
          },
        },
      },
      MuiListItem: {
        root: {
          fontSize:18,
        },
      },
      MuiMenuItem: {
        root: {
          fontSize:18,
        },
      },
      MuiDialogTitle: {
        root: {
          padding: "24px 32px 8px",
          textAlign:"center",
        },
      },
      MuiDialogContent: {
        root: {
          padding: "8px 32px 24px",
        },
      },
      MuiDialogActions: {
        root: {
          padding: "16px",
          justifyContent:"center",
          paddingBottom:32,
        },
        spacing: {
          "& button": {
            margin: "0px 16px",
          },
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius:24,
        },
      },
      MuiChip: {
        root: {
          backgroundColor: useDarkMode ? background_chip_dark : "#e0e0e0",
        },
      },
      MuiInputBase: {
        input: {
          color:"#000",
        },
      },
      MuiInputAdornment: {
        positionEnd: {
          marginLeft:-8, // This corrects what appears to be unnecessary spacing between an input adornment,
          // like the clear icon to clear an input value, and a system adornment like the calendar picker icon
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: textColor,
          color: "#eee",
          borderRadius:16,
          padding:16,
          fontSize:16,
        },
      },
      MuiPopover: {
        paper: {
          // backgroundColor: textColor,
          // color: "#eee",
          borderRadius:16,
          padding:8,
          fontSize:14,
        },
      },
      MuiSwitch: {
        switchBase: {
          '&.Mui-checked': {
            color: secondaryColor,
          },
        },
      },
      MuiTabs: {
        "flexContainerVertical": {
          height:"100%",
        },
      },
      MuiButton: {
        root: {
          fontSize:18,
          textTransform:"inherit",
          borderRadius:24,
          padding:"7px 32px 6px 32px",
        },
        text: {
          padding:"7px 32px 6px 32px",
        },
        contained: {
          color:"inherit",
        },
      },
    },
  };
}

export const GetObjectWithOverrides = (obj, overrides) => {
  Object.keys(overrides).forEach(k => {
    if (obj[k] && typeof obj[k] === "object") {
      GetObjectWithOverrides(obj[k], overrides[k]);
    } else {
      obj[k] = overrides[k];
    }
  });
  return obj;
}

export const GetMuiTheme = (cookies, forceDarkMode, themeOverrides) => {
  let theme = getBaseTheme(cookies, forceDarkMode);
  if (themeOverrides) {
    theme = GetObjectWithOverrides(theme, themeOverrides);
  }
  return createMuiTheme(theme);
}