import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import UiCore from '../Components/UiCore';
import { GlobalContext } from '../Context/Global.context';

import { Player, BigPlayButton } from 'video-react';
import "video-react/dist/video-react.css";

import {
  GetPublicVideoPath,
} from '../Util/api';

const styles = theme => ({
  root: {
    width:"100%",
    height:"100%",
    marginBottom:142,
    display:"flex",
    justifyContent:"center",
  },
  videoContainer: {
    width:"75%",
    "& .video-react": {
      backgroundColor:"transparent",
    }
  },
});

class Help extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicatorImmediately: false,
    }
  }

  showLiveChatWhenReady = () => {
    if (!window.$zoho
      || !window.$zoho.salesiq
      || !window.$zoho.salesiq.floatbutton) {
      return setTimeout(() => this.showLiveChatWhenReady(), 250);
    }
    window.$zoho.salesiq.floatbutton.visible("show");
  }

  componentDidMount() {
    // Shows live chat on this page
    this.showLiveChatWhenReady();
  }

  componentWillUnmount() {
    // Hides live chat when leaving this page
    if (window.$zoho) {
      window.$zoho.salesiq.floatbutton.visible("hide");
    }
  }

  handleApiError = ApiError => {
    this.setState({ApiError});
  }

  render() {
    const {
      classes,
    } = this.props;
    const {
      ApiError,
      ShowProgressIndicatorImmediately,
    } = this.state;

    const content = (
      <div className={classes.root}>
        <div className={classes.videoContainer}>
          <Player
            style={{
              backgroundColor:"transparent",//video-react is target
            }}
            ref={(player) => { this.player = player; }}
            playsInline
            // autoPlay
            // fluid={false}
            height="100%"
            // poster={posterUri}
            src={GetPublicVideoPath("APCentral.mp4")}
          >
            <BigPlayButton position="center" />
          </Player>
        </div>
      </div>
    );

    return (
      <UiCore
        title="Help"
        content={content}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        apiError={ApiError}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Help);
