import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as ContentCopyIconSvg } from '../SVG/content_copy.svg';
import { ReactComponent as ClipboardIconSvg } from '../SVG/clipboard.svg';
import { ReactComponent as SupportIconSvg } from '../SVG/support.svg';
import { ReactComponent as QuickBooksIconSvg } from '../SVG/quickbooks.svg';
import { ReactComponent as ConnectToQuickBooksIconSvg } from '../SVG/C2QB_green_btn_tall_default.svg';

import FolderIcon from '@material-ui/icons/Folder';
import OrganizationIcon from '@material-ui/icons/Domain';
import ProfileIcon from '@material-ui/icons/Subject';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import MembersIcon from '@material-ui/icons/Group';
import OrganizationSubscriptionIcon from '@material-ui/icons/Payment';
import SettingsIcon from '@material-ui/icons/Settings';

export const SupportIcon = props => {
  return (
    <SvgIcon
      {...props}
      component={SupportIconSvg}
    />
  );
};

export const ClipboardIcon = props => {
  return (
    <SvgIcon
      {...props}
      component={ClipboardIconSvg}
    />
  );
};

export const ContentCopyIcon = props => {
  return (
    <SvgIcon
      {...props}
      component={ContentCopyIconSvg}
    />
  );
};

export const QuickBooksIcon = props => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 132 132"
      component={QuickBooksIconSvg}
    />
  );
};

export const ConnectToQuickBooksIcon = props => {
  return (
    <SvgIcon
      {...props}
      style={{width:274,height:48}}
      viewBox="0 0 274 48"
      component={ConnectToQuickBooksIconSvg}
    />
  );
};

export const GetIconById = (id, theme) => {
  let icon;
  if (!id) {
    id = "folderNode";
  }
  if (theme && theme.iconURLs && theme.iconURLs[id]) {
    return (
      <img src={theme.iconURLs[id]} alt="" style={{height:24}} />
    );
  }
  switch (id) {
    case "folderNode":
      icon = <FolderIcon />;
      break;
    case "organizations":
      icon = <OrganizationIcon />;
      break;
    case "profile":
      icon = <ProfileIcon />;
      break;
    case "members":
      icon = <MembersIcon />;
      break;
    case "organizationSubscription":
      icon = <OrganizationSubscriptionIcon />;
      break;
    case "settings":
      icon = <SettingsIcon />;
      break;
    case "quickbooks":
      icon = <QuickBooksIcon />;
      break;
    default:
      break;
  }
  return icon;
}