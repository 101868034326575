import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import DashboardCard from './DashboardCard';
import EnhancedTableHead from './EnhancedTableHead';

import {
  CardClasses,
} from '../Util/Cards';

import {
  NumberWithSeparators,
  CurrencyWithSeparators,
} from '../Util/NumberFormatting';
import { GlobalContext } from '../Context/Global.context';  

import PaymentHistoryDashboardCard from '../Components/PaymentHistoryDashboardCard';
import MultiUseDialog from '../Components/MultiUseDialog';
import ProgressIndicator from '../Components/ProgressIndicator';

const styles = theme => ({
  // For Table
  tableWrapper: {
    minWidth:"100%",
    width:'max-content',
    overflowX: "visible",
  },
  table: {
    backgroundColor: theme.palette.background.paper,
  },
  tableCell_FirstCell: {
    paddingLeft:15,
    paddingRight:20,
    [theme.breakpoints.down('xs')]: {
      paddingLeft:7,
    },
  },
  ...CardClasses(theme),
});

class PartnerCustomersCard extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      DialogDetails: { Open: false },
      ShowProgressIndicatorImmediately: true,
    };
  }
  handleApiError = ApiError => {
    this.props.onApiError(ApiError);
    if (ApiError) {
      setTimeout(() => this.handleApiError(null), 100);
    }
  }

  componentDidMount() {
    this.context.GetPartner(true)
      .then(partner => {
        this.beginLoadData();
      })
      .catch(this.handleApiError);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateKey !== this.props.updateKey) {
      this.beginLoadData();
    }
  }

  beginLoadData = () => {
    this.setState({ShowProgressIndicatorImmediately:true});
    this.context.GetPartnerCustomerSubscriptionUsagePackages()
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately:false});
      });
  }
  
  render() {
    const {
      classes,
      theme,
    } = this.props;
    const {
      PartnerCustomerSubscriptionUsagePackages,
      CompletedGET,
    } = this.context;

    const progressIndicator = (!PartnerCustomerSubscriptionUsagePackages)
      ? (
        <div style={{position:"relative",width:"100%",height:"100%"}}>
          <ProgressIndicator showImmediately />
        </div>
      ) : null;

    const collectionFields = [
      "CompanyName",
      "BillingEmail",
      "PagesProcessed",
      "PageLimit",
      "BillingCycleLimitUSD",
    ];

    const headCells = [
      { id: collectionFields[0], numeric: false, label: "Company" },
      { id: collectionFields[1], numeric: false, label: "Email" },
      { id: collectionFields[2], numeric: true, label: "Pages", label2: "Processed" },
      { id: collectionFields[3], numeric: true, label: "Page", label2: "Limit" },
      { id: collectionFields[4], numeric: true, label: "Monthly", label2: "Limit (USD)" },
    ];

    const tableHead = (
      <EnhancedTableHead
        headCells={headCells}
        sortDescending={false}
        // sortType={sortType}
        collectionFields={collectionFields}
        // onSortChange={id => onSortChangeFromTableHeader(id)}
        showSelectionColumn={false}
      />
    );

    let innerContent;
    if (CompletedGET.PartnerCustomerSubscriptionUsagePackages) {
      innerContent = (PartnerCustomerSubscriptionUsagePackages && PartnerCustomerSubscriptionUsagePackages.length) ? (
        <div className={classes.tableWrapper}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            {tableHead}
            <TableBody>
              {PartnerCustomerSubscriptionUsagePackages.map((sup, i) => {
                const subscription = sup.Subscription;
                const subscriptionUsage = sup.SubscriptionUsage;
                const onItemClick = this.handleRowClick(sup);

                return (
                  <TableRow
                    hover={onItemClick !== undefined}
                    onClick={onItemClick}
                    tabIndex={-1}
                    key={`${subscription?.CompanyName}_${i}`}
                    style={{cursor:(onItemClick) ? "pointer" : null}}
                  >
                    <TableCell className={classes.tableCell} padding="none">{subscription?.CompanyName}</TableCell>
                    <TableCell className={classes.tableCell}>{subscription?.BillingEmail}</TableCell>
                    <TableCell className={classes.tableCell} align="right">{NumberWithSeparators(subscriptionUsage?.BillingCyclePagesProcessed)}</TableCell>
                    <TableCell className={classes.tableCell} align="right">{NumberWithSeparators(subscription?.BillingCyclePageLimit)}</TableCell>
                    <TableCell className={classes.tableCell} align="right">{CurrencyWithSeparators(subscription?.BillingCycleLimitUSD)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      ) : (
        <Typography style={{marginTop:theme.spacing(2)}}>
          You don't have any customers to display right now.
        </Typography>
      );
    }

    const content = (
      <div style={{display:"flex",flexDirection:"column",overflowX:"auto"}}>
        {progressIndicator}
        <div className={classes.cardTitle} style={{flexGrow:1}}>
          <span>
            Customers
          </span>
        </div>
        {innerContent}
      </div>
    );
  
    return (
      <Grid item xs={12}>
        <DashboardCard
          height={300}
          style={{
            marginBottom:theme.spacing(3),
            paddingRight:theme.spacing(0),
          }}
        >
          {content}
        </DashboardCard>

        <MultiUseDialog Details={this.state.DialogDetails} />
      </Grid>
    );
  }

  handleRowClick = subscriptionUsagePackage => e => {
    const subscription = subscriptionUsagePackage.Subscription;

    this.handleShowDialog({
      Open: true,
      Title: `Payment History - ${subscription.BillingEmail}`,
      BodyContent:
        <React.Fragment>
          <div style={{
            minHeight: 100,
            maxHeight: 250,
            maxWidth: 400,
            verticalAlign: "middle",
          }}>
            <PaymentHistoryDashboardCard
              onApiError={this.handleApiError}
              companyIdOverride={subscription.CompanyID}
              displayCardContentsOnly={true}
            />
          </div>
        </React.Fragment>,
      // BodyText: "Please try again.",
      BodyContentIsRawHtml: false,
      CloseCallback: () => this.handleShowDialog(false),
    });
  }

  handleShowDialog = DialogDetails => {
    if (!DialogDetails) {
      this.setState({DialogDetails: { Open: false }});
      return;
    }

    this.setState({DialogDetails});
  }
}

export default withStyles(styles, { withTheme: true })(PartnerCustomersCard);

PartnerCustomersCard.propTypes = {
  classes: PropTypes.object.isRequired,
  onApiError: PropTypes.func.isRequired,
  updateKey: PropTypes.any,
};