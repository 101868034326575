import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
// import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';

import { CurrencyWithSeparators } from '../Util/NumberFormatting';
import { GlobalContext } from '../Context/Global.context';
import NumericTextField from '../Components/NumericTextField';
import ProgressIndicator from '../Components/ProgressIndicator';

import green from '@material-ui/core/colors/green';

const styles = theme => ({
  pricingBlue: {
    color: theme.palette.text.darkBlue,
  },
});

class ChangeLimitDialog extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      Open: true,
      NewLimit: props.subscription.BillingCycleLimitUSD.toFixed(2),
      ShowProgressIndicatorImmediately: false,
    }
  }

  componentDidMount() {
  }

  handleApiError = ApiError => {
    this.props.onApiError(ApiError);
    if (ApiError) {
        setTimeout(() => this.handleApiError(null), 100);
    }
  }

  handleNewLimitValueChange = e => {
    if (e && e.target && e.target.value !== undefined) {
      this.setState({NewLimit: e.target.value || "0"});
    } else {
      this.setState({NewLimit: "0"});
    }
  }

  handleChangeLimit = () => {
    this.setState({ShowProgressIndicatorImmediately:true});
    this.props.onUpdateLimit(parseFloat(this.state.NewLimit))
      .then(() => {
        this.handleClose();
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately:false})
      });
  }

  handleClose = isCancel => {
    this.props.onClose(isCancel);
  }

  render() {
    const {
      NewLimit,
      Open,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      theme,
      subscription,
    } = this.props;
    const {
      Subscription,
    } = this.context;

    const progressIndicator = (ShowProgressIndicatorImmediately)
      ? ( <ProgressIndicator constrained showImmediately={ShowProgressIndicatorImmediately} /> )
      : null;

    const changeButton = (
      <Button variant="contained" onClick={this.handleChangeLimit}
        style={{
          backgroundColor: green[500],
          color: "white",
        }}
      >
        Change
      </Button>
    );
    
    const dialogActions = (
      <DialogActions>
        <Button variant="contained" onClick={this.handleClose}>
          Cancel
        </Button>
        {changeButton}
      </DialogActions>
    );

    const dialogTitle = "Change limit";

    return (
      <Dialog
        open={Open}
        maxWidth="xs"
        fullWidth
        onClose={this.handleClose}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          {progressIndicator}
          <DialogContentText style={{textAlign:"center"}}>
            <span className={classes.pricingBlue}>
              Pricing
            </span>
            &nbsp;is&nbsp;
            <span className={classes.pricingBlue}>
              {Subscription && CurrencyWithSeparators(Subscription.PricePerPageUSD)}
            </span>
            &nbsp;per page&nbsp;
          </DialogContentText>
          
          <Grid container direction="column" spacing={2}
            style={{
              marginTop:theme.spacing(2),
              fontSize:18,
              fontWeight:500,
            }}>
            <Grid item>
              Current Limit: {CurrencyWithSeparators(subscription.BillingCycleLimitUSD)}
            </Grid>
            {/* <Grid item> */}
            {/*   Number of Pages: {(subscription.BillingCycleLimitUSD/subscription.PricePerPageUSD).toFixed(0)} */}
            {/* </Grid> */}
            <Grid item style={{marginTop:theme.spacing(2)}}>
              <NumericTextField
                autoFocus
                id="limit"
                label="New limit"
                isCurrency
                decimalPlaces={2}
                value={NewLimit}
                onValueChange={this.handleNewLimitValueChange}
                onEnterKey={() => this.handleChangeLimit()}
              />
            </Grid>
            <Grid item>
              Number of pages: {Math.floor((NewLimit / subscription.PricePerPageUSD).toFixed(1))}
            </Grid>
          </Grid>
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ChangeLimitDialog);

ChangeLimitDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  onUpdateLimit: PropTypes.func.isRequired,
  onApiError: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};