import SelectControl from '../Components/SelectControl';

export const GetEmployeeControl = (context, onValueChange, allowChange, alwaysShow, style) => {
	if (!context || !context.User) {
		return null;
	}
  if (context.User.Employees.length < 2 && !alwaysShow) {
    return null;
  }

	const getEmployeeOption = employee => ({ label: employee.DisplayName, value: employee.EmployeeID });
  const allEmployees = context.User.Employees.map(e => getEmployeeOption(e)); 

  const onValueChangeInternal = employeeId => {
  	context.SetEmployeeID(employeeId);
  	if (onValueChange) {
  		onValueChange(employeeId);
  	}
  }

	return (
		<SelectControl
	    id={`select_employee`}
	    label="Employee"
      disabled={!allowChange}
	    options={allEmployees}
	    hideEmpty
	    style={style}
	    value={context.EmployeeID}
	    onValueChange={onValueChangeInternal}
		/>
	);
}