import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ItemCard from './ItemCard';


import { NumberWithSeparators } from '../Util/NumberFormatting';
import {
  GetDateValue,
  GetUserValue,
  GetBoolValue,
} from '../Util/Properties';

class CompanyCard extends Component {
  render() {
    const {
      Company,
      onCardAction,
    } = this.props;

    let metadata = [
      ["ID", Company.ID],
      ["Created On", GetDateValue(Company.CreatedOn)],
      ["Creating User Last Activity", GetDateValue(Company.CreatingUserLastActivity, true)],
      ["Creating User", GetUserValue(Company.CreatedByUserEmail, Company.CreatedByUserName, "sm")],
      ["Firestore", Company.ID],
      ["Expenses (minimum)", NumberWithSeparators(Company.HasUpToMinExpenses)],
      ["Invoice Emails (minimum)", NumberWithSeparators(Company.HasUpToMinEmails)],
      ["Integration Active?", GetBoolValue(Company.IntegrationConnectionIsActive)],
      ["Integration Type", Company.IntegrationType],
      ["Integration ID", Company.IntegrationID],
    ];

    return (
      <ItemCard
        onCardAction={onCardAction}
        name={Company.Name}
        metadata={metadata}
      />
    );
  }
}

CompanyCard.propTypes = {
  Company: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
};

export default CompanyCard;