import React, { Component } from 'react';
import TitleComponent from '../Components/TitleComponent';

import { withStyles } from '@material-ui/core/styles';

// import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
// import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

// import AccountCircle from '@material-ui/icons/AccountCircle';
// import ClearIcon from '@material-ui/icons/Clear';

import UiCore from '../Components/UiCore';
import API, {
  GetUserPathForApi,
  GetUserProfilePathForApi,
  GetUserSmsNumberPathForApi,
  GetUserSmsNumberVerifyPathForApi,
  // GetUserAddressBookItemsPathForApi,
  // GetUserAvatarsPathForApi,
} from '../Util/api';
import MaskedTextField from '../Components/MaskedTextField';
import { 
  SMSNumberControl,
  GetSMSCountryCodeAndNumberIsValid,
  // SMSNumberMask,
} from '../Components/SMSNumberControl';
// import {
//   GetUserApiKeysPath,
// } from '../Util/PathHelper';
// import {
//   GetUserValue,
// } from '../Util/Properties';
import { GlobalContext } from '../Context/Global.context';

// import { ValidateEmail } from '../Util/Regex';
import MultiUseDialog from '../Components/MultiUseDialog';
import debounce from 'es6-promise-debounce';
// import AvatarSelectionDialog from '../Components/AvatarSelectionDialog';

import { IsMobile } from '../Util/MobileDetector';

const styles = theme => ({
  contentContainer: {
    // background:theme.palette.background.paper,
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    height:"100%",
  },
  profileGrid: {
  },
});

class UserProfile extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
  
    this.state = {
      // AvatarUpdateInProgress: false,
      UserProfile: null,
      // CurrentAvatar: null,
      // ShowAvatarSelectionDialog: false,
      ShowSMSNumberDialog: false,
      ShowSMSNumberConfirmationDialog: false,
      SMSNumberDialogSMSNumber: "",
      SMSNumber: "",
      ShowSMSNumberChangeCodeVerifyDialog: false,
      SMSNumberChangeCode: "",
      ShowRemoveSMSNumberDialog: false,
      // ShowClearAddressBookDialog: false,
      ShowDeleteAccountDialog: false,
      ShowProgressIndicatorImmediately: false,
      ShowDialogProgressIndicatorImmediately: false,
    }

    this.AvatarImageUploadCancelToken = null;
    this.BeginFileUploadFunc = () => {};
  }

  loadUserProfile = () => {
    this.setState({
      ShowProgressIndicatorImmediately: true,
    });
    API.get(GetUserProfilePathForApi())
      .then(resp => {
        this.setState({
          UserProfile: resp.data,
          ShowProgressIndicatorImmediately: false,
        });
      })
      .catch(this.handleApiError);
  }

  handleUpdateUserProfile = debounce(userProfile => {
    if (!userProfile) {
      userProfile = {...this.state.UserProfile};
    }
    
    API.put(GetUserProfilePathForApi(), userProfile)
      .catch(this.handleApiError);
  }, 500)

  handleUserProfileChange = (propName, value) => {
    let UserProfile = {...this.state.UserProfile};
    
    UserProfile[propName] = value;

    this.setState({UserProfile});
    this.handleUpdateUserProfile(UserProfile);
  }

  handleSetSMSNumberDialogVisibility = visible => {
    if (!this.state.UserProfile) {
      return;
    }
    this.setState({
      SMSNumberDialogSMSNumber: "",
      ShowSMSNumberDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
    });
  }

  handleSetSMSNumberConfirmationDialogVisibility = visible => {
    if (!this.state.UserProfile) {
      return;
    }
    this.setState({
      SMSNumberDialogSMSNumber: "",
      ShowSMSNumberConfirmationDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
    });
  }

  handleSMSNumberDialogSMSNumberValueChange = smsNumber => {
    this.setState({ SMSNumberDialogSMSNumber: smsNumber });
  }

  handleSMSNumberChangeVerify = () => {
    if (!this.state.SMSNumberDialogSMSNumber) {
      return;
    }
    if (!GetSMSCountryCodeAndNumberIsValid(this.state.SMSNumberDialogSMSNumber)) {
      this.handleApiError("Please enter a valid phone number.");
      return;
    }
    if (this.state.SMSNumberDialogSMSNumber === this.state.UserProfile.OTPSMSNumber) {
      this.handleApiError("This matches your current phone number.");
      return; 
    }
    this.setState({SMSNumber: this.state.SMSNumberDialogSMSNumber});
    this.handleSetSMSNumberDialogVisibility(false);
    this.handleSetSMSNumberConfirmationDialogVisibility(true);
  }

  handleSMSNumberConfirmation = () => {
    if (!this.state.SMSNumberDialogSMSNumber) {
      return;
    }
    let smsNumber = this.state.SMSNumberDialogSMSNumber;
    if (smsNumber !== this.state.SMSNumber) {
      this.handleApiError("Phone number doesn't match.");
      return;
    }
    this.handleSetSMSNumberConfirmationDialogVisibility(false);
    this.handleSMSNumberChangeCodeSend();
  }

  handleSMSNumberChangeCodeSend = () => {
    this.handleSetSMSNumberChangeCodeVerifyDialogVisibility(false);
    this.setState({ ShowProgressIndicatorImmediately: true });
    API.post(GetUserSmsNumberPathForApi(), {SMSNumber: this.state.SMSNumber})
      .then(resp => {
        this.setState({
          ShowProgressIndicatorImmediately: false,
        });
        this.handleSetSMSNumberChangeCodeVerifyDialogVisibility(true);
      })
      .catch(this.handleApiError);
  }

  handleSetSMSNumberChangeCodeVerifyDialogVisibility = visible => {
    let stateToUpdate = {
      ShowSMSNumberChangeCodeVerifyDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
    };
    if (visible) {
      stateToUpdate.SMSNumberChangeCode = "";
    }
    this.setState(stateToUpdate);
  }

  handleSMSNumberChangeCodeVerify = () => {
    if (!this.state.SMSNumberChangeCode) {
      return;
    }
    this.setState({ShowDialogProgressIndicatorImmediately: true});
    API.put(GetUserSmsNumberVerifyPathForApi(this.state.SMSNumberChangeCode))
      .then(resp => {
          this.handleSetSMSNumberChangeCodeVerifyDialogVisibility(false);
          this.loadUserProfile();
          this.setState({
            Alert: {
              DialogStyle: {
                height:(IsMobile() ? "80%" : undefined),
              },
              Title: "Phone number added",
              BodyText: "Your phone number has been set successfully.",
            },
            ShowDialogProgressIndicatorImmediately: false,
          });
      })
      .catch(this.handleApiError);
  }

  handleSetRemoveSMSNumberDialogVisibility = visible => {
    this.setState({
      ShowRemoveSMSNumberDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
    });
  }

  handleRemoveSMSNumber = () => {
    this.handleSetRemoveSMSNumberDialogVisibility(false);
    this.setState({ShowProgressIndicatorImmediately: true});
    API.delete(GetUserSmsNumberPathForApi())
      .then(resp => {
          this.loadUserProfile();
          this.setState({
            Alert: {
              DialogStyle: {
                height:(IsMobile() ? "80%" : undefined),
              },
              Title: "Phone number removed",
              BodyText: "Your phone number has been removed successfully.",
            },
            ShowProgressIndicatorImmediately: false,
          });
      })
      .catch(this.handleApiError);
  }

//   handleSetClearAddressBookDialogVisibility = visible => {
//     this.setState({
//       ShowClearAddressBookDialog: visible,
//       ShowDialogProgressIndicatorImmediately: false,
//     });
//   }
// 
//   handleClearAddressBook = () => {
//     this.handleSetClearAddressBookDialogVisibility(false);
//     this.setState({ShowProgressIndicatorImmediately: true});
//     API.delete(GetUserAddressBookItemsPathForApi())
//       .then(resp => {
//           this.setState({
//             Alert: {
//               Title: "Address book cleared",
//               BodyText: "All items in your address book have been cleared.",
//             },
//             ShowProgressIndicatorImmediately: false,
//           });
//       })
//       .catch(this.handleApiError);
//   }

  handleSetDeleteAccountDialogVisibility = visible => {
    this.setState({
      ShowDeleteAccountDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
    });
  }

  handleDeleteAccount = confirmation => {
    if (!confirmation) {
      return;
    }
    if (confirmation !== this.context.User.Email) {
      this.handleAlert({
        Title: "Email mismatch",
        BodyText:"Please enter your e-mail address exactly as it appears.",
      });
      return;
    }
    this.handleSetDeleteAccountDialogVisibility(false);
    this.setState({ShowProgressIndicatorImmediately: true});
    API.delete(GetUserPathForApi())
      .then(resp => {
          this.props.history.push("/signout");
      })
      .catch(this.handleApiError);
  }
// 
//   handleSetShowAvatarSelectionDialog = ShowAvatarSelectionDialog => {
//     this.setState({ShowAvatarSelectionDialog});
//   }
// 
//   handleAvatarSelectionDialogClosed = file => {
//     this.handleSetShowAvatarSelectionDialog(false);
//     if (file) {
//       this.handleReplaceAvatarImage(file);
//     }
//   }
// 
//   handleReplaceAvatarImage = file => {
//     this.setState({
//       ShowProgressIndicatorImmediately: true,
//       AvatarUpdateInProgress: true,
//     });
//     let avatarImageUpload = {};
//     if (file && file.type) {
//       avatarImageUpload.ContentType = file.type;
//     }
//     API.put(GetUserAvatarsPathForApi(), avatarImageUpload)
//       .catch(this.handleApiError)
//       .finally(() => {
//         this.context.Reset();
//         // Delay a bit to ensure avatar on this page is updated correctly
//         setTimeout(() => this.setState({
//           AvatarUpdateInProgress: false,
//           ShowProgressIndicatorImmediately: false,
//         }), 500);
//       });
//   }

  handleAlert = Alert => {
    this.setState({Alert});
  }

  handleApiError = err => {
    this.setState({
      ApiError: err,
      ShowProgressIndicatorImmediately: false,
      ShowDialogProgressIndicatorImmediately: false,
    });
    if (err) {
      setTimeout(() => this.handleApiError(null), 1);
    }
  }

  componentDidMount() {
    this.loadUserProfile();
  }

  render() {
    const {
      UserProfile,
      ApiError,
      Alert,
      // AvatarUpdateInProgress,
      // ShowEmailAddressDialog,
      // EmailDialogEmail,
      // EmailAddressDialogBodyText,
      // EmailAddressDialogConfirm,
      ShowSMSNumberDialog,
      ShowSMSNumberConfirmationDialog,
      ShowSMSNumberChangeCodeVerifyDialog,
      SMSNumberChangeCode,
      ShowRemoveSMSNumberDialog,
      // ShowClearAddressBookDialog,
      ShowDeleteAccountDialog,
      // ShowAvatarSelectionDialog,
      ShowProgressIndicatorImmediately,
      ShowDialogProgressIndicatorImmediately,
  	} = this.state;
    const {
      // history,
      // location,
      classes,
      theme,
    } = this.props;
    const {
      User,
    } = this.context;

    let content = null;
    if (UserProfile) {
      let smsNumberDialogDetails = {
        Open: ShowSMSNumberDialog,
        DialogStyle: {
          height:(IsMobile() ? "80%" : undefined),
        },
        ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
        Title: "Phone number for SMS",
        IsConfirmation: true,
        BodyContent: (
          <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
            <Grid item>
              <SMSNumberControl
                onEnterKey={this.handleSMSNumberChangeVerify}
                onValueChange={this.handleSMSNumberDialogSMSNumberValueChange}
              />
            </Grid>
          </Grid>
        ),
        ConfirmLabel: "Next",
        ConfirmCallback: this.handleSMSNumberChangeVerify,
        CancelCallback: () => this.handleSetSMSNumberDialogVisibility(false),
        CloseCallback: () => this.handleSetSMSNumberDialogVisibility(false),
      };

      let smsNumberConfirmationDialogDetails = {
        Open: ShowSMSNumberConfirmationDialog,
        DialogStyle: {
          height:(IsMobile() ? "80%" : undefined),
        },
        ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
        Title: "Confirm number",
        IsConfirmation: true,
        BodyContent: (
          <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
            <Grid item>
             <SMSNumberControl
                onEnterKey={this.handleSMSNumberConfirmation}
                onValueChange={this.handleSMSNumberDialogSMSNumberValueChange}
              />
            </Grid>
          </Grid>
        ),
        ConfirmLabel: "Next",
        ConfirmCallback: this.handleSMSNumberConfirmation,
        CancelCallback: () => this.handleSetSMSNumberConfirmationDialogVisibility(false),
        CloseCallback: () => this.handleSetSMSNumberConfirmationDialogVisibility(false),
      };

      let smsNumberChangeCodeDialogDetails = {
        Open: ShowSMSNumberChangeCodeVerifyDialog,
        DialogStyle: {
          height:(IsMobile() ? "80%" : undefined),
        },
        ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
        Title: "Device verification",
        DialogWidth:"xs",
        IsConfirmation: true,
        BodyContent: (
          <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
            <Grid item>
              A code has been sent to your device. Enter it here.
            </Grid>
            <Grid item>
              <MaskedTextField
                id="otp"
                label="Code"
                value={SMSNumberChangeCode}
                mask=">AAAAAAAAaaaaaaaa"
                autoFocus
                onValueChange={e => this.setState({SMSNumberChangeCode:e.target.value})}
                onEnterKey={() => this.handleSMSNumberChangeCodeVerify()}
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={this.handleSMSNumberChangeCodeVerify}
              >
                Verify
              </Button>
            </Grid>
          </Grid>
        ),
        ConfirmLabel: "Resend",
        ConfirmCallback: this.handleSMSNumberChangeCodeSend,
        CancelCallback: () => this.handleSetSMSNumberChangeCodeVerifyDialogVisibility(false),
        CloseCallback: () => this.handleSetSMSNumberChangeCodeVerifyDialogVisibility(false),
      };

      let removeSMSNumberDialogDetails = {
        Open: ShowRemoveSMSNumberDialog,
        DialogStyle: {
          height:(IsMobile() ? "80%" : undefined),
        },
        ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
        Title: "Remove phone number?",
        IsConfirmation: true,
        BodyText: "You will not be able to sign in using text messaging if you remove this number. Continue?",
        ConfirmCallback: this.handleRemoveSMSNumber,
        CancelCallback: () => this.handleSetRemoveSMSNumberDialogVisibility(false),
        CloseCallback: () => this.handleSetRemoveSMSNumberDialogVisibility(false),
      };

      // let clearAddressBookDialogDetails = {
      //   Open: ShowClearAddressBookDialog,
      //   ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
      //   Title: "Clear address book?",
      //   IsConfirmation: true,
      //   BodyText:"This action cannot be undone.",
      //   BodyClassName:"warning",
      //   ConfirmCallback: this.handleClearAddressBook,
      //   CancelCallback: () => this.handleSetClearAddressBookDialogVisibility(false),
      //   CloseCallback: () => this.handleSetClearAddressBookDialogVisibility(false),
      // };

      let deleteUserAccountConfirmationDialogDetails = {
        Open:ShowDeleteAccountDialog,
        ShowProgressIndicatorImmediately:ShowDialogProgressIndicatorImmediately,
        Title:"Delete your user account?",
        FullScreen: IsMobile(),
        FullWidth: !IsMobile(),
        RequireTextInput1:true,
        BodyContent:(
          <Grid container spacing={2} style={{paddingBottom:theme.spacing(3)}}>
            <Grid item className={classes.warning}>
              This action will remove your user account permanently, including all personal data associated with it.
            </Grid>
            <Grid item>
            Confirm you want to delete your account by entering your email address:
            <span style={{marginLeft:theme.spacing(1),fontWeight:"bold"}}>{User && User.Email}</span>
            </Grid>
          </Grid>
        ),
        DialogWidth: "xs",
        // TextInput1PlaceHolder:User && User.Email,
        CancelCallback:() => this.handleSetDeleteAccountDialogVisibility(false),
        CloseCallback:() => this.handleSetDeleteAccountDialogVisibility(false),
        ConfirmLabel:"Delete",
        ConfirmCallback:this.handleDeleteAccount,
      };

      let smsNumberSetChangeButtonText = (UserProfile.OTPSMSNumber)
        ? "Change" : "Setup SMS";
      let smsPhoneNumberRemoveButton = (UserProfile.OTPSMSNumber)
        ? (
          <Button variant="contained" fullWidth size="large"
            onClick={() => this.handleSetRemoveSMSNumberDialogVisibility(true)}>
            Remove
          </Button>
        ) : null;
      // const smsNumberGridItem = (UserProfile.OTPSMSNumber) ? (
      //   <Grid key="gi_smsnumber" item xs={(IsMobile()) ? 12 : 6}
      //     onClick={() => this.handleSetSMSNumberDialogVisibility(true)}>
      //     <MaskedTextField
      //       id="smsnumber"
      //       guide={false}
      //       disabled
      //       label="Phone number"
      //       value={UserProfile.OTPSMSNumber}
      //       mask={SMSNumberMask}
      //       onValueChange={() => {}}
      //     />
      //   </Grid>
      // ) : null;
      const smsPhoneNumberGridItem = (
        <Grid key="gi_phoneInfo" item>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body1">
                SMS lets you receive one-time sign-in codes via text message.
              </Typography>
            </Grid>
            {/* {smsNumberGridItem} */}
            <Grid item key="gi_smschange" xs={(IsMobile()) ? 6 : 3}>
              <Button variant="contained" fullWidth size="large"
                onClick={() => this.handleSetSMSNumberDialogVisibility(true)}>
                {smsNumberSetChangeButtonText}
              </Button>
            </Grid>
            <Grid item key="gi_smsremove" xs={(IsMobile()) ? 6 : 3}>
              {smsPhoneNumberRemoveButton}
            </Grid>
          </Grid>
        </Grid>
      );

      // const getUploadAvatarMenuItem = clickFunc => {
      //   return (
      //     <Button onClick={e => (clickFunc) ? clickFunc(e) : undefined}>
      //       SET AVATAR
      //     </Button>
      //   );
      // }

      // const removeAvatarGridItem = (UserHasCustomAvatar)
      //   ? (
      //     <Tooltip title="Clear avatar">
      //       <IconButton aria-label="Clear avatar"
      //         onClick={this.handleClearAvatar}
      //       >
      //         <ClearIcon />
      //       </IconButton>
      //     </Tooltip>
      //   ) : null;

//       const nameGridItem = (
//         <Grid item key="gi_name" style={{width:"100%"}}>
//           <TextField
//             fullWidth
//             variant="outlined"
//             id="name"
//             label="Name"
//             value={UserProfile.UserNameOverride || ""}
//             onChange={e => this.handleUserProfileChange("UserNameOverride", e.target.value)}
//           />
//         </Grid>
//         );
// 
//       const phoneGridItem = (
//         <Grid item key="gi_phone" style={{width:"100%"}}>
//           <TextField
//             fullWidth
//             variant="outlined"
//             id="phone"
//             label="Contact Phone"
//             value={UserProfile.UserPhone || ""}
//             onChange={e => this.handleUserProfileChange("UserPhone", e.target.value)}
//           />
//         </Grid>
//       );

      const profileGrid = (UserProfile)
        ? (
          <Grid container direction="column" spacing={3} className={classes.profileGrid}>
            {/* <Grid item key="gi_avatar" style={{marginBottom:theme.spacing(2)}}> */}
            {/*   { */}
            {/*     (!AvatarUpdateInProgress) */}
            {/*       ? GetUserValue( */}
            {/*         UserProfile.UserEmail, */}
            {/*         UserProfile.UserNameOverride || UserProfile.UserName, */}
            {/*         "profile", */}
            {/*         false, */}
            {/*         undefined, */}
            {/*         {cursor:"pointer"}, */}
            {/*         {}, */}
            {/*         true, */}
            {/*         () => this.handleSetShowAvatarSelectionDialog(true), */}
            {/*       ) */}
            {/*       : <AccountCircle style={{ width: 96, height: 96, }} /> */}
            {/*   } */}
            {/* </Grid> */}

            {/* {nameGridItem} */}
            {/* {phoneGridItem} */}

            {smsPhoneNumberGridItem}
            
{/*             <Grid key="gi_clearAddressBook" item> */}
{/*               <Button fullWidth size="large" */}
{/*                 onClick={() => this.handleSetClearAddressBookDialogVisibility(true)}> */}
{/*                 CLEAR ADDRESS BOOK */}
{/*               </Button> */}
{/*             </Grid> */}
{/*              */}
{/*             <Grid key="gi_apiKeys" item xs={12}> */}
{/*               <Button fullWidth size="large" */}
{/*                 onClick={() => history.push(GetUserApiKeysPath())}> */}
{/*                 API KEYS */}
{/*               </Button> */}
{/*             </Grid> */}
{/*  */}
            <Grid key="gi_deleteAccount" item style={{marginTop:theme.spacing(6)}}>
              <Button size="large"
                onClick={() => this.handleSetDeleteAccountDialogVisibility(true)}>
                Delete Account
              </Button>
            </Grid>

          </Grid>
        )
        : null;

      content = (
        <Container maxWidth="sm" className={classes.contentContainer}>
          <TitleComponent title="User Profile" />
          
          <MultiUseDialog Details={smsNumberDialogDetails} />
          <MultiUseDialog Details={smsNumberConfirmationDialogDetails} />
          <MultiUseDialog Details={smsNumberChangeCodeDialogDetails} />
          <MultiUseDialog Details={removeSMSNumberDialogDetails} />
          {/* <MultiUseDialog Details={clearAddressBookDialogDetails} /> */}
          <MultiUseDialog Details={deleteUserAccountConfirmationDialogDetails} />

          {/* <AvatarSelectionDialog */}
          {/*   open={ShowAvatarSelectionDialog} */}
          {/*   history={history} */}
          {/*   location={location} */}
          {/*   onClose={this.handleAvatarSelectionDialogClosed} */}
          {/*   onApiError={this.handleApiError} */}
          {/*   onAlert={this.handleAlert} */}
          {/* /> */}

          {profileGrid}
        </Container>
      );
    }

    let title = (UserProfile) ? "User Profile" : "Loading profile...";

    return (
      <UiCore title={title}
        apiError={ApiError}
        alert={Alert}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        content={content}
      />
    );
  }
}

export default withStyles(styles, {withTheme: true})(UserProfile);