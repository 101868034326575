import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import PartnerCustomersCard from '../Components/PartnerCustomersCard';
import UiCore from '../Components/UiCore';
import { GlobalContext } from '../Context/Global.context';

import { ContentCopyIcon } from '../Util/Icons';

import { IsMobile } from '../Util/MobileDetector';

import { CopyToClipboard } from 'react-copy-to-clipboard';

const styles = theme => ({
});

class PartnerDashboard extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      EditEmail: false,
      PagesProcessedStats: [],
      SubscriptionInvoices: null,
      PopoverText: null,
      OriginalEmailAddressPrefix: undefined,
      ShowProgressIndicatorImmediately: false,
      ShowEmailAddressPrefixProgressIndicatorImmediately: false,
    }

    this.PopoverAnchorRef = React.createRef();
    this.PartnerCodeRef = React.createRef();
  }

  componentDidMount() {
    this.context.GetPartner(true)
      .then(partner => {
        // this.loadPagesProcessedStats();
      })
      .catch(this.handleApiError);
  }

  handleApiError = ApiError => {
    this.setState({ApiError});
  }

  handleShowPopover = (PopoverText, popoverAnchorRef) => {
    this.setState({ PopoverText });
    this.PopoverAnchorRef = popoverAnchorRef;
    setTimeout(() => this.setState({ PopoverText: null }), 1500); 
  }

  render() {
    const {
      PopoverText,
      ApiError,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      theme,
    } = this.props;

    const partnerCustomersCard = (
      <div style={{width:"100%",marginTop:theme.spacing(3)}}>
        <PartnerCustomersCard
          onApiError={this.handleApiError}
        />
      </div>
    );

    const partnerId = this.context.Partner?.ID;
    let partnerIdContent = null;
    if (partnerId) {
      const getCopyPartnerCodeToClipboardContent = content => {
        return (
          <CopyToClipboard
            text={partnerId}
            onCopy={() => this.handleShowPopover("Copied to clipboard!", this.PartnerCodeRef)}
          >
            {content}
          </CopyToClipboard>
        );
      }

      partnerIdContent = (
        <Grid container direction="column" spacing={0} style={{marginTop:theme.spacing(2),marginLeft:theme.spacing(2)}}>
          <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
            <Typography variant="body1" style={{fontSize:20,fontWeight:500}}>
              Your Partner Code is
            </Typography>
            {getCopyPartnerCodeToClipboardContent(
              <div style={{fontWeight:600,fontSize:20,color:theme.palette.text.darkBlue,paddingLeft:7}}
                ref={instance => this.PartnerCodeRef = instance}>
                {partnerId}
              </div>
            )}
            {getCopyPartnerCodeToClipboardContent(
              <IconButton size="small" style={{paddingLeft:7}}>
                <ContentCopyIcon />
              </IconButton>
            )}
          </div>
        </Grid>
      );
    }

    const contentGrid = (IsMobile())
      ? (
        <Grid container spacing={3}>
          {partnerIdContent}
          {partnerCustomersCard}
        </Grid>
      ) : (
        <Grid container spacing={3}>
          {partnerIdContent}
          {partnerCustomersCard}
        </Grid>
      );

    const popover = (PopoverText)
      ? (
        <Popover
          open={Boolean(PopoverText)}
          onClose={() => this.handleShowPopover(null, null)}
          anchorOrigin={{
            vertical:'center',
            horizontal:'center',
          }}
          anchorEl={this.PopoverAnchorRef}
          anchorReference={"anchorEl"}
          transformOrigin={{
            vertical:'center',
            horizontal:'center',
          }}
        >
        {PopoverText}
      </Popover>
    ) : null;

    const content = (
      <React.Fragment>
        {popover}
        {contentGrid}
      </React.Fragment>
    );

    return (
      <UiCore
        title="Dashboard"
        content={content}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        apiError={ApiError}
        apiContext="Partner"
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(PartnerDashboard);
