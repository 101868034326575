import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
// import Typography from '@material-ui/core/Typography';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import SupportCompanyCard from '../Components/SupportCompanyCard';
import CompanyCollectionFields from '../Model/CompanyCollectionFields';

// import red from '@material-ui/core/colors/red';
// import brown from '@material-ui/core/colors/brown';
// import grey from '@material-ui/core/colors/grey';

import { NumberWithSeparators } from '../Util/NumberFormatting';
import {
  GetDateValue,
  GetUserValue,
  GetBoolValue,
} from '../Util/Properties';
import {
  GetSupportCompanyDetailPath,
} from '../Util/PathHelper';
import {
  GetSupportCompaniesPathForApi,
} from '../Util/api';

export class SupportCompanies_Collection extends Collection {
  constructor(props, onSetState, onApiError) {
    super(props, onSetState, onApiError);

    this.PageTitle = "Companies";
    this.ContentUri = GetSupportCompaniesPathForApi();
    this.CollectionName = "SupportCompanies";
    this.ItemsName = "Companies";
    this.ItemName = "Companies";
    this.DefaultViewType = "List";
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(CompanyCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => [
    { id: 'ID', numeric: false, label: 'ID' },
    { id: 'Name', numeric: false, label: 'Name' },
    { id: 'CreatedOn', numeric: false, label: 'Created On' },
    { id: 'CreatingUserLastActivity', numeric: false, label: 'Creating User Last Activity' },
    { id: 'CreatedUser', numeric: false, label: 'Creating User' },
    { id: 'MinExpenses', numeric: true, label: 'Expenses (minimum)' },
    { id: 'MinEmails', numeric: true, label: 'Invoice Emails (minimum)' },
    { id: 'IntegrationConnectionIsActive', numeric: false, label: 'Integration Active?' },
    { id: 'IntegrationType', numeric: false, label: 'Integration Type' },
    { id: 'IntegrationID', numeric: false, label: 'Integration ID' },
  ];

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length) 
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <SupportCompanyCard
                Company={i}
                // onCardAction={() => this.handleRouteToCompanyDetail(i)}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          return (
            <ItemTableRow key={`k_${i.ID}`}
              // onItemClick={() => this.handleRouteToCompanyDetail(i)}
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  style={{
                    visibility: "hidden",
                  }}
                  color="secondary"
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
                {i.ID}
              </TableCell>
              <TableCell className={classes.tableCell}>{i.Name}</TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatingUserLastActivity)}</TableCell>
              <TableCell className={classes.tableCell}>{GetUserValue(i.CreatedByUserEmail, i.CreatedByUserName)}</TableCell>
              <TableCell className={classes.tableCell} align="right">{NumberWithSeparators(i.HasUpToMinExpenses)}</TableCell>
              <TableCell className={classes.tableCell} align="right">{NumberWithSeparators(i.HasUpToMinEmails)}</TableCell>
              <TableCell className={classes.tableCell}>{GetBoolValue(i.IntegrationConnectionIsActive)}</TableCell>
              <TableCell className={classes.tableCell}>{i.IntegrationType}</TableCell>
              <TableCell className={classes.tableCell}>{i.IntegrationID}</TableCell>
            </ItemTableRow>
          );
        }) 
      : [];
  }

  handleRouteToCompanyDetail = company => {
    window.open(GetSupportCompanyDetailPath(company.ID), "apc_supportOrganization");
  }
}