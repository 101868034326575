import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import DashboardCard from './DashboardCard';
import ChangeLimitDialog from './ChangeLimitDialog';

import {
  CardClasses,
} from '../Util/Cards';

import { CurrencyWithSeparators } from '../Util/NumberFormatting';
import { GlobalContext } from '../Context/Global.context';  

import API, {
  GetCompanyBillingSubscriptionPathForApi,
} from '../Util/api';
import ProgressIndicator from '../Components/ProgressIndicator';

import { PieChart } from 'eazychart-react';
import { IsMobile } from '../Util/MobileDetector';
import { withCookies } from 'react-cookie';
import 'eazychart-css';

const gradientButtonStyle = {
  background:"linear-gradient(300deg, #1f95d9, #4137a9 70%)",
  color:"white",
  margin:(IsMobile()) ? 0 : undefined,
};

const styles = theme => ({
  pricingBlue: {
    color: theme.palette.text.darkBlue,
    fontSize:30,
  },
  chart: {
    display:"flex",
    marginTop:theme.spacing(4),
    alignSelf:"center",
    alignItems:"center",
    justifyContent:"center",

    "& .ez-chart": {
      position:"absolute",
    },
    "& .ez-legend": {
      display:"none",
    },
    "& .ez-legend-key": {
    },
    "& .ez-legend-box": {
    },
    "& .ez-legend-text": {
    },
    "& .ez-tooltip": {
      display:"none",
    },
    "& .ez-pie": {

    },
    "& .ez-arc:nth-child(1)": {
      filter: "drop-shadow(0px 5px 3px #888)",
    },
  },
  flipH: {
    transform: "rotateY(180deg)",
    transformOrigin: "center",
  },
  detailGrid: {
    marginTop:theme.spacing(4),
  },
  ...CardClasses(theme),
});

class BillingDashboardCard extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      ShowChangeLimitDialog: false,
      ShowProgressIndicatorImmediately: true,
    };
  }

  handleApiError = ApiError => {
    this.props.onApiError(ApiError);
    if (ApiError) {
        setTimeout(() => this.handleApiError(null), 100);
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateKey !== this.props.updateKey) {
      this.context.GetSubscriptionUsage();
    }
  }

  setShowChangeLimitDialog = (ShowChangeLimitDialog, reloadSubscriptionUsage) => {
    this.setState({ShowChangeLimitDialog});
    if (reloadSubscriptionUsage) {
      this.context.GetSubscriptionUsage();
    }
  }

  handleUpdateLimit = BillingCycleLimitUSD => {
    const updatedSubscription = {
      ...this.state.Subscription,
      BillingCycleLimitUSD,
    };
    return this.handleUpdateSubscription(updatedSubscription);
  }

  handleUpdateSubscription = subscription => {
    if (!subscription) {
      subscription = {...this.state.Subscription};
    }
    this.setState({ShowProgressIndicatorImmediately: true});
    return API.put(GetCompanyBillingSubscriptionPathForApi(this.context.User.CompanyID), subscription)
      .catch(this.handleApiError)
      .finally(() => {
        this.context.GetSubscriptionUsage();
      });
  }

  getPieChart = usage => {
    let pieChartClasses = [];
    if (usage < 0.50) {
      pieChartClasses.push(this.props.classes.flipH);
    }

    return (
      <React.Fragment>
        <div style={{
          position:"absolute",
          border: "30px solid #bbb",
          borderRadius: 135,
          height: IsMobile() ? 200 : 270,
          width: IsMobile() ? 200 : 270,
        }}/>
        <div className={classNames(pieChartClasses)}>
          <PieChart
            // animationOptions={{
            //   delay: 0,
            //   duration: 400,
            //   easing: 'easeBack'
            // }}
            arc={{
              cornerRadius: 0,
              donutRadius: 0.72,
              padAngle: 0,
              padRadius: 0,
              strokeWidth: 0
            }}
            colors={[
              this.props.theme.palette.text.darkBlue,
              'transparent',
            ]}
            data={[
              {
                name: 'Usage',
                usage,
              },
              {
                name: 'adj0',
                usage: 1-usage,
              },
            ]}
            dimensions={{
              height: IsMobile() ? 250 : 320,
              width: IsMobile() ? 250 : 320
            }}
            // labelDomainKey=""
            valueDomainKey="usage"
            padding={{
              bottom: 20,
              left: 20,
              right: 20,
              top: 20
            }}
          /> 
        </div>
      </React.Fragment>
    )
  }

  render() {
    const {
      ShowChangeLimitDialog,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      cookies,
      classes,
      theme,
      showPricePerPage,
      onUpgradeClick,
    } = this.props;
    const {
      Subscription,
      SubscriptionUsage,
    } = this.context;

    const nativeAppMode = cookies.get("native_app_mode");

    const progressIndicator = (ShowProgressIndicatorImmediately)
      ? (
        <div style={{position:"relative",width:"100%",height:"100%"}}>
          <ProgressIndicator noBackground constrained showImmediately />
        </div>
      ) : null;

    let content = progressIndicator;

    if (Subscription && SubscriptionUsage) {
      const subscriptionIsFree = Subscription.PaymentSubscriptionID === "";
  
      const upgradeButton = (!nativeAppMode) ? (
        <div style={{marginTop:theme.spacing(1)}}>
          <Button variant="contained"
            onClick={() => onUpgradeClick()}
            style={{
              ...gradientButtonStyle,
            }}
          >
            Upgrade
          </Button>
        </div>
      ) : null;

      // FREE
      if (subscriptionIsFree) {
        const usage = Math.min(1, SubscriptionUsage.BillingCycleFreePagesProcessed / Subscription.FreePagesPerBillingCycle);
        content = (
          <div style={{display:"flex",flexDirection:"column"}}>
            <div className={classes.cardTitle} style={{flexGrow:1}}>
              <Grid container>
                <Grid item xs={4} style={{textAlign:"center"}}>
                  <div>
                    <span style={{color:theme.palette.text.teal}}>FREE</span> Plan
                  </div>
                  {upgradeButton}
                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4} style={{textAlign:"center"}}>
                  This Month
                </Grid>
              </Grid>
            </div>
            <div className={classes.chart}>
              <div style={{
                position:"absolute",
                fontSize:IsMobile() ? 50 : 60,
                color:theme.palette.text.darkBlue,
              }}>
                {((usage*100) || 0).toFixed(0)}%
              </div>

              {this.getPieChart(usage)}
            </div>
            <div style={{textAlign:"center",marginTop:theme.spacing(4)}}>
              <span className={classes.pricingBlue}>
                {SubscriptionUsage.BillingCycleFreePagesProcessed}
              </span>
              &nbsp;of&nbsp;
              <span className={classes.pricingBlue}>
                {Subscription.FreePagesPerBillingCycle}
              </span>
              &nbsp;free pages
            </div>
          </div>
        );
      }

      // PAID
      if (!subscriptionIsFree) {
        const limit = Subscription.BillingCycleLimitUSD;
        const usage = Math.min(1, SubscriptionUsage.BillingCyclePagesProcessed / Subscription.BillingCyclePageLimit);
        const paid = SubscriptionUsage.BillingCyclePagesProcessed * Subscription.PricePerPageUSD;
        
        const changeLimitButton = (!nativeAppMode) ? (
          <div style={{marginTop:theme.spacing(2)}}>
            <Button variant="contained"
              onClick={() => this.setShowChangeLimitDialog(true)}
              size="small"
              style={{
                ...gradientButtonStyle,
              }}
            >
              <span style={{whiteSpace:"nowrap"}}>Change limit</span>
            </Button>
          </div>  
        ) : null;

        const limitGridItem = (
          <Grid item xs={4} style={{textAlign:"center"}}>
            <div style={{
              fontSize:32,
              lineHeight:1,
              color:theme.palette.text.darkBlue,
            }}>
              {CurrencyWithSeparators(paid)}
            </div>
            <div style={{
              fontSize:16,
            }}>
              /{CurrencyWithSeparators(limit)} limit
            </div>
            {changeLimitButton}
          </Grid>
        )

        const pricePerPageGridItem = (
          <Grid item xs={4} style={{textAlign:"center"}}>
            <div style={{
              fontSize:42,
              lineHeight:1,
              color:theme.palette.text.darkBlue,
            }}>
              ${Subscription.PricePerPageUSD.toFixed(2)}
            </div>
            <div>
              per page
            </div>
          </Grid>
        );

        const changeLimitDialog = (ShowChangeLimitDialog)
          ? (
            <ChangeLimitDialog
              subscription={Subscription}
              onApiError={this.handleApiError}
              onUpdateLimit={this.handleUpdateLimit}
              onClose={isCancel => this.setShowChangeLimitDialog(false, !isCancel)}
            />
          ) : null;

        const emptyGridItem = (
          <Grid item xs={4}>
          </Grid>
        );

        const gridItem1 = (showPricePerPage)
          ? pricePerPageGridItem
          : emptyGridItem;
        const gridItem2 = (!showPricePerPage)
          ? limitGridItem
          : emptyGridItem;
        const gridItem3 = (showPricePerPage)
          ? limitGridItem
          : emptyGridItem;

        content = (
          <div style={{display:"flex",flexDirection:"column"}}>
            {changeLimitDialog}
            <div className={classes.cardTitle} style={{flexGrow:1}}>
              <span>
                This Month
              </span>
            </div>
            <div className={classes.chart}>
              <div style={{
                position:"absolute",
                fontSize:60,
                color:theme.palette.text.darkBlue,
              }}>
                {((usage*100) || 0).toFixed(0)}%
              </div>

              {this.getPieChart(usage)}
            </div>
            <Grid container className={classes.detailGrid}>
              {gridItem1}
              {gridItem2}
              {gridItem3}
            </Grid>
          </div>
        );
      }
    }

    return (
      <DashboardCard height={IsMobile() ? 525 : 612}>
        {content}
      </DashboardCard>
    );
  }
}

export default withCookies(withStyles(styles, { withTheme: true })(BillingDashboardCard));

BillingDashboardCard.propTypes = {
  classes: PropTypes.object.isRequired,
  onApiError: PropTypes.func.isRequired,
  onUpgradeClick: PropTypes.func.isRequired,
  updateKey: PropTypes.any,
  showPricePerPage: PropTypes.bool,
};