import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import API, {
  GetUserPathForApi,
  GetUserEmployeeIdPathForApi,
  GetUserCompaniesPathForApi,
  GetCompanyBillingSubscriptionInvoicesPathForApi,
  GetCompanyBillingSubscriptionUsagePathForApi,
  // GetPartnerBillingSubscriptionInvoicesPathForApi,
  GetPartnerBillingSubscriptionUsagePathForApi,
  GetExpenseInfoPathForApi,
} from '../Util/api';
// import {
//   GetSingleUserPreference_Bool,
//   SaveSingleUserPreference_Bool,
// } from '../Util/SingleUserPreference';

export const GlobalContext = React.createContext();
export const GlobalContextConsumer = GlobalContext.Consumer;

class GlobalContextProvider extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      CompletedGET: {},
      User: null,
      EmployeeID: "",
      UserCompanies: [],
      Subscription: null,
      SubscriptionUsage: null,
      SubscriptionInvoices: null,
      ViewedWelcomeDialog: true,
      ApiError: null,
    };

    this.state = {
      ...this.initialState,
    };

    this.UniqueID = new Date();
    this.ResetContextUniqueID = null;
    this.ResetContextCheckIntervalID = null;
  }

  getUser = useCached => {
    if (useCached && this.state.CompletedGET.User && this.state.User) {
      return Promise.resolve(this.state.User);
    }
    this.setState({
      CompletedGET:{...this.state.CompletedGET, User:false},
    });
    return API.get(GetUserPathForApi())
      .then(resp => {
        const User = resp.data;
        const EmployeeID = (User.EmployeeID)
          ? User.EmployeeID
          : (User.Employees.length)
            ? User.Employees[0].EmployeeID
            : "";

        this.setState({
          CompletedGET:{...this.state.CompletedGET, User:true},
          User,
          EmployeeID,
        });
        return resp.data;
      });
  }

  setEmployeeID = EmployeeID => {
    this.setState({EmployeeID});
    return API.put(GetUserEmployeeIdPathForApi(EmployeeID));
  }

  getUserCompanies = useCached => {
    if (useCached && this.state.CompletedGET.UserCompanies && this.state.UserCompanies) {
      return Promise.resolve(this.state.UserCompanies);
    }
    this.setState({
      CompletedGET:{...this.state.CompletedGET, UserCompanies:false},
    });
    return API.get(GetUserCompaniesPathForApi())
      .then(resp => {
        this.setState({
          CompletedGET:{...this.state.CompletedGET, UserCompanies:true},
          UserCompanies: resp.data,
        });
        return resp.data;
      });
  }

  getSubscriptionUsagePackage = companyId => {
    this.setState({
      CompletedGET:{...this.state.CompletedGET, Subscription:false, SubscriptionUsage:false},
      Subscription:null,
      SubscriptionUsage:null,
    });
    return API.get(GetCompanyBillingSubscriptionUsagePathForApi(companyId))
      .then(resp => {
        this.setState({
          Subscription: resp.data.Subscription,
          SubscriptionUsage: resp.data.SubscriptionUsage,
          CompletedGET:{...this.state.CompletedGET, Subscription:true, SubscriptionUsage:true},
        });
        return resp.data;
      });
  }

  getPartnerCustomerSubscriptionUsagePackages = () => {
    this.setState({
      CompletedGET:{...this.state.CompletedGET, PartnerCustomerSubscriptionUsagePackages:false},
      PartnerCustomerSubscriptionUsagePackages:null,
    });
    return API.get(GetPartnerBillingSubscriptionUsagePathForApi())
      .then(resp => {
        const PartnerCustomerSubscriptionUsagePackages = resp.data.SubscriptionUsagePackages;
        this.setState({
          PartnerCustomerSubscriptionUsagePackages,
          CompletedGET:{...this.state.CompletedGET, PartnerCustomerSubscriptionUsagePackages:true},
        });
        return PartnerCustomerSubscriptionUsagePackages;
      });
  }

  getBillingSubscriptionInvoices = companyId => {
    this.setState({
      CompletedGET:{...this.state.CompletedGET, SubscriptionInvoices:false},
      SubscriptionInvoices:null,
    });
    
    const apiPath = /*this.state.APIContext.Type === "Partner"
      ? GetPartnerBillingSubscriptionInvoicesPathForApi()
      : */GetCompanyBillingSubscriptionInvoicesPathForApi(companyId);
    return API.get(apiPath)
      .then(resp => {
        const SubscriptionInvoices = resp.data;
        this.setState({
          SubscriptionInvoices,
          CompletedGET:{...this.state.CompletedGET, SubscriptionInvoices:true},
        });
      });
  }

  getExpenseInfo = () => {
    this.setState({
      CompletedGET:{...this.state.CompletedGET, ExpenseInfo:false},
    });
    return API.get(GetExpenseInfoPathForApi(this.state.EmployeeID))
      .then(resp => {
        const ExpenseInfo = resp.data;
        this.setState({
          ExpenseInfo,
          CompletedGET:{...this.state.CompletedGET, ExpenseInfo:true},
        })
        return ExpenseInfo;
      });
  }

  // This must be set as a cookie since it is used across multiple browser windows/tabs
  getReloadItemsFlag = () => {
    return this.props.cookies.get("reloadItems");
  }
  setReloadItemsFlag = () => {
    this.props.cookies.set("reloadItems", true, { path: "/" });
  }
  clearReloadItemsFlag = () => {
    const removeFlagFunc = () => this.props.cookies.remove("reloadItems", { path: "/" });
    // We execute via timeout to assist all tabs in seeing the call to reload
    setTimeout(removeFlagFunc, 1000);
  }

  handleReset = () => {
    this.setState({
      ...this.initialState,
    });
    this.initiateContext();
  }

//   handleGetViewedWelcomeDialog = () => {
//     if (localStorage.getItem("viewedWelcomeDialog")) {
//       return this.setState({ViewedWelcomeDialog:true});
//     }
//     GetSingleUserPreference_Bool("ViewedWelcomeDialog")
//       .then(ViewedWelcomeDialog => {
//         this.setState({ViewedWelcomeDialog});
//       })
//   }
// 
//   handleSetViewedWelcomeDialog = ViewedWelcomeDialog => {
//     this.setState({ViewedWelcomeDialog});
//     SaveSingleUserPreference_Bool("ViewedWelcomeDialog", ViewedWelcomeDialog);
//   }
// 
//   handleSetViewedWelcomeDialogThisSession = () => {
//     this.setState({ViewedWelcomeDialog:true});
//     localStorage.setItem("viewedWelcomeDialog", true);
//   }

  initiateContext = () => {
    this.getUser()
      .then(user => {
        if (user.IsCompanyAdmin) {
          this.getSubscriptionUsagePackage(user.CompanyID);
          this.getBillingSubscriptionInvoices(user.CompanyID);
        }
        // this.handleGetViewedWelcomeDialog();
      })
      .catch(ApiError => this.setState({ApiError}));
    this.getUserCompanies()
      .catch(ApiError => this.setState({ApiError}));
  }

  componentDidMount() {
    this.initiateContext();
  }

  componentWillUnmount() {
    // clearTimeout(this.ResetContextCheckIntervalID);
  }

  render() {
    let { children } = this.props;

    return (
      <GlobalContext.Provider
        value={{
          ApiError: this.state.ApiError,
          
          Reset: this.handleReset,
          
          CompletedGET: this.state.CompletedGET,

          User: this.state.User,
          GetUser: this.getUser,

          UserCompanies: this.state.UserCompanies,
          GetUserCompanies: this.getUserCompanies,
         
          EmployeeID: this.state.EmployeeID,
          SetEmployeeID: this.setEmployeeID,

          ExpenseInfo: this.state.ExpenseInfo,
          GetExpenseInfo: this.getExpenseInfo,

          Subscription: this.state.Subscription,
          SubscriptionUsage: this.state.SubscriptionUsage,
          GetSubscriptionUsage: () => this.getSubscriptionUsagePackage(this.state.User.CompanyID),

          PartnerCustomerSubscriptionUsagePackages: this.state.PartnerCustomerSubscriptionUsagePackages,
          GetPartnerCustomerSubscriptionUsagePackages: () => this.getPartnerCustomerSubscriptionUsagePackages(),

          SubscriptionInvoices: this.state.SubscriptionInvoices,
          GetSubscriptionInvoices: (companyId) => this.getBillingSubscriptionInvoices(companyId || this.state.User?.CompanyID),

          GetReloadItemsFlag: this.getReloadItemsFlag,
          SetReloadItemsFlag: this.setReloadItemsFlag,
          ClearReloadItemsFlag: this.clearReloadItemsFlag,

          // ViewedWelcomeDialog: this.state.ViewedWelcomeDialog,
          // SetViewedWelcomeDialog: this.handleSetViewedWelcomeDialog,
          // SetViewedWelcomeDialogThisSession: this.handleSetViewedWelcomeDialogThisSession,
        }}
      >
        { children }
      </GlobalContext.Provider>
    );
  }
}

export default withRouter(withCookies(GlobalContextProvider));