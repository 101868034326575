import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';
import red from '@material-ui/core/colors/red';

import PropTypes from 'prop-types';

import ProgressIndicator from '../Components/ProgressIndicator';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  warning: {
    color: red[600],
  },
  scrollFix: {
    paddingBottom:theme.spacing(1),
  },
});

class MultiUseDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Open: (props.Details) ? props.Details.Open : false,
      ShowProgressIndicator: props.Details.ShowProgressIndicator || false,
      ShowProgressIndicatorImmediately: props.Details.ShowProgressIndicatorImmediately || false,
    }

    this.textInput1Ref = React.createRef();
  }

  handleClose = e => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({ Open: false });
    if (this.props.Details.CloseCallback) {
      let textInput1 = this.textInput1Ref && this.textInput1Ref.value;
      this.props.Details.CloseCallback(textInput1);
    }
  }

  handleCancel = e => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({ Open: false });
    if (this.props.Details.CancelCallback) {
      let textInput1 = this.textInput1Ref && this.textInput1Ref.value;
      this.props.Details.CancelCallback(textInput1);
    }
  }

  handleConfirm = e => {
    if (e) {
      e.stopPropagation();
    }
    // this.setState({ Open: false });
    if (this.props.Details.ConfirmCallback) {
      let textInput1 = this.textInput1Ref && this.textInput1Ref.value;
      this.setState({ Open: !this.props.Details.ConfirmCallback(textInput1), });
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (this.props.Details && this.props.Details.Open !== undefined
      && prevProps.Details.Open !== this.props.Details.Open) {
      this.setState({Open: this.props.Details.Open !== false, ShowProgressIndicator: false});
    }
    if (this.props.Details && this.props.Details.ShowProgressIndicator !== undefined
      && prevProps.Details.ShowProgressIndicator !== this.props.Details.ShowProgressIndicator) {
      this.setState({ShowProgressIndicator: this.props.Details.ShowProgressIndicator !== false});
    }
    if (this.props.Details && this.props.Details.ShowProgressIndicatorImmediately !== undefined
      && prevProps.Details.ShowProgressIndicatorImmediately !== this.props.Details.ShowProgressIndicatorImmediately) {
      this.setState({ShowProgressIndicatorImmediately: this.props.Details.ShowProgressIndicatorImmediately !== false});
    }
  }

  render() {
    const {
      Open,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const { 
      classes,
      theme,
      Details,
    } = this.props;

    if (!Details) {
      return (<div />);
    }

    let dialogContentText = (!Details.BodyContent && Details.BodyText)
      ? (
        <DialogContentText id="dialog-description" className={classes[Details.BodyClassName]}>
          {Details.BodyText}
        </DialogContentText>
      ) : null;

    let confirmButton = (Details.ConfirmButton)
      ? Details.ConfirmButton
      : (Details.ConfirmCallback)
        ? (
          <Button variant="contained"
            onClick={this.handleConfirm}
            disabled={Details.DisableConfirmButton}
            style={{
              textWrap: "nowrap",
              ...Details.ConfirmButtonStyle,
            }}
          >
            {Details.ConfirmLabel || "Confirm"}
          </Button>
        )
        : null;

    let dialogActions = null;
    if (Details.IsConfirmation || Details.RequireTextInput1) {
      dialogActions = (
        <DialogActions style={{...Details.DialogActionsStyle}}>
          <Button variant="contained"
            onClick={this.handleCancel}
            style={{
              textWrap: "nowrap",
            }}
          >
            {Details.CancelLabel || "Cancel"}
          </Button>
          {Details.AdditionalActions}
          {confirmButton}
        </DialogActions>
      );
    } 
    else {
      dialogActions = (
        <DialogActions style={{...Details.DialogActionsStyle}}>
          <Button
            variant="contained" 
            onClick={this.handleClose}>
            {Details.CloseLabel || "Close"}
          </Button>
        </DialogActions>
      );
    }

    let dialogContentExtras = null;
    if (Details.RequireTextInput1) {
      dialogContentExtras = (
        <TextField
          inputRef={input => (this.textInput1Ref = input)}
          style={{marginTop: (dialogContentText) ? theme.spacing(1) : 0}}
          fullWidth
          autoFocus
          onFocus={e => { if (Details.TextInput1SelectAllOnFocus) { e.target.select(); }}}
          variant="outlined"
          label={Details.TextInput1Label}
          type={(Details.TextInput1Type || undefined)}
          placeholder={Details.TextInput1PlaceHolder}
          defaultValue={Details.TextInput1DefaultValue}
          onKeyPress={e => {if (e.key === "Enter") { this.handleConfirm(e); }}}
        />
      );
    }

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator constrained showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    const bodyContent = (Details.BodyContentIsRawHtml)
      ? (
        <div
          dangerouslySetInnerHTML={{
          __html: Details.BodyContent
          }}
        />
      )
      : Details.BodyContent;

    return (
      <Dialog
        fullWidth={Details.FullWidth}
        fullScreen={Details.FullScreen}
        maxWidth={Details.DialogWidth || undefined}
        open={Open}
        onClose={e => { if (!Details.DisableBlurClose) this.handleClose(e); }}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        style={Details.DialogStyle}
      >
        {progressIndicator}
        <DialogTitle id="dialog-title">
          <span>{Details.Title}</span>
        </DialogTitle>
        <DialogContent style={Details.DialogContentStyle}>
          {dialogContentText}
          <div className={classes[Details.BodyClassName]} style={Details.BodyStyle}>
            {bodyContent}
          </div>
          {dialogContentExtras}
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

MultiUseDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  Details: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(MultiUseDialog);