import queryString from 'query-string';

import {
  // GetBrowserFingerprint,
} from './SignIn';
import {
	GetIntuitSignInPath,
} from './PathHelper';

const getParsedQueryString = qs => {
    return queryString.parse(qs);
}

export const GetQuickBooksSignInState = (from, isCompanyConnect) => {
	let state = "";
	// if (GetBrowserFingerprint()) {
	// 	state = GetBrowserFingerprint().toString();
	// } else {
		state = "pYxGvZ7JcF60qT3O";
	// }
	
	return {
		state,
		from,
		isCompanyConnect,
	};
}

export const GetQuickBooksStateFromQueryString = props => {
	const state = getParsedQueryString(props.location.search).state;
	if (state) {
		return JSON.parse(atob(state));
	}

	return {};
}

export const GetQuickBooksRealmIDFromQueryString = props => {
	return getParsedQueryString(props.location.search).realmId;
}

export const GetQuickBooksAuthCodeFromQueryString = props => {
	return getParsedQueryString(props.location.search).code;
}

export const GetQuickBooksErrorFromQueryString = props => {
	return getParsedQueryString(props.location.search).error;
}

const QuickBooksOAuthConnectPath = "https://appcenter.intuit.com/connect/oauth2";
const GetQuickBooksOAuthUrl = (scopes, redirectUri, from, isCompanyConnect) => {
	const url = `${
  		QuickBooksOAuthConnectPath
  	}?client_id=${
      process.env.REACT_APP_QUICKBOOKS_CLIENTID
    }&response_type=code&scope=${
    	scopes
    }&redirect_uri=${
      redirectUri
    }&state=${
    	btoa(JSON.stringify(GetQuickBooksSignInState(from, isCompanyConnect)))
    }`;
  return url;
}

export const GetScopesForSignIn = () => {
	return "openid profile email phone address";
}

export const GetScopesForSignInAndConnect = () => {
	return `com.intuit.quickbooks.accounting ${GetScopesForSignIn()}`;
}

export const GetRedirectUriForSignIn = () => {
	return `${window.location.origin}${GetIntuitSignInPath()}`;
}

export const HandleSignIn = from => {
  window.location.href = GetQuickBooksOAuthUrl(GetScopesForSignIn(), GetRedirectUriForSignIn(), from);
}

export const HandleSignInAndConnect = from => {
  window.location.href = GetQuickBooksOAuthUrl(GetScopesForSignInAndConnect(), GetRedirectUriForSignIn(), from, true);
}

export const GetScopesForDisconnect = () => {
	return "com.intuit.quickbooks.accounting openid profile email phone address";
}

export const QuickBooksButtonClasses = ({
	connectToQuickBooksButton: {
	  cursor:"pointer",
	  width:223,
	  height:36,
	  backgroundImage: "url(/C2QB_green_btn_med_default.png)",
	  backgroundSize: "cover",
	  "&:hover": {
	    backgroundImage: "url(/C2QB_green_btn_med_hover.png)",
	  },
  },
  intuitSignInButton: {
    cursor:"pointer",
    width:161,
    height:36,
    backgroundImage: "url(/Sign_in_transparent_btn_med_default.png)",
    backgroundSize: "cover",
    "&:hover": {
      backgroundImage: "url(/Sign_in_transparent_btn_med_hover.png)",
    },
  },
});