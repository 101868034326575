import React from 'react';

import BaseCard from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import CompleteIcon from '@material-ui/icons/CheckCircle';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import green from '@material-ui/core/colors/green';

const styles = theme => ({
  card: {
    position:"relative",
    "&:hover $cardActionsContainer": { 
      display:"flex",
    },
    "&:hover $cardActionsContainerMinimal": { 
      display:"flex",
    },
    "&:hover $cardActionsHover": { 
      display:"flex",
    },
  },
  cardContent: {
    height:"100%",
    overflowWrap: "break-word",
  },
  cardActionsContainer: {
    position:"sticky",
    bottom:0,
    left:0,
    height: theme.spacing(8),
    marginBottom:-1 * theme.spacing(8),
    display:"none",
    backgroundColor:theme.palette.background.cardActionBar,
    overflow:"hidden",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  cardActionsContainerMinimal: {
    position:"absolute",
    bottom:0,
    right:0,
    height: theme.spacing(5),
    width: theme.spacing(5),
    alignItems:"center",
    justifyContent:"center",
    borderTopLeftRadius:8,
    display:"none",
    backgroundColor:theme.palette.background.cardActionBar,
    overflow:"hidden",
  },
  cardActionsTranslucent: {
    pointerEvents:"none",
    opacity:0.8,
  },
  cardSelected: {
    display:"flex",
  },
  cardActionsStatic: {
    pointerEvents:"all",
    opacity:1,
  },
  cardActionsHover: {
    pointerEvents:"all",
    display:"none",
    justifyContent: "flex-end",
    opacity:1,
  },
  cardActionsDivider: {
    flexGrow:1,
  },
  thumbnailGridItem: {
    overflow:"hidden",
    marginBottom:-4,
  },
  metadataGridItem: {
    overflow:"hidden",
  },
  thumbnailGridItemContent: {
    position:"relative",
  },
  thumbnailImage: {
    width:"100%",
  },
  completeIcon: {
    color:green[400],
    position:"absolute",
    top:theme.spacing(2.5),
    right:theme.spacing(2),
  },
  metadataContainer: {
    marginTop:theme.spacing(1),
    position:"relative",
  },
  metadataName: {
    fontWeight:500,
    marginRight:6,
    whiteSpace:"nowrap",
  },
  metadataValue: {
    overflowWrap: "anywhere",
  },
  property: {
    fontSize:12,
  },
  propertyContainer: {
    display:"flex",
    alignItems:"center",
  },
});

class Card extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  handleCardActionAreaClick = e => {
    if (e.ctrlKey) {
      this.handleSelect(e);
    } else {
      if (this.props.onCardAction) {
        this.props.onCardAction();
      }
    }
  }

  handleCardActionsClick = e => {
    if (e.ctrlKey) {
      this.handleSelect(e);
    }
  }

  handleSelect = e => {
    if (!this.props.noSelect && this.props.onSelect) {
      this.props.onSelect();
    }
  }

  render() {
    const { 
      classes,
      theme,
      style,
      hoverActionItems,
      selected,
      height,
      minHeight,
      maxHeight,
      checkboxOnly,
      smallName,
      noThumbnail,
      disableRipple,
      translucentActionBar,
      onCardAction,
      name,
      nameStyle,
      metadata,
      thumbnailUrl,
      isComplete,
      nameFontWeight,
      description,
      noSelect,
      onSelect,
      extraContent,
    } = this.props;

    let cardActions;
    if (hoverActionItems || onSelect) {
      let cardActionsContainerClassNames = classNames(
        (checkboxOnly)
          ? classes.cardActionsContainerMinimal
          : classes.cardActionsContainer
      );
      if (selected) {
        cardActionsContainerClassNames = classNames(cardActionsContainerClassNames, classes.cardSelected);
      }
      if (translucentActionBar) {
        cardActionsContainerClassNames = classNames(cardActionsContainerClassNames, classes.cardActionsTranslucent);
      }
      const checkbox = (!noSelect)
        ? (
          <Checkbox
            color="secondary"
            checked={selected}
            style={{
              padding: (checkboxOnly) ? 0 : undefined,
            }}
            onClick={this.handleSelect} />
        ) : null;
      cardActions = (checkboxOnly)
        ? (
          <CardActions className={cardActionsContainerClassNames}>
            {checkbox}
          </CardActions>
        )
        : (
          <CardActions className={cardActionsContainerClassNames}
            onClick={this.handleCardActionsClick}
            // style={{ opacity: (isDragging) && 0 }}
            >
            <div className={classes.cardActionsStatic}>
              {checkbox}
            </div>
            <div className={classes.cardActionsDivider}>
            </div>
            <div className={classes.cardActionsHover}>
              {hoverActionItems}
            </div>
          </CardActions>
        );
    }

    const contentMaxHeight = (height) ? height - 24 : (maxHeight) ? maxHeight - 24 : undefined;

    let thumbnailGridItem = null;
    if (thumbnailUrl && !noThumbnail) {
      thumbnailGridItem = (
        <Grid item xs={4} className={classes.thumbnailGridItem}
          style={{
            maxHeight: contentMaxHeight,
          }}
        >
          <div className={classes.thumbnailGridItemContent}>
            <img src={thumbnailUrl}
              className={classes.thumbnailImage}
              alt=""
            />
          </div>
        </Grid>
      );
    }

    let descriptionContent = (description)
      ? (
        <Typography color="textSecondary"
          component="div"
          style={{
            marginTop:theme.spacing(2),
          }}
        >
          {description}
        </Typography>
      ) : null;

    let metadataContent = [];
    if (metadata && metadata.length) {
      metadata.forEach((m, index) => {
        if (!m[1]) {
          return;
        }
        let nameSpan = (m[0]) ? (<span className={classes.metadataName}>{m[0]}:</span>) : null;
        metadataContent.push(
          <Typography key={`m${index}`} className={classes.property} color="textSecondary" component="span">
            <span className={classes.propertyContainer} style={m[2]}>
              {nameSpan}
              <span className={classes.metadataValue} style={m[3]}>{m[1]}</span>
            </span>
          </Typography>
        );
      });
    }

    const completeIcon = (isComplete)
      ? (
        <CompleteIcon className={classes.completeIcon} />
      ) : null;

    let primaryContent = (name)
      ? (
        <Typography variant={(smallName) ? "h6" : "h5"} component="h2"
          style={{
            lineHeight: (smallName) ? 1 : undefined,
            whiteSpace: (smallName) ? "nowrap" : undefined,
            fontSize: (smallName) ? 16 : undefined,
            ...nameStyle,
            fontWeight: (nameFontWeight) ? nameFontWeight : undefined,
          }}
        >
          {name}
        </Typography>
      ) : null;

    let mainContent = (
      <React.Fragment>
        {primaryContent}
        <div className={classes.metadataContainer}>
          {metadataContent}
          {descriptionContent}
        </div>
      </React.Fragment>
    );

    const cardContentMinHeight = (contentMaxHeight < 200) ? contentMaxHeight : Math.max(minHeight || 0, 200);
    let gridOrMainContent = (thumbnailGridItem)
      ? (
        <Grid container spacing={2}>
          <Grid item xs={8}
            className={classes.metadataGridItem}
            style={{
              minHeight: cardContentMinHeight,
              maxHeight: contentMaxHeight,
              overflow: "hidden",
            }}
          >
            {mainContent}
          </Grid>
          {thumbnailGridItem}
        </Grid>
      )
      : (
        <div style={{
          minHeight: cardContentMinHeight,
          maxHeight: contentMaxHeight,
          overflow: "hidden",
          position: "relative",
        }}>
          {mainContent}
          {extraContent}
        </div>
      );

    let cardContent = (
      <CardContent
        className={classes.cardContent}
        style={{
            height,
            maxHeight,
          }}
        onClick={e => { if (!onCardAction && e.ctrlKey) { this.handleSelect(e) } }}
      >
        {gridOrMainContent}
        {completeIcon}
      </CardContent>
    );

    let cardInnerContent = (onCardAction)
      ? (
        <CardActionArea
          disableRipple={disableRipple}
          onClick={this.handleCardActionAreaClick}>
          {cardContent}
        </CardActionArea>
      )
      : cardContent;

    return (
      <BaseCard className={classes.card} style={style}>
        {cardInnerContent}
        {cardActions}
      </BaseCard>
    );
  }
}

Card.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  height: PropTypes.number,
  checkboxOnly: PropTypes.bool,
  smallName: PropTypes.bool,
  noThumbnail: PropTypes.bool,
  thumbnailStyle: PropTypes.object,
  // name: PropTypes.object or string,
  nameStyle: PropTypes.object,
  metadata: PropTypes.array,
  nameFontWeight: PropTypes.number,
  onCardAction: PropTypes.func,
  disableRipple: PropTypes.bool,
  hoverActionItems: PropTypes.array,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  isComplete: PropTypes.bool,
  contentItem: PropTypes.object,
  contentTypeLabel: PropTypes.string,
  extraContent: PropTypes.object,
};

export default withStyles(styles, {withTheme: true})(Card);