import getBrowserFingerprint from 'get-browser-fingerprint';
import { IsMobile } from '../Util/MobileDetector';

export const GetBrowserFingerprint = () => {
  return getBrowserFingerprint(true);
}

export const BuildUpSignInPackage = (signInPackage, newProperties) => {
  const BrowserFingerprint = GetBrowserFingerprint();

  let SignInPackage = {
    ...signInPackage,
    BrowserFingerprint,
    IsMobile: IsMobile(),
  };
  if (newProperties) {
    SignInPackage = {...SignInPackage, ...newProperties};
  }
  return SignInPackage;
}