import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
// import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import { GoogleLogin } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth';

import green from '@material-ui/core/colors/green';

import API, {
  GetUserSigninPathForApi,
  GetUserEmailLoginVerifyPathForApi,
  GetUserEmailLoginOTPSendPathForApi,
  GetUserEmailLoginOTPVerifyPathForApi,
} from '../Util/api';

import MaskedTextField from '../Components/MaskedTextField';
import {
  SMSNumberControl,
  GetSMSCountryCodeAndNumberIsValid,
} from '../Components/SMSNumberControl';
import MultiUseDialog from '../Components/MultiUseDialog';
import ProgressIndicator from '../Components/ProgressIndicator';
import { ValidateEmail } from '../Util/Regex';
import { IsMobile } from '../Util/MobileDetector';
import {
  BuildUpSignInPackage,
} from '../Util/SignIn';
import {
  GetSignInPath,
} from '../Util/PathHelper';
import {
  HandleSignIn,
  QuickBooksButtonClasses,
} from '../Util/QuickBooks';
import { withCookies } from 'react-cookie';

const linksColor = "#888";
const styles = theme => ({
  root: {
    width:"100%",
    height:"100%",
    // backgroundImage: "url('/bg.jpg')",
    backgroundColor:"#222",
    backgroundRepeat:"no-repeat",
    backgroundAttachment:"fixed",
    backgroundSize:"cover",
    overflowY:"auto",
    display:"flex",
    justifyContent:"center",
  },
  container: {
    marginTop:24,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
  },
  paper: {
    width:(IsMobile()) ? 320 : 400,
    padding: theme.spacing(4),
    paddingBottom:theme.spacing(1),
    borderRadius:theme.spacing(2),
  },
  logo: {
    width:"75%",
    marginBottom:theme.spacing(1),
  },
  gridControls: {
    textAlign:"center",
    marginTop:theme.spacing(2),
    marginBottom:theme.spacing(2),
  },
  button: {
    textTransform:"none",
  },
  or: {
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(1),
  },
  gridLinks: {
    color:linksColor,
    marginTop:theme.spacing(3),
    justifyContent:"center",
    alignItems:"center",
    "& .MuiTypography-root": {
      fontSize:16,
    },
  },
  divider:{
    backgroundColor:"#aaa",
  },
  ...QuickBooksButtonClasses,
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SignInRoute: props.match.params.route || "new",
      redirectToReferrer: false,
      Email: "",
      EmailLoginOptions: {},
      SignInPackage: {},
      MicrosoftSigningIn: false,
      ShowEmailAddressConfirmationDialog: false,
      EmailConfirm: "",
      ShowAuthTypeDialog: false,
      AuthTypes: [],
      AuthType: "",
      ShowSMSNumberDialog: false,
      SMSNumber: "",
      ShowSMSNumberConfirmationDialog: false,
      SMSNumberConfirmation: "",
      ShowOTPGatherDialog: false,
      OTP: "",
      GeneralDialogDetails: {
        Open: false,
      },
      GoogleButtonWidth: null,
      ShowProgressIndicatorImmediately: false,
    }

    window.name = "n1main";
    this.ButtonGridSpacing = 2;
    this.ButtonGridRef = React.createRef();
  }

  showGeneralDialog = (title, bodyText, closeCallback_optional) => {
    this.setState({ 
      GeneralDialogDetails: {
        Open: true,
        Title: title,
        BodyText: bodyText,
        CloseCallback: () => this.handleCloseGeneralDialog(closeCallback_optional),
      },
    });
  }

  handleApiError = err => {
    // console.log(err.response.status);
    // console.log(err.response.data.message);

    this.setState({
      MicrosoftSigningIn: false,
      ShowProgressIndicatorImmediately: false,
    });

    if (typeof err.response === "undefined") {
      err = {
        response: {
          status: 500,
          data: {
          message: err.toString(),
          },
        },
      };
    }

    switch(err.response.status) {
      case 401:
      case 500:
      default:
        this.showGeneralDialog(
          "Sorry, something went wrong...",
          (err.response.data.message) ? err.response.data.message : "Please try again.",
          err.closeCallback,
        );
        break;
    }
  }

  handleCloseGeneralDialog = closeCallback_optional => {
    this.setState({ GeneralDialogDetails: { Open: false }});
    if (closeCallback_optional) {
      closeCallback_optional();
    }
  }

  handleEmailLoginVerify = () => {
    if (!this.state.Email) {
      return;
    }
    if (!ValidateEmail(this.state.Email)) {
      this.showGeneralDialog(
        "E-mail login",
        "Please enter a valid e-mail address.",
      );
      return;
    }

    this.setState({ShowProgressIndicatorImmediately: true});

    const SignInPackage = BuildUpSignInPackage(this.state.SignInPackage, {
      UserProvider: "email",
      Email: this.state.Email,
    });
    this.setState({SignInPackage});

    API.post(GetUserEmailLoginVerifyPathForApi(), SignInPackage)
      .then(resp => {
        let EmailLoginOptions = resp.data;
        let AuthTypes = [
          {
            Type: "email",
            Label: "E-mail",
          },
        ];
        if (EmailLoginOptions.SMSNumberLast2 || !EmailLoginOptions.UserExists) {
          AuthTypes.push({
            Type: "sms",
            Label: "Text message",
          });
        }

        this.setState({
          EmailLoginOptions,
          AuthTypes,
        });

        this.setState({ShowProgressIndicatorImmediately: false});

        if (EmailLoginOptions.UserExists) {
          this.doAuthTypeStep(AuthTypes);
        } else {
          this.handleSetEmailConfirmationDialogVisibility(true);
        }
      })
      .catch(this.handleApiError);
  }

  handleSetEmailConfirmationDialogVisibility = visible => {
    let stateToUpdate = {
      ShowEmailAddressConfirmationDialog: visible,
    };
    if (visible) {
      stateToUpdate.EmailConfirm = "";
    }
    this.setState(stateToUpdate);
  }

  handleEmailConfirmation = () => {
    if (!this.state.EmailConfirm) {
      return;
    }
    if (this.state.Email.toLowerCase() !== this.state.EmailConfirm.toLowerCase()) {
      this.showGeneralDialog(
        "E-mail confirmation",
        "E-mail address doesn't match.",
      );
      return;
    }
    this.handleSetEmailConfirmationDialogVisibility(false);
    this.doAuthTypeStep();
  }

  doAuthTypeStep = authTypes => {
    if (!authTypes) {
      authTypes = this.state.AuthTypes;
    }

    if (authTypes.length > 1) {
      this.handleSetAuthTypeDialogVisibility(true);
    } else {
      this.handleSendOTP(authTypes[0].Type);
    }
  }

  handleSetAuthTypeDialogVisibility = visible => {
    this.setState({
      ShowAuthTypeDialog: visible,
    });
  }

  handleSetAuthType = authType => {
    this.setState({AuthType: authType});
    switch (authType) {
      case "sms":
        if (!this.state.EmailLoginOptions.UserExists) {
          this.handleSetAuthTypeDialogVisibility(false);
          this.handleSetSMSNumberDialogVisibility(true);
        } else if (this.state.EmailLoginOptions.SMSNumberLast2) {
          this.handleSendOTP(authType);
        } else {
          this.handleApiError("There is no phone number associated with your account. Please choose a different authentication mechanism.");
        }
        break;
      default:
        this.handleSendOTP(authType);
        break;
    }
  }

  handleSetSMSNumberDialogVisibility = visible => {
    this.setState({
      SMSNumberDialogSMSNumber: "",
      ShowSMSNumberDialog: visible,
    });
  }

  handleSetSMSNumberConfirmationDialogVisibility = visible => {
    this.setState({
      SMSNumberDialogSMSNumber: "",
      ShowSMSNumberConfirmationDialog: visible,
    });
  }

  handleSMSNumberDialogSMSNumberValueChange = smsNumber => {
    this.setState({ SMSNumberDialogSMSNumber: smsNumber });
  }

  handleValidateSMSNumber = () => {
    if (!this.state.SMSNumberDialogSMSNumber) {
      return;
    }
    if (!GetSMSCountryCodeAndNumberIsValid(this.state.SMSNumberDialogSMSNumber)) {
      this.handleApiError("Please enter a valid phone number.");
      return;
    } 
    this.setState({SMSNumber: this.state.SMSNumberDialogSMSNumber});
    this.handleSetSMSNumberDialogVisibility(false);
    this.handleSetSMSNumberConfirmationDialogVisibility(true);
  }

  handleSMSNumberConfirmation = () => {
    if (!this.state.SMSNumberDialogSMSNumber) {
      return;
    }
    let smsNumber = this.state.SMSNumberDialogSMSNumber;
    if (smsNumber !== this.state.SMSNumber) {
      this.handleApiError("Phone number doesn't match.");
      return;
    }
    this.handleSetSMSNumberConfirmationDialogVisibility(false);
    this.handleSendOTP();
  }

  handleNavigateBackFromSMSNumberDialog = () => {
    this.handleSetSMSNumberDialogVisibility(false);
    this.handleSetAuthTypeDialogVisibility(true);
  }

  handleNavigateBackFromSMSNumberConfirmationDialog = () => {
    this.handleSetSMSNumberConfirmationDialogVisibility(false);
    this.handleSetSMSNumberDialogVisibility(true);
  }

  handleSendOTP = authType => {
    this.handleSetAuthTypeDialogVisibility(false);
    this.handleSetOTPGatherDialogVisibility(false);
    if (authType) {
      this.setState({AuthType: authType});
    } else {
      authType = this.state.AuthType;
    }

    this.setState({ShowProgressIndicatorImmediately: true});

    const SignInPackage = BuildUpSignInPackage(this.state.SignInPackage, {
      AuthType: authType,
      SMSNumber: (!this.state.EmailLoginOptions.UserExists
                    && authType === "sms"
                    && this.state.SMSNumber)
                  ? this.state.SMSNumber.trim()
                  : undefined,
    });
    this.setState({SignInPackage});

    API.post(GetUserEmailLoginOTPSendPathForApi(), SignInPackage)
      .then(resp => {
        this.setState({ShowProgressIndicatorImmediately: false});
        this.handleSetSMSNumberDialogVisibility(false);
        this.handleSetOTPGatherDialogVisibility(true);
      })
      .catch(this.handleApiError);
  }

  handleSetOTPGatherDialogVisibility = visible => {
    this.setState({
      ShowOTPGatherDialog: visible,
      OTP: "",
    });
  }

  handleEmailLoginCompletion = () => {
    if (!this.state.OTP) {
      return;
    }

    this.handleSetOTPGatherDialogVisibility(false);
    this.setState({ShowProgressIndicatorImmediately: true});
    
    const SignInPackage = BuildUpSignInPackage(this.state.SignInPackage, {
      OTP: this.state.OTP,
    });
    this.setState({SignInPackage});

    this.doLogin(SignInPackage)
      .catch(this.handleApiError);
  }

  handleGoogleLoginResponse = resp => {
    if (!resp || !resp.credential)
      return;

    this.setState({ShowProgressIndicatorImmediately: true});
  
    const SignInPackage = BuildUpSignInPackage(this.state.SignInPackage, {
      UserProvider: "google",
      OAuthIdToken: resp.credential,
    });
    this.setState({SignInPackage})

    this.doLogin(SignInPackage)
      .catch(this.handleApiError);
  }

  handleAppleSignInResponse = response => {
    if (!response || !response.authorization)
      return;

    this.setState({ShowProgressIndicatorImmediately: true});
  
    let signInPackage = BuildUpSignInPackage({
      UserProvider: "apple",
      OAuthRedirectUri: window.location.href,
      OAuthCode: response.authorization.code,
      OAuthIdToken: response.authorization.id_token,
    });
    if (response.user && response.user.name) {
      signInPackage.OAuthUserName = `${response.user.name.firstName} ${response.user.name.lastName}`;
    }

    this.doLogin(signInPackage)
      .catch(this.handleApiError);
  }

  doOTPVerify = signInPackage => {
    return API.post(GetUserEmailLoginOTPVerifyPathForApi(), signInPackage);
  }

  doLogin = signInPackage => {
    return API.post(GetUserSigninPathForApi(), signInPackage)
      .then(resp => {
        if (resp.status === 200) {
          this.setState({ redirectToReferrer: true });
        }
        return resp;
      });
  }

  handleWindowResize = () => {
    if (this.ButtonGridRef && this.ButtonGridRef.clientWidth) {
      this.setState({
        GoogleButtonWidth:
          this.ButtonGridRef.clientWidth - this.props.theme.spacing(this.ButtonGridSpacing || 0),
      });
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();

    // Clear all local-storage properties, currently used for per-session context
    localStorage.clear();

    if (this.props.match && this.props.match.params) {
      let UniqueID = this.props.match.params.uniqueID;
      let OTP = this.props.match.params.OTP;
      if (UniqueID && OTP) {
        const SignInPackage = BuildUpSignInPackage(this.state.SignInPackage, {
          UserProvider: "email",
          AuthType: "emailLink",
          UniqueID,
          OTP,
        });
        this.setState({SignInPackage});

        this.doLogin(SignInPackage)
          .catch(err => {
            err.closeCallback = () => this.props.history.push(GetSignInPath());
            this.handleApiError(err);
          });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  render() {
    const {
      redirectToReferrer,
      Email,
      EmailLoginOptions,
      ShowEmailAddressConfirmationDialog,
      EmailConfirm,
      ShowAuthTypeDialog,
      AuthTypes,
      AuthType,
      ShowSMSNumberDialog,
      ShowSMSNumberConfirmationDialog,
      ShowOTPGatherDialog,
      OTP,
      // MicrosoftSigningIn,
      GeneralDialogDetails,
      GoogleButtonWidth,
      ShowProgressIndicatorImmediately,
      SignInRoute,
     } = this.state;
    const {
      classes,
      theme,
      match,
      location,
      // history,
      cookies,
    } = this.props;

    const nativeAppMode = cookies.get("native_app_mode");

    const { from } = (location && location.state)
      || { from: { pathname: '/' } };

    if (redirectToReferrer === true && from) {
      return (
        <Redirect to={from} />
      );
    }

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    let authTypeButtonGridItems = (AuthTypes.length) 
      ? AuthTypes.map(at => (
        <Grid item key={`b_${at.Type}`}>
          <Button
            variant="contained" 
            color="secondary" 
            fullWidth
            onClick={() => this.handleSetAuthType(at.Type)}
          >
            {`${at.Label}${(at.Type === "sms" && EmailLoginOptions.SMSNumberLast2) ? " to number ending in " + EmailLoginOptions.SMSNumberLast2 : ""}`}
          </Button>
        </Grid>
      )) : null;
    
    let emailAddressConfirmationDialogDetails = {
      Open: ShowEmailAddressConfirmationDialog,
      DialogStyle: {
        height:(IsMobile() ? "80%" : undefined),
      },
      Title: "Authentication",
      IsConfirmation: true,
      ConfirmButtonStyle: {
        backgroundColor: green[500],
        color:"white",
      },
      BodyContent: (
        <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
          <Grid item>
            Confirm your e-mail address.
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              fullWidth
              autoFocus
              onChange={e => this.setState({EmailConfirm:e.target.value})}
              onKeyDown={e => { if (e.keyCode === 13) { e.preventDefault(); this.handleEmailConfirmation(); } }}
              value={EmailConfirm}
            />
          </Grid>
        </Grid>
      ),
      ConfirmCallback: () => this.handleEmailConfirmation(),
      CancelCallback: () => this.handleSetEmailConfirmationDialogVisibility(false),
      CloseCallback: () => this.handleSetEmailConfirmationDialogVisibility(false),
    };

    let authTypeDialogDetails = {
      Open: ShowAuthTypeDialog,
      DialogStyle: {
        height:(IsMobile() ? "80%" : undefined),
      },
      Title: "Authentication",
      DialogWidth:"sm",
      BodyContent: (
        <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
          <Grid item>
            Where would you like to get your one-time code?
          </Grid>
          { authTypeButtonGridItems }
        </Grid>
      ),
      CloseCallback: () => this.handleSetAuthTypeDialogVisibility(false),
    };

    let smsNumberDialogDetails = {
      Open: ShowSMSNumberDialog,
      DialogStyle: {
        height:(IsMobile() ? "80%" : undefined),
      },
      Title: "Authentication",
      IsConfirmation: true,
      BodyContent: (
        <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
          <Grid item>
            Enter your phone number.
          </Grid>
          <Grid item>
            <SMSNumberControl
              onEnterKey={this.handleValidateSMSNumber}
              onValueChange={this.handleSMSNumberDialogSMSNumberValueChange}
            />
          </Grid>
        </Grid>
      ),
      CancelLabel: "BACK",
      ConfirmLabel: "NEXT",
      ConfirmCallback: this.handleValidateSMSNumber,
      CancelCallback: this.handleNavigateBackFromSMSNumberDialog,
      CloseCallback: () => this.handleSetSMSNumberDialogVisibility(false),
    };

    let smsNumberConfirmationDialogDetails = {
      Open: ShowSMSNumberConfirmationDialog,
      DialogStyle: {
        height:(IsMobile() ? "80%" : undefined),
      },
      Title: "Authentication",
      IsConfirmation: true,
      BodyContent: (
        <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
          <Grid item>
            Confirm your phone number.
          </Grid>
          <Grid item>
            <SMSNumberControl
              onEnterKey={this.handleSMSNumberConfirmation}
              onValueChange={this.handleSMSNumberDialogSMSNumberValueChange}
            />
          </Grid>
        </Grid>
      ),
      CancelLabel: "BACK",
      ConfirmLabel: "SEND",
      ConfirmCallback: this.handleSMSNumberConfirmation,
      CancelCallback: this.handleNavigateBackFromSMSNumberConfirmationDialog,
      CloseCallback: () => this.handleSetSMSNumberConfirmationDialogVisibility(false),
    };

    let otpLocationText = "";
    switch (AuthType) {
      case "email":
        otpLocationText = " to your e-mail address";
      break;
      case "sms":
        otpLocationText = " to your device";
      break;
      default:
      break;
    }
    
    let otpGatherDialogDetails = {
      Open: ShowOTPGatherDialog,
      DialogStyle: {
        height:(IsMobile() ? "80%" : undefined),
      },
      Title: "Authentication",
      DialogWidth:"xs",
      DialogContentStyle:{overflowY:"visible"},
      IsConfirmation: true,
      BodyContent: (
        <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
          <Grid item>
            A code has been sent{otpLocationText}.
          </Grid>
          <Grid item>
            <MaskedTextField
              id="otp"
              label="Code"
              value={OTP}
              mask=">AAAAAAAAaaaaaaaa"
              autoFocus
              onValueChange={e => this.setState({OTP:e.target.value})}
              onEnterKey={() => this.handleEmailLoginCompletion()}
            />
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              onClick={this.handleEmailLoginCompletion}
            >
              Verify
            </Button>
          </Grid>
        </Grid>
      ),
      ConfirmLabel: "Resend",
      ConfirmCallback: this.handleSendOTP,
      CancelCallback: () => this.handleSetOTPGatherDialogVisibility(false),
      CloseCallback: () => this.handleSetOTPGatherDialogVisibility(false),
    };

    // const microsoftLoginButton = (
    //   <Button variant="outlined" size="large"
    //     style={{backgroundColor:"#fff",height:38}}
    //     className={classes.button}
    //     fullWidth
    //     disabled={MicrosoftSigningIn}
    //     onClick={() => this.setState({MicrosoftSigningIn:true})}
    //   >
    //     <div style={{display:"flex",alignItems:"center"}}>
    //       <div style={{marginTop:4,height:22}}>
    //         <svg aria-hidden="true" width="22" height="22" viewBox="10 10 28 28">
    //           <g>
    //             <path fill="#f25022" d="M13 11h9v9h-9z"></path><path fill="#00a4ef" d="M13 21h9v9h-9z"></path><path fill="#7fba00" d="M23 11h9v9h-9z"></path><path fill="#ffb900" d="M23 21h9v9h-9z"></path>
    //           </g>
    //         </svg>
    //       </div>
    //       <div style={{marginLeft:8}}>
    //         Microsoft
    //       </div>
    //     </div>
    //   </Button>
    // );
    // const microsoftLogin = (!MicrosoftSigningIn)
    //   ? (
    //     <MicrosoftLogin
    //       clientId={process.env.REACT_APP_MICROSOFT_SIGNIN_CLIENTID}
    //       authCallback={this.handleMicrosoftLoginResponse}
    //     >
    //       {microsoftLoginButton}
    //     </MicrosoftLogin>
    //   )
    //   : microsoftLoginButton;

//     const welcomeGrid = (
//       <Grid container
//         direction="column"
//         spacing={this.ButtonGridSpacing}
//         ref={instance => this.ButtonGridRef = instance}
//         className={classes.gridControls}>
// 
//         <Grid item style={{marginTop:theme.spacing(2)}}>
//           <Button variant="contained" color="primary"
//             style={{
//               backgroundColor: green[500],
//               color:"white",
//             }}
//             className={classes.button}
//             onClick={() => history.push(GetSignInPath("new"))}>
//             <span>Create FREE Account</span>
//           </Button>
//         </Grid>
// 
//         <Grid item style={{
//           marginTop:theme.spacing(1),
//           marginBottom:theme.spacing(1),
//         }}>
//           <Divider className={classes.divider} />
//         </Grid>
// 
//         <Grid item>
//           <Button variant="contained" color="primary"
//             className={classes.button}
//             onClick={() => history.push(GetSignInPath("user"))}>
//             <span>{(SignInRoute === "new") ? "Create" : "Sign in"}</span>
//           </Button>
//         </Grid>
//       </Grid>
//     );

    // Only show login panel if not direct login
    let isDirectLink = (match && match.params && match.params.uniqueID && match.params.OTP);
    let signInPaper = (<div />);
    let gridLinks = null;
    if (!isDirectLink) {
      let signInGrid;
      const newAccountGridItem = (SignInRoute === "new") ? (
        <Grid item style={{
          marginBottom:theme.spacing(2),
        }}>
            {/* <div style={{ */}
            {/*   fontSize:24, */}
            {/*   fontWeight:600, */}
            {/*   color:theme.palette.secondary.main, */}
            {/*   marginBottom:theme.spacing(1), */}
            {/* }}> */}
            {/*   Welcome! */}
            {/* </div> */}
          <div style={{
            fontSize:19,
            fontWeight:500,
            color:theme.palette.primary.main,
          }}>
            Create your FREE account
          </div>
        </Grid>
      ) : null;

      // const dontHaveAnAccountGridItem = (SignInRoute !== "new") ? (
      //   <Grid item xs={12}>
      //     <Link href="/signin/new" variant="caption">
      //       <span style={{textDecoration:"underline"}}>Create a free account</span>
      //     </Link>
      //   </Grid>
      // ) : null;

      const signInGridItem = (SignInRoute === "new") ? (
        <Grid item xs={12} style={{marginTop:theme.spacing(1)}}>
          <Link href="/signin/user">
            <span style={{textDecoration:"underline"}}>Sign in</span>
          </Link>
        </Grid>
      ) : null;

      const intuitSignInGridItem = (!nativeAppMode) ? (
        <Grid item style={{display:"flex",justifyContent:"center"}}>
          <div className={classes.intuitSignInButton}
            onClick={() => HandleSignIn(from)}
          />
        </Grid>
      ) : null;

      signInGrid = (
        <Grid container
          direction="column"
          spacing={this.ButtonGridSpacing}
          ref={instance => this.ButtonGridRef = instance}
          className={classes.gridControls}>

          {newAccountGridItem}

          <Grid item>
            <Grid container spacing={2} style={{alignItems:"center"}}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Email"
                  variant="outlined"
                  // InputLabelProps={{ shrink: true, }}
                  fullWidth
                  onChange={e => this.setState({Email:e.target.value})}
                  onKeyDown={e => { if (e.keyCode === 13) this.handleEmailLoginVerify(); }}
                  value={Email}
                />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" color="primary"
                  style={{
                    height:40,
                    backgroundColor: (SignInRoute === "new") ? green[500] : undefined,
                    color:(SignInRoute === "new") ? "white" : undefined,
                  }}
                  className={classes.button}
                  fullWidth
                  onClick={this.handleEmailLoginVerify}>
                  <span style={{whiteSpace:"nowrap"}}>{(SignInRoute === "new") ? "Create Account" : "Sign in"}</span>
                </Button>
              </Grid>

              {/* {dontHaveAnAccountGridItem} */}
            </Grid>
          </Grid>

          <Grid item className={classes.or}>
            <Grid container spacing={0} style={{alignItems:"center"}}>
              <Grid item xs={5}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item xs={2}>
                or
              </Grid>
              <Grid item xs={5}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <GoogleLogin
              text={(SignInRoute === "new") ? "continue_with" : undefined}
              onSuccess={this.handleGoogleLoginResponse}
              onError={() => {
                console.log('Sign-in Failed');
              }}
              width={GoogleButtonWidth}
              type="standard"
              logo_alignment="center"
              size="large"
            />
          </Grid>

          <Grid item>
            <AppleSignin
              authOptions={{
                clientId: process.env.REACT_APP_APPLE_SIGNIN_CLIENTID,
                scope: 'email name',
                redirectURI: window.location.href,
                // state: 'state',
                // nonce: 'nonce',
                usePopup: true,
              }}
              uiType="light"
              render={props => (
                <Button variant="outlined" size="large"
                  style={{
                    backgroundColor:"#fff",
                    height:38,
                    borderRadius:4,
                    borderColor:"#ddd",
                  }}
                  className={classes.button}
                  fullWidth
                  onClick={props.onClick}
                >
                  <svg style={{marginLeft:-6,marginRight:10}} aria-hidden="true" width="24" height="24" viewBox="2 12 20 20">
                    <g>
                      <path d="M12.2337427,16.9879688 C12.8896607,16.9879688 13.7118677,16.5445313 14.2014966,15.9532812 C14.6449341,15.4174609 14.968274,14.6691602 14.968274,13.9208594 C14.968274,13.8192383 14.9590357,13.7176172 14.9405591,13.6344727 C14.2107349,13.6621875 13.3330982,14.1241016 12.8065162,14.7430664 C12.3907935,15.2142188 12.012024,15.9532812 12.012024,16.7108203 C12.012024,16.8216797 12.0305005,16.9325391 12.0397388,16.9694922 C12.0859302,16.9787305 12.1598365,16.9879688 12.2337427,16.9879688 Z M9.92417241,28.1662891 C10.8202857,28.1662891 11.2175318,27.5658008 12.3353638,27.5658008 C13.4716724,27.5658008 13.721106,28.1478125 14.7188404,28.1478125 C15.6980982,28.1478125 16.3540162,27.2424609 16.972981,26.3555859 C17.6658521,25.339375 17.9522388,24.3416406 17.9707154,24.2954492 C17.9060474,24.2769727 16.0306763,23.5101953 16.0306763,21.3576758 C16.0306763,19.491543 17.5088013,18.6508594 17.5919459,18.5861914 C16.612688,17.1819727 15.1253248,17.1450195 14.7188404,17.1450195 C13.6194849,17.1450195 12.7233716,17.8101758 12.1598365,17.8101758 C11.5501099,17.8101758 10.7463794,17.1819727 9.79483648,17.1819727 C7.98413335,17.1819727 6.14571538,18.6785742 6.14571538,21.5054883 C6.14571538,23.2607617 6.8293482,25.1176563 7.67003179,26.3186328 C8.39061773,27.3348438 9.01882085,28.1662891 9.92417241,28.1662891 Z" fill="#000"></path>
                    </g>
                  </svg>
                  <span style={{
                    fontWeight:400,
                    color:"#222",
                    fontSize:14,
                  }}>
                    {(SignInRoute === "new") ? "Continue with Apple" : "Sign in with Apple"}
                  </span>
                  
                </Button>
              )}
              onSuccess={this.handleAppleSignInResponse}
            />
          </Grid>

          {intuitSignInGridItem}
          {signInGridItem}
        </Grid>
      );

      signInPaper = (
        <Paper elevation={theme.spacing(2)} className={classes.paper}>
          <div style={{textAlign:"center"}}>
            <img className={classes.logo} src="/apcentral.png" alt="AP Central" />  
          </div>

          {signInGrid}
        </Paper>
      );

      gridLinks = (
        <Grid container spacing={2} className={classes.gridLinks}>
          <Grid item>
            <Link href="/eula" target="n1legal" color="inherit" variant="body2">
              EULA
            </Link>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="inherit">
              &bull;
            </Typography>
          </Grid>
          <Grid item>
            <Link href="/privacy" target="n1legal" color="inherit" variant="body2">
              Privacy Policy
            </Link>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="inherit">
              &bull;
            </Typography>
          </Grid>
          <Grid item>
            <Link href="/licenses" target="n1legal" color="inherit" variant="body2">
              Licenses
            </Link>
          </Grid>
        </Grid>
      );
    }

    return (
      <div className={classes.root}>
        <MultiUseDialog Details={GeneralDialogDetails} />
        <MultiUseDialog Details={emailAddressConfirmationDialogDetails} />
        <MultiUseDialog Details={authTypeDialogDetails} />
        <MultiUseDialog Details={smsNumberDialogDetails} />
        <MultiUseDialog Details={smsNumberConfirmationDialogDetails} />
        <MultiUseDialog Details={otpGatherDialogDetails} />

        {progressIndicator}
        
        <div className={classes.container}>
          {signInPaper}
          {gridLinks}
        </div>
      </div>
    );
  }
}

export default withCookies(withStyles(styles, {withTheme: true})(Login));
