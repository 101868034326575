import React, {Component} from 'react';

import IconButton from '@material-ui/core/IconButton';
// import TextField from '@material-ui/core/TextField';
// import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import CloseIcon from '@material-ui/icons/Close';
import PrevIcon from '@material-ui/icons/NavigateBefore';
import NextIcon from '@material-ui/icons/NavigateNext';

import API, {
  GetExpensePagePathForApi,
} from '../Util/api';
import ProgressIndicator from './ProgressIndicator';

import { GlobalContext } from '../Context/Global.context';
import { IsMobile } from '../Util/MobileDetector';

const styles = theme => ({
  dialogPaper: {
    height:(!IsMobile()) ? "90%" : undefined,
  },
  object: {
    width:"100%",
    height:"100%",
  },
  downloadContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width:"100%",
  },
});

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

class ExpensePageViewDialog extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      Open: false,
      PageID: props.startingPageID || 0,
      CurrentPage: null,
      ShowProgressIndicatorImmediately: false,
    }
  }

  handleLoadCurrentPage = PageID => {
    if (PageID === undefined) {
      PageID = this.state.PageID;
    }
    this.setState({ ShowProgressIndicatorImmediately: true });
    API.get(GetExpensePagePathForApi(this.context.EmployeeID, this.props.expense.ID, PageID))
      .then(resp => {
        this.setState({
          CurrentPage: resp.data,
          ShowProgressIndicatorImmediately: false, 
        });
      })
      .catch(this.handleApiError);
  }

  handleViewPrev = () => {
    const PageID = this.state.PageID - 1;
    this.setState({ PageID });
    if (this.props.onPageIDChanged) {
      this.props.onPageIDChanged(PageID);
    }
    this.handleLoadCurrentPage(PageID);
  }

  handleViewNext = () => {
    const PageID = 1 + this.state.PageID;
    this.setState({ PageID });
    if (this.props.onPageIDChanged) {
      this.props.onPageIDChanged(PageID);
    }
    this.handleLoadCurrentPage(PageID);
  }

  handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleApiError = err => {
    this.setState({ ShowProgressIndicatorImmediately: false, });
    this.props.onApiError(err);
  }

  handleDownload = url => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  componentDidMount() {
    if (this.props.returnContentOnly && !this.state.CurrentPage) {
      this.handleLoadCurrentPage();
    }
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({ Open: this.props.open !== false, });
      if (this.props.open && !this.state.CurrentPage) {
        this.handleLoadCurrentPage();
      }
    }
    if (typeof this.props.expense !== "undefined"
      && prevProps.expense.ID !== this.props.expense.ID) {
      this.setState({ PageID: 0, CurrentPage: null, });
      if (this.props.returnContentOnly) {
        this.handleLoadCurrentPage();
      }
    }
  }

  render() {
    const {
      Open,
      CurrentPage,
      PageID,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      theme,
      expense,
      returnContentOnly,
    } = this.props;

    let pageContent;
    if (CurrentPage) {
      switch (CurrentPage.ContentType) {
      case "application/pdf":
      case "text/plain":
      case "application/xml":
        pageContent = (
          <object data={CurrentPage.SignedUrl} className={classes.object}> </object>
        );
        break;
      case "image/jpeg":
      case "image/gif":
      case "image/png":
      case "image/svg+xml":
        pageContent = (
          <img src={CurrentPage.SignedUrl} alt="Expense page" className={classes.image} />
        );
        break;
      default:
        pageContent = (
          <div className={classes.downloadContainer}>
            <Button variant="contained" 
              color="secondary" 
              onClick={() => this.handleDownload(CurrentPage.SignedUrl)}>
              Download   
            </Button>
          </div>
        );
        break;
      }
    }

    const viewPrevIconButton = (
      <IconButton disabled={PageID <= 0} onClick={() => this.handleViewPrev()}>
        <PrevIcon />
      </IconButton> 
    );
    const viewNextIconButton = (
      <IconButton disabled={PageID+1 >= expense.PageCount} onClick={() => this.handleViewNext()}>
        <NextIcon />
      </IconButton>
    );
    const pageInfo = (
      <span>Page {`${1+PageID} of ${expense.PageCount}`}</span>
    );

    const inlineNav = (returnContentOnly && expense.PageCount > 1) ? (
      <div style={{
        display:"flex",
        alignItems:"center",
        marginLeft:theme.spacing(2),
      }}>
        <div style={{flexGrow:1,textAlign:"left"}}>
          {pageInfo}
        </div>
        {viewPrevIconButton}
        {viewNextIconButton}
      </div>
    ) : null;

    const progressIndicator = (ShowProgressIndicatorImmediately)
      ? (
        <ProgressIndicator
          constrained
          noBackground
          showImmediately={ShowProgressIndicatorImmediately} />
      ): null;

    const content = (CurrentPage) ? (
      <div style={{textAlign:"center",height:"100%",position:"relative"}}>
        {progressIndicator}
        {inlineNav}
        {pageContent}
      </div>
    ) : (
      <div style={{position:"relative",height:"100%",}}>
        {progressIndicator}
      </div>
    );

    if (returnContentOnly) {
      return content;
    }

    return (
      <Dialog
        open={Open}
        fullScreen={IsMobile()}
        fullWidth={!IsMobile()}
        maxWidth="lg"
        // TransitionComponent={Transition}
        classes={{
          paper:classes.dialogPaper,
        }}
        onClose={this.handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">
          <div style={{display:"flex",textAlign:"left"}}>
            <div style={{flexGrow:1}}>
              {pageInfo}
            </div>
            <div style={{display:"flex",marginTop:-8,marginRight:-16}}>
              {viewPrevIconButton}
              {viewNextIconButton}
              <IconButton onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {content}          
        </DialogContent>
      </Dialog>
    );
  }
}

ExpensePageViewDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onApiError: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onPageIDChanged: PropTypes.func,
  startingPageID: PropTypes.number,
  expense: PropTypes.object.isRequired,
  returnContentOnly: PropTypes.bool,
};

export default withStyles(styles, {withTheme: true})(ExpensePageViewDialog);
