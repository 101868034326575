import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ItemCard from './ItemCard';

import {
  GetDateValue,
} from '../Util/Properties';

class ErrorEventCard extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {
    const { 
      ErrorEvent,
    } = this.props;

    let metadata = [
      ["ID", ErrorEvent.ID],
      ["Service", ErrorEvent.ServiceNameLower],
      ["Level", ErrorEvent.Level],
      ["Request URI", ErrorEvent.RequestURI],
      ["Request Method", ErrorEvent.RequestMethod],
    ];
    if (ErrorEvent.HttpStatus) {
      metadata.push(["HTTP Status", ErrorEvent.HttpStatus]);
    }
    if (ErrorEvent.CompanyName) {
      metadata.push(["Company", ErrorEvent.CompanyName]);
    }
    if (ErrorEvent.RealmID) {
      metadata.push(["Realm ID", ErrorEvent.RealmID]);
    }
    if (ErrorEvent.UserEmailLower) {
      metadata.push(["User Email", ErrorEvent.UserEmailLower]);
    }
    if (ErrorEvent.UserID) {
      metadata.push(["User ID", ErrorEvent.UserID]);
    }
    metadata.push(["Unique ID", ErrorEvent.UniqueID]);
    if (ErrorEvent.Header) {
      metadata.push(["Header", ErrorEvent.Header]);
    }
    if (ErrorEvent.Message) {
      metadata.push(["Message", ErrorEvent.Message]);
    }
    if (ErrorEvent.ClientMessage) {
      metadata.push(["Client Message", ErrorEvent.ClientMessage]);
    }
    if (ErrorEvent.OtherValues && ErrorEvent.OtherValues.length) {
      for (let i = 0; i < ErrorEvent.OtherValues.length; i++) {
        metadata.push([`Other ${1+i}`, ErrorEvent.OtherValues[i]]);
      };        
    }

    return (
      <ItemCard
        name={GetDateValue(ErrorEvent.CreatedOn)}
        metadata={metadata}
      />
    );
  }
}

ErrorEventCard.propTypes = {
  ErrorEvent: PropTypes.object.isRequired,
};

export default ErrorEventCard;