// import React from 'react';

import dateformat from 'dateformat';

export const GetUserValue = (email, name) => {
 
  if (!email && !name) {
    return null;
  }

  const nameAndOrEmail = (name)
    ? `${name} (${email})`
    : email;

  return (
    <span>{nameAndOrEmail}</span>
  );
}

export const GetDateValue = (date, allowPre2000) => {
  const d = new Date(date);
  return (allowPre2000 || d >= new Date("2020-01-01")) 
    ? dateformat(d, "m/d/yyyy h:MM:ss TT")
    : "";
}

export const GetFieldTypeLabel = fieldType => {
  switch (fieldType) {
    case "Text":
      return "Text";
    case "Date":
      return "Date";
    case "Number":
      return "Number";
    case "Currency":
      return "Currency";
    case "Bool":
      return "Bool";
    case "Image":
      return "Image";
    default:
      return fieldType;
  }
}

export const GetBoolValue = (value, showFalse) => {
  return value ? "Yes" : (showFalse) ? "No" : "";
}

export const GetUservalue = value => {

}