import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

import SortIcon from '@material-ui/icons/Sort';
import RefreshIcon from '@material-ui/icons/Refresh';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ClearIcon from '@material-ui/icons/Clear';

import UiCore from '../Components/UiCore';
import { GlobalContext } from '../Context/Global.context';

import API from '../Util/api';
import ErrorEventCollectionFields from '../Model/ErrorEventCollectionFields';
import ErrorEventListBase from '../Components/ErrorEventListBase';
import debounce from 'es6-promise-debounce';
import { IsMobile } from '../Util/MobileDetector';
import { 
  GetCurrentViewType,
  GetNextViewType,
  HandleCycleViewType,
} from '../Util/ViewType';
import { 
  GetCurrentSort,
  SaveSort,
} from '../Util/Sort';
import { 
  CompareCollectionField,
} from '../Util/Filters';

const toolHeaderHeight = 56;
const styles = theme => ({
  contentContainer: {
    overflowX: "auto",
    height:"100%",
  },
  content: {
    overflowY: "auto",
    height:(!IsMobile()) ? `calc(100% - ${toolHeaderHeight}px)` : undefined,
  },
  toolHeader: {
    display:(!IsMobile()) ? "flex" : undefined,
    alignItems: "center",
    justifyContent:"flex-end",
    height:(!IsMobile()) ? toolHeaderHeight : undefined,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  filterGrid: {
    alignItems:"center",
    marginRight: -theme.spacing(2),
    width: (IsMobile()) ? "100%" : undefined,
  },
  filterGridItem: {
    width: (IsMobile()) ? "100%" : undefined,
  },
});

class ErrorEvents extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      ViewType: "",
      ErrorEvents: [],
      ShowGetMoreButton: false,
      Cursor: "",
      SortMenuAnchor: null,
      CollectionFields: [],
      SortType: null,
      SortDescending: true,
      CompanyIDFilter: "",
      CompanyNameFilter: "",
      UserEmailFilter: "",
      ServiceFilter: "",
      LevelFilter:"",
      KeywordFilter:"",
      UniqueIDFilter:"",
      ShowMobileFilters: false,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    }
  }

  handleSaveCurrentStateToHistory(sortType, sortDescending) {
    // let stateToSave = {
    //   SortType: (sortType !== null) ? sortType : this.state.SortType,
    //   SortDescending: (sortDescending !== null) ? sortDescending : this.state.SortDescending,
    // };
    // this.props.history.replace(this.props.location.pathname, stateToSave);
  }

  handleLoadErrorEvents = (reset, sortType, sortDescending, filterObj) => {
    this.handleSaveCurrentStateToHistory(sortType, sortDescending);

    let params = {};
    params.cursor = (!reset) ? this.state.Cursor : "";
    let sortTypeParam = null;
    if (sortType) {
      sortTypeParam = sortType;
    } else if (this.state.SortType)  {
      sortTypeParam = this.state.SortType;
    }
    // Ensure outgoing sortType is valid against CollectionFields
    sortTypeParam = this.getValidSortType(sortTypeParam);
    params.sortType = sortTypeParam;
    if (sortDescending !== undefined) {
      params.sortDescending = sortDescending;
    } else if (this.state.SortDescending !== undefined) {
      params.sortDescending = this.state.SortDescending;
    }

    params.serviceNameFilter = (filterObj && filterObj.ServiceFilter !== undefined)
      ? filterObj.ServiceFilter : this.state.ServiceFilter;
    params.levelFilter = (filterObj && filterObj.LevelFilter !== undefined)
      ? filterObj.LevelFilter : this.state.LevelFilter;
    params.companyIDFilter = (filterObj && filterObj.CompanyIDFilter !== undefined)
      ? filterObj.CompanyIDFilter : this.state.CompanyIDFilter;
    params.companyNameFilter = (filterObj && filterObj.CompanyNameFilter !== undefined)
      ? filterObj.CompanyNameFilter : this.state.CompanyNameFilter;
    params.userEmailFilter = (filterObj && filterObj.UserEmailFilter !== undefined)
      ? filterObj.UserEmailFilter : this.state.UserEmailFilter;
    params.keywordFilter = (filterObj && filterObj.KeywordFilter !== undefined)
      ? filterObj.KeywordFilter : this.state.KeywordFilter;
    params.uniqueIdFilter = (filterObj && filterObj.UniqueIDFilter !== undefined)
      ? filterObj.UniqueIDFilter : this.state.UniqueIDFilter;

    this.setState({ShowProgressIndicator:true});
    API.get("/support/errorEvents", { params })
      .then(resp => {
        let errorEventList = resp.data;
        let ErrorEvents = (reset)
          ? errorEventList.ErrorEvents
          : [...this.state.ErrorEvents].concat(errorEventList.ErrorEvents);
        this.setState({
          ErrorEvents,
          ShowGetMoreButton: errorEventList.ErrorEvents.length >= errorEventList.PageSize,
          Cursor: errorEventList.Cursor, 
          ShowProgressIndicator: false
        });
      })
      .catch(this.handleApiError);
  }

  handleLoadErrorEventsWithDebounce = debounce((reset, sortType, sortDescending, filterObj) => {
    return this.handleLoadErrorEvents(reset, sortType, sortDescending, filterObj);
  }, 250);

  handleFilterValueChange = propertyName => e => {
    let value = e.target.value;
    this.setState({[propertyName]: value});
    this.handleLoadErrorEventsWithDebounce(true, undefined, undefined, {[propertyName]:value});
  }

  handleClearValue = propertyName => {
    this.handleFilterValueChange(propertyName)({target:{value:""}});
  }

  handleSortMenuClose() {
    this.setState({ SortMenuAnchor: null });
  };

  handleSortChange = (SortType, SortDescending) => {
    if (!SortType) {
      SortType = this.state.SortType;
    }
    if (typeof SortDescending !== "boolean") {
      SortDescending = this.state.SortDescending;
    }
    this.handleSortMenuClose();
    this.setState({SortType, SortDescending});
    this.handleLoadErrorEvents(true, SortType, SortDescending);
    SaveSort("ErrorEvents", null, SortType, SortDescending);
  }

  handleFlipSortDirection = () => {
    this.handleSortMenuClose();
    let sortDescending = !this.state.SortDescending;
    this.handleSortChange(this.state.SortType, sortDescending);
  }

  handleSetMobileFiltersVisibility = ShowMobileFilters => {
    this.setState({ShowMobileFilters});
  }

  handleSortChangeFromTableHeader = sortType => {
    // Flip sort direction if same sortType selected
    if (this.state.SortType && this.state.SortType.startsWith(sortType)) {
      this.handleFlipSortDirection();
    } else {
      this.handleSortChange(this.getValidSortType(sortType), false);
    }
  }

  getValidSortType = (sortType, collectionFields) => {
    if (!collectionFields) {
      collectionFields = this.state.CollectionFields;
    }
    let sortTypeFinder = collectionFields.filter(st => st.ID.startsWith(sortType));    
    if (sortTypeFinder.length) {
      return sortTypeFinder[0].ID;
    }
    return null;
  }

  handleApiError = err => {
    this.setState({
      ApiError: err, 
      ShowProgressIndicator: false, 
      ShowProgressIndicatorImmediately: false,
    });
  }

  loadDataWhenReady = () => {
    // This is important as it will catch 401s, which when passed to UiCore will redirect to the sign-in page.
    if (this.context && this.context.ApiError) {
      this.setState({ApiError:this.context.ApiError});
      return;
    }
    if (!this.context.CompletedGET.User) {
      setTimeout(() => this.loadDataWhenReady(), 250);
      return;
    }

    GetCurrentViewType("ErrorEvents", null, ViewType => this.setState({ViewType}), "List", this.handleApiError);
    
    GetCurrentSort("ErrorEvents")
      .then(initialSort => {
        let CollectionFields = [...ErrorEventCollectionFields].sort(CompareCollectionField);
        let SortType = (initialSort) ? this.getValidSortType(initialSort.SortType, CollectionFields) : null;
        let SortDescending = (initialSort) ? initialSort.SortDescending : true;
        this.setState({CollectionFields, SortType, SortDescending});
        this.handleLoadErrorEvents(true, SortType, SortDescending);
      });
  }

  componentDidMount() {
    this.loadDataWhenReady();
  }

  render() {
    const { 
      ApiError,
      Alert,
      ViewType,
  		ErrorEvents,
      CollectionFields,
      SortType,
      SortMenuAnchor,
      SortDescending,
      ShowGetMoreButton,
      CompanyIDFilter,
      CompanyNameFilter,
      ServiceFilter,
      LevelFilter,
      UserEmailFilter,
      KeywordFilter,
      UniqueIDFilter,
      ShowMobileFilters,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
  	} = this.state;
    const {
      classes,
      theme,
    } = this.props;

    let itemContent = (
      <ErrorEventListBase
        errorEvents={ErrorEvents}
        showGetMoreButton={ShowGetMoreButton}
        onGetMoreItems={this.handleLoadErrorEvents}
        sortType={SortType}
        collectionFields={CollectionFields}
        sortDescending={SortDescending}
        onSortChangeFromTableHeader={this.handleSortChangeFromTableHeader}
        viewType={ViewType}
      />
    );

    let sortMenuItems = CollectionFields.map(st => (
      <MenuItem key={"sortitem_" + st.ID} selected={SortType && st.ID === SortType} onClick={() => this.handleSortChange(st.ID)}>
        {st.Label}
      </MenuItem>
    ));
    let sortMenuOpen = Boolean(SortMenuAnchor);
    let sortMenu = (ViewType !== "List")
    ? (
      <div>
        <Tooltip title="Sort">
          <IconButton
            aria-owns={sortMenuOpen ? 'sort-menu' : undefined}
            aria-haspopup="true"
            onClick={event => this.setState({ SortMenuAnchor: event.currentTarget })}
            color="inherit"
          >
            <SortIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="sort-menu"
          anchorEl={SortMenuAnchor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={sortMenuOpen}
          onClose={() => this.handleSortMenuClose()}
        >
        <MenuItem key="_sortdirection_" onClick={this.handleFlipSortDirection}>
          <FormControlLabel
            onClick={e => e.stopPropagation()}
            control={
              <Switch
                color="secondary"
                onClick={e => {this.handleFlipSortDirection(); e.stopPropagation(); }}
                checked={SortDescending}
               />
            }
            label="Descending" />
        </MenuItem>
          {sortMenuItems}
        </Menu>
      </div>
    ) : null;

    let nextViewTitle = "";
    let nextViewIcon = null;
    switch (GetNextViewType(ViewType)) {
      case "Card":
        nextViewTitle = "Card view";
        nextViewIcon = <ViewModuleIcon />;
        break;
      case "List":
      default:
        nextViewTitle = "List view";
        nextViewIcon = <ViewListIcon />;
        break;
    }
    let switchViewComponent = (
      <div>
        <Tooltip title={nextViewTitle}>
          <IconButton
            onClick={() => HandleCycleViewType(state => this.setState(state), this.handleApiError, "ErrorEvents", null, ViewType)}
            color="inherit"
          >
            {nextViewIcon}
          </IconButton>
        </Tooltip>
      </div>
    );

    const getClearInputAdornment = propName => (
      <InputAdornment position="end">
        <IconButton
          edge="end"
          aria-label="clear"
          onClick={() => this.handleClearValue(propName)}
        >
          <ClearIcon style={{fontSize:18}} />
        </IconButton>
      </InputAdornment> 
    );

    let filters = (!IsMobile() || ShowMobileFilters) ?
      (
      <Grid container 
        direction={(IsMobile()) ? "column" : undefined}
        spacing={2}
        className={classes.filterGrid}>
        <Grid item xs={(!IsMobile()) ? 2 : undefined} className={classes.filterGridItem}>
          <TextField
            fullWidth
            label="Service"
            variant="outlined"
            size="small"
            onChange={this.handleFilterValueChange("ServiceFilter")}
            value={ServiceFilter}
            InputProps={{
              endAdornment: (ServiceFilter) ? getClearInputAdornment("ServiceFilter") : undefined,
            }}
          />
        </Grid>
        <Grid item xs={(!IsMobile()) ? 1 : undefined} className={classes.filterGridItem}>
          <TextField
            fullWidth
            label="Level"
            variant="outlined"
            size="small"
            onChange={this.handleFilterValueChange("LevelFilter")}
            value={LevelFilter}
            InputProps={{
              endAdornment: (LevelFilter) ? getClearInputAdornment("LevelFilter") : undefined,
            }}
          />
        </Grid>
        <Grid item xs={(!IsMobile()) ? 2 : undefined} className={classes.filterGridItem}>
          <TextField
            fullWidth
            label="Company ID"
            variant="outlined"
            size="small"
            onChange={this.handleFilterValueChange("CompanyIDFilter")}
            value={CompanyIDFilter}
            InputProps={{
              endAdornment: (CompanyIDFilter) ? getClearInputAdornment("CompanyIDFilter") : undefined,
            }}
          />
        </Grid>
        <Grid item xs={(!IsMobile()) ? 2 : undefined} className={classes.filterGridItem}>
          <TextField
            fullWidth
            label="Company name"
            variant="outlined"
            size="small"
            onChange={this.handleFilterValueChange("CompanyNameFilter")}
            value={CompanyNameFilter}
            InputProps={{
              endAdornment: (CompanyNameFilter) ? getClearInputAdornment("CompanyNameFilter") : undefined,
            }}
          />
        </Grid>
        <Grid item xs={(!IsMobile()) ? 1 : undefined} className={classes.filterGridItem}>
          <TextField
            fullWidth
            label="User email"
            variant="outlined"
            size="small"
            onChange={this.handleFilterValueChange("UserEmailFilter")}
            value={UserEmailFilter}
            InputProps={{
              endAdornment: (UserEmailFilter) ? getClearInputAdornment("UserEmailFilter") : undefined,
            }}
          />
        </Grid>
        <Grid item xs={(!IsMobile()) ? 2 : undefined} className={classes.filterGridItem}>
          <TextField
            fullWidth
            label="Unique ID"
            variant="outlined"
            size="small"
            onChange={this.handleFilterValueChange("UniqueIDFilter")}
            value={UniqueIDFilter}
            InputProps={{
              endAdornment: (UniqueIDFilter) ? getClearInputAdornment("UniqueIDFilter") : undefined,
            }}
          />
        </Grid>
        <Grid item xs={(!IsMobile()) ? 2 : undefined} className={classes.filterGridItem}>
          <TextField
            fullWidth
            label="Keyword/Other"
            variant="outlined"
            size="small"
            onChange={this.handleFilterValueChange("KeywordFilter")}
            value={KeywordFilter}
            InputProps={{
              endAdornment: (KeywordFilter) ? getClearInputAdornment("KeywordFilter") : undefined,
            }}
          />
        </Grid>
      </Grid>
    ) : null;

    const mobileFilters = (IsMobile())
      ? (
        <FormControlLabel
          onClick={e => e.stopPropagation()}
          control={
            <Switch
              color="secondary"
              onClick={e => {this.handleSetMobileFiltersVisibility(e.target.checked); e.stopPropagation(); }}
              checked={ShowMobileFilters}
             />
          }
          label="Filters" />
      ) : null;

    let toolHeader = (
      <div className={classes.toolHeader}>
        {filters}
        <div style={{
          display:"flex",
          alignItems:"center",
          marginLeft:theme.spacing(2),
        }}>
          {mobileFilters}
          {sortMenu}
          {switchViewComponent}
          <Tooltip title="Refresh">
            <IconButton
              color="inherit"
              onClick={this.handleLoadErrorEvents}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );

    let content = (
      <div className={classes.contentContainer}>
        {toolHeader}
        <div className={classes.content}>
          {itemContent}   
        </div>
      </div>
    );

    return (
      <UiCore title="Support: Error Events"
        apiError={ApiError}
        alert={Alert}
        hideProjectName
        hideSubscriptionExpirationAlert
        showProgressIndicator={ShowProgressIndicator}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        content={content}
      />
    );
  }
}

export default withStyles(styles, {withTheme: true})(ErrorEvents);