import { 
  GetMetaField, 
} from '../Util/Search';
// import API from '../Util/api';
// import {
// 	GetUserValue,
// } from '../Util/Properties';
// import debounce from 'es6-promise-debounce';

export const GetFilterFieldsFromProps = (filterFields, isWorkspace) => {
  // Deep copy is necessary to prevent filterFields and state.Fields from being linked in React
  const fields = JSON.parse(JSON.stringify(filterFields));
  return fields
    .filter(st => st.Type)
    .map(st => {
      if (isWorkspace && st.WorkspaceFilterID) {
        st.ID = st.WorkspaceFilterID;
      } else if (st.FilterID) {
        st.ID = st.FilterID;
      }

      // Restore any function properties from original array
      // const fieldFinder = filterFields.filter(f => f.ID === st.ID);
      // if (fieldFinder.length) {
      // 	var sourceField = fieldFinder[0];
      // 	for (const prop in sourceField) {
      // 		if (typeof sourceField[prop] === "function") {
	     //  		st[prop] = sourceField[prop];
      // 		}
      // 	}
      // } 

      return st;
    });
}

export const SaveFilterStateToHistory = (pathname, props, state) => {
  const newState = {
    ...props.location.state,
    ...state,
    locationPathname: pathname,
  };
  setTimeout(() => props.history.replace(pathname, newState), 1);
}

export const GetInitialFilterState = (filterFields, fullText, isWorkspace) => {
	const Fields = GetFilterFieldsFromProps(filterFields, isWorkspace);
  // Deep copy is necessary to prevent Fields and SecondaryFields from being linked in React
  const SecondaryFields = JSON.parse(JSON.stringify(Fields));
  return {
    FullTextFilter: fullText || "",
    Fields,
    SecondaryFields,
  };
}

export const GetSavedFilterState = (props, filterFields, fullText, ignorePathDifferences) => {
	let state = GetInitialFilterState(filterFields, fullText, props.isWorkspace);
  if (props.location && props.location.state
    && (
      ignorePathDifferences
      || props.location.state.locationPathname === props.location.pathname
    )
  ) {
	  if (props.location.state.FullTextFilter) {
	    state.FullTextFilter = fullText || props.location.state.FullTextFilter;
	  }
	  if (props.location.state.Fields && props.location.state.Fields.length) {
	    let finalFields = [];
	    state.Fields.forEach(f => {
	      const savedFieldFinder = props.location.state.Fields.filter(ssf => ssf.ID === f.ID);
	      if (savedFieldFinder.length) {
	        finalFields.push({
	          ...f, 
	          ...savedFieldFinder[0],
	        });
	      }
	    });
	    state.Fields = finalFields;
	  }
	  if (props.location.state.SecondaryFields && props.location.state.SecondaryFields.length) {
	    let finalSecondaryFields = [];
	    state.SecondaryFields.forEach(sf => {
	      const savedFieldFinder = props.location.state.SecondaryFields.filter(ssf => ssf.ID === sf.ID);
	      if (savedFieldFinder.length) {
	        finalSecondaryFields.push({
	          ...sf, 
	          ...savedFieldFinder[0],
	        });
	      }
	    });
	    state.SecondaryFields = finalSecondaryFields;
	  }
	}
	return state;
}

export const GetFilterFieldsAsMetaFieldFilters = (fields, secondaryFields) => {
  let metaFields = [];
  fields.filter(f => f.Operator)
    .forEach(f => {
      let sfFinder = secondaryFields.filter(sf => sf.ID === f.ID);
      let sfValue = (sfFinder.length) ? sfFinder[0].Value : null;
      if (f.Value) {
	      metaFields.push(GetMetaField(f.ID, f.API_Type || f.Type, f.Value, sfValue, f.Operator));
	    } else if (f.ListValues && f.ListValues.length) {
        metaFields.push(GetMetaField(f.ID, f.API_Type || f.Type, null, null, 
          f.Operator, f.ListValues.map(lv => lv.value)));
      }
    });
  return metaFields;
}

export const CompareCollectionField = (a, b) => {
  if (a.LabelOrName < b.LabelOrName) {
    return -1;
  } else if (a.LabelOrName > b.LabelOrName) {
    return 1;
  }
  else return 0;
}

// These functions have to exist separate from collection fields because browser history can't be replaced with state having functions
export const TryGetFilterFieldPromiseFuncById = id => {
	switch (id) {
    // case "addressBookItemsList":
    //   return debounce((organizationId, projectId, fieldId, valueFilter, isOrganizationMember, isProjectMember) => {
    //     return GetAddressBookItemsPromise(organizationId, projectId, true, (projectId && isProjectMember), false, false, valueFilter)
    //       .then(abitems => {
    //         return abitems
    //           .map(abi => { 
    //             return { 
    //               value: abi.EmailLower,
    //               plainLabel: (abi.Name) ? `${abi.Name} (${abi.EmailLower})` : abi.EmailLower,
    //               label: GetUserValue(abi.EmailLower,
    //                 (abi.Name)
    //                   ? `${abi.Name} (${abi.EmailLower})`
    //                   : abi.EmailLower,
    //                 "", false, undefined, {}, {}, true,
    //                 ),
    //             };
    //           });
    //       })
    //       .catch(() => Promise.resolve([]));
    //   }, 250);
    // case "contentTypes":
    //   return (organizationId, projectId, fieldId, valueFilter, isOrganizationMember, isProjectMember) => {
    //     const forcedValues = [
    //       { label: "Approval", value: "Approval" },
    //       { label: "Asset", value: "AssetItem" },
    //       { label: "Document", value: "Document" },
    //       { label: "Document to Sign", value: "DocumentSignatureSessionRecipient" },
    //       { label: "Field", value: "Field" },
    //       { label: "Document Folder", value: "DocumentFolder" },
    //       // { label: "Document Subscription", value: "DocumentSubscription" },
    //       { label: "Form", value: "FormTemplate" },
    //       { label: "Organization Member", value: "OrganizationMember" },
    //       { label: "Project", value: "Project" },
    //       // { label: "Project Member", value: "ProjectMember" },
    //       { label: "Task", value: "Task" },
    //       { label: "Workflow Process", value: "Process" },
    //     ];
    //     // FUTURE: projectId not applicable, so we would have to grab all assets on the organization,
    //     // but only those where the user has access 
    //     // return HandleGetAssets(organizationId, projectId, () => {})
    //     //   .then(assets => {
    //     //     return 
    //     //       forcedValues.filter(lv => 
    //     //         (valueFilter) 
    //     //           ? lv.label.toLowerCase().startsWith(valueFilter.toLowerCase()) 
    //     //           : lv
    //     //       );
    //     //   });
    //     return Promise.resolve(
    //       forcedValues.filter(lv => 
    //         (valueFilter) 
    //           ? lv.label.toLowerCase().startsWith(valueFilter.toLowerCase()) 
    //           : lv
    //       )
    //     );
    //   };
    // case "projectList":
    // 	return debounce((organizationId, projectId, fieldId, valueFilter, isOrganizationMember, isProjectMember) => {
    //     if (isOrganizationMember) {
    //       return API.get(GetProjectsPathForApi(organizationId), { params: { nameFilter: valueFilter } })
    //         .then(resp => {
    //           return resp.data.Projects
    //             .map(p => { return { value: p.Name, label: p.Name }; });
    //         })
    //         .catch(() => Promise.resolve([]));
    //     } else {
    //       return API.get(GetUserOrganizationProjectsPathForApi(organizationId), { params: { nameFilter: valueFilter } })
    //        .then(resp => {
    //           return resp.data.UserOrganizationProjects
    //             .map(uop => { return { value: uop.ProjectName, label: uop.ProjectName }; });
    //         })
    //         .catch(() => Promise.resolve([]));
    //     }
  	// 	}, 250);
    default:
    	return null;
	}
}