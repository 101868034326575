import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const chartStyles = theme => ({
  tooltip: {
    position:"absolute",
    display:"flex",
    textAlign:"center",
    marginLeft:-40,
    lineHeight:1,
    textWrap:"nowrap",
    fontWeight:500,
  },
  vertical: {
    borderLeft: "2px solid #888",
    position:"absolute",
    left:"50%",
    top:56,
    pointerEvents:"none",
  },
})

class ChartTooltip extends Component {
  constructor(props) {
    super(props);

    this.TooltipRef = React.createRef();
  }

  getOffsetX = () => {
    const {
      chartId,
      chartWidth,
    } = this.props;
    let offsetX = 0;
    if (chartId && chartWidth) {
      const chart = document.getElementById(chartId);
      if (chart) {
        offsetX = chartWidth - chart.offsetWidth + 1;
      }
    }
    return offsetX;
  }

  render() {
    const {
      isVisible,
      // mousePosition,
      shapeDatum,
      classes,
      chartHeight,
      children,
    } = this.props;

    if (!isVisible) {
      return null;
    }

    // if (mousePosition.x <= 100 || mousePosition.y <= 100) {
    //   return null;
    // }

    const offsetX = this.getOffsetX();

    return (
      <React.Fragment>
        <div className={classes.vertical}
          style={{
            // display:(offsetX === null) ? "none" : undefined,
            left:shapeDatum.x - offsetX,
            height: chartHeight,
          }}
        />
        <div
          // ref={instance => this.TooltipRef = instance}
          className={classes.tooltip}
          style={{
            // display:(offsetX === null) ? "none" : undefined,
            left:shapeDatum.x - offsetX,
            top:0,
          }}
        >
          {children}
        </div>
      </React.Fragment>
    );
  }
}

ChartTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  mousePosition: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default withStyles(chartStyles, {withTheme: true})(ChartTooltip);