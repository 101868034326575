import React, { Component } from 'react';

// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import {
  GetUserHomePath,
  GetDashboardPath,
} from '../Util/PathHelper';
import API, {
  GetCompanyIntegrationPathForApi,
} from '../Util/api';
import {
  HandleSignInAndConnect,
} from '../Util/QuickBooks';

import MultiUseDialog from '../Components/MultiUseDialog';
import ProgressIndicator from '../Components/ProgressIndicator';

import {
  GetQuickBooksStateFromQueryString,
  GetQuickBooksRealmIDFromQueryString,
  GetQuickBooksAuthCodeFromQueryString,
  GetQuickBooksErrorFromQueryString,
  QuickBooksButtonClasses,
} from '../Util/QuickBooks';

import queryString from 'query-string';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height:"100%",
    backgroundColor: theme.palette.background.default,
  },
  content: {
    paddingTop: 64,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
    },
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    display:"flex",
  },
  ...QuickBooksButtonClasses,
});

class QuickBooksDisconnect extends Component {
  constructor(props) {
    super(props);
    
    const qs = queryString.parse(window.location.search);
    this.CompanyID = qs.companyId;
    this.RealmID = qs.realmId;

    this.state = {
      DialogDetails: { Open: false },
      ShowProgressIndicatorImmediately: false,
      IsDisconnected: false,
    }

    this.QuickBooksSignInState = GetQuickBooksStateFromQueryString(props);
    this.QuickBooksAuthorizationCode = GetQuickBooksAuthCodeFromQueryString(props);
    this.QuickBooksRealmID = GetQuickBooksRealmIDFromQueryString(props);
    this.QuickBooksError = GetQuickBooksErrorFromQueryString(props);
  }

  componentDidMount() {
    // At this point we have no idea how to obtain OpenID identity without performing
    // normal OAuth Authorization, which Intuit says they do not expect.
    // So we're simply showing a landing page. If the user clicks Connect to QuickBooks,
    // it will issue a normal OAuth flow. If they click Dashboard, it will take them into
    // the dashboard if signed in, regardless of whether the disconnected QBO company
    // is the one the user is currently signed in with. Otherwise if they aren't signed in,
    // it will redirect to the sign in page.
    if (this.CompanyID && this.RealmID) {
      this.handleQuickbooksDisconnect(this.CompanyID, this.RealmID);
    }
  }

  tryCloseWindowOrRedirectToRoot = () => {
    window.close();
    this.props.history.replace(GetUserHomePath());
  }

  handleQuickbooksDisconnect = (companyId, realmId) => {
    API.delete(GetCompanyIntegrationPathForApi(companyId, realmId))
      .then(resp => {
        this.setState({IsDisconnected:true});
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately: false});
      })
  }

  handleApiError = err => {
    this.handleShowDialog({
      Open: true,
      Title: "Sorry, something went wrong...",
      BodyText: (err.response.data.message) ? err.response.data.message : "Please try again.",
      CloseCallback: () => this.tryCloseWindowOrRedirectToRoot(),
    });
  }

  handleShowDialog = DialogDetails => {
    if (!DialogDetails) {
      this.setState({DialogDetails: { Open: false }});
      return;
    }

    this.setState({DialogDetails});
  }

  handleNavigateToDashboard = () => {
    this.props.history.push(GetDashboardPath());
  }

  render() {
    const {
      IsDisconnected,
      DialogDetails,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      theme,
    } = this.props;

    const progressIndicator = (ShowProgressIndicatorImmediately)
      ? ( <ProgressIndicator showImmediately /> )
      : null;

    const disconnectedContent = (!this.RealmID || IsDisconnected)
      ? (
        <div className={classes.content}>
          <Grid container spacing={4} direction="column" style={{textAlign:"center",alignItems:"center"}}>
            <Grid item>
              <Typography variant="h5" color="inherit">
                Your connection to QuickBooks Online has been removed.
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" color={theme.palette.type === "dark" ? "default" : "secondary"}
                onClick={this.handleNavigateToDashboard}>
                Dashboard
              </Button>
            </Grid>
            <Grid item>
              <div className={classes.connectToQuickBooksButton}
                onClick={() => HandleSignInAndConnect()}
              />
            </Grid>
          </Grid>
        </div>
      )
      : null;

    return (
      <div className={classes.root}>
        {progressIndicator}

        <MultiUseDialog Details={DialogDetails} />

        {disconnectedContent}
      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(QuickBooksDisconnect);