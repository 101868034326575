import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';

import DashboardCard from '../Components/DashboardCard';
import BillingDashboardCard from '../Components/BillingDashboardCard';
import PaymentHistoryDashboardCard from '../Components/PaymentHistoryDashboardCard';
import UiCore from '../Components/UiCore';
import ChartTooltip from '../Components/ChartTooltip';
import { GlobalContext } from '../Context/Global.context';
import { ValidateEmail } from '../Util/Regex';

import InfoIcon from '@material-ui/icons/InfoOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import { ContentCopyIcon } from '../Util/Icons';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  CurrencyWithSeparators,
  NumberWithSeparators,
} from '../Util/NumberFormatting';
import { IsMobile } from '../Util/MobileDetector';

import API, {
  GetCompanyPagesProcessedStatsPathForApi,
  GetCompanyEmailAddressPrefixPathForApi,
} from '../Util/api';
import {
  GetBillingPath,
} from '../Util/PathHelper';
import {
  GetStatUTCKey,
} from '../Util/TimeSeriesStats';
import {
  CardClasses,
} from '../Util/Cards';
import ProgressIndicator from '../Components/ProgressIndicator';
import { LineChart } from 'eazychart-react';
import 'eazychart-css';
import dateformat from 'dateformat';
import debounce from 'es6-promise-debounce';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

const _chartId = "cardPagesProcessedChart";
const _chartWidth = (IsMobile()) ? 1000 : 2000;
const _chartHeight = 300;
const requiredChartPoints = 30;

const getSampleChartData = highlightColor => {
  let chartData = [];
  let nextDate = new Date();
  for (let x = requiredChartPoints - 1; x >= 0; x--) {
    const datum = {
      x,
      y: Math.floor(Math.random() * (20 - 0) + 0),
      text: dateformat(nextDate, "mmm d", true),
      date: nextDate,
      highlightColor,
    };
    let d = datum.date;
    d.setDate(datum.date.getDate() - 1);
    nextDate = d;
    chartData.push(datum);
  }
  return chartData.reverse();
}

class LineChartTooltip extends Component {
  render() {
    const {
      datum,
    } = this.props;
    const pages = (datum && datum["y"]) || 0;

    return (
      <ChartTooltip
        {...this.props}
        chartId={_chartId}
        chartWidth={_chartWidth}
        chartHeight={_chartHeight}
      >
        <Grid container direction="column" spacing={1}>
          <Grid item style={{color:datum && datum.highlightColor}}>
            {datum && datum["text"]}
          </Grid>
          <Grid item>
          <span>
            {pages}
          </span>
          <span style={{color:datum && datum.highlightColor}}>
            {` Page${(pages === 1) ? "" : "s"}`}
          </span>
          </Grid>
        </Grid>
      </ChartTooltip>
    );
  }
}


const styles = theme => ({
  ...CardClasses(theme),
  purple: {
    color: theme.palette.text.purple,
  },
  teal: {
    color: theme.palette.text.teal,
  },
  lightBlue: {
    color: theme.palette.text.lightBlue,
  },
  chart: {
    position:"relative", // Necessary to ensure ChartTooltip is constrained within
    margin:theme.spacing(3),
    marginTop:theme.spacing(1),
    display:"flex",
    justifyContent:"flex-end",
    overflow:"hidden",
    "& .ez-legend": {
    },
    "& .ez-legend-key": {
    },
    "& .ez-legend-box": {
    },
    "& .ez-legend-text": {
    },
    "& .ez-axis-path": {
      stroke:"transparent",
    },
    "& .ez-axis-tick": {
      stroke:"transparent",
    },
    "& .ez-axis-tick-text": {
    },
    "& .ez-grid-line": {
    },
    "& .ez-point": {
      stroke:theme.palette.text.purple,
      fill:theme.palette.text.lightBlue,
      // stroke:"transparent",
      // fill:"transparent",
    }
  },
  tooltip: {
    maxWidth:"initial",
  },
});

class Dashboard extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      EditEmail: false,
      PagesProcessedStats: [],
      SubscriptionInvoices: null,
      PopoverText: null,
      OriginalEmailAddressPrefix: undefined,
      EmailAddressPrefix: "",
      EmailAddressPrefixIsAvailable: undefined,
      EmailAddressPrefixIsValid: undefined,
      ShowProgressIndicatorImmediately: false,
      ShowPagesProcessedProgressIndicatorImmediately: true,
      ShowEmailAddressPrefixProgressIndicatorImmediately: false,
    }

    this.SampleChartData = getSampleChartData(this.getHighlighColorForChartDatum());
    this.EmailAddressRef = React.createRef();
    this.PopoverAnchorRef = React.createRef();
  }

  componentDidMount() {
    this.context.GetUser(true)
      .then(user => {
        this.setState({EmailAddressPrefix:user.EmailAddressPrefix});
        this.loadPagesProcessedStats();
      })
      .catch(this.handleApiError);
  }

  handleApiError = ApiError => {
    this.setState({ApiError});
  }

  handleUpgradeClick = e => {
    this.props.history.push(GetBillingPath(), { ...this.props.location.state, AddPayment: true })
  }

  loadPagesProcessedStats = () => {
    this.setState({ShowPagesProcessedProgressIndicatorImmediately:true});
    API.get(GetCompanyPagesProcessedStatsPathForApi(this.context.User.CompanyID))
      .then(resp => {
        let PagesProcessedStats = resp.data.Stats;
        // Ignore showing stats for tomorrow (local timezone is negative UTC)
        PagesProcessedStats = PagesProcessedStats.filter(s => new Date(s.Date.substr(0, s.Date.length-1)) <= new Date());
        this.setState({
          PagesProcessedStats,
        });
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowPagesProcessedProgressIndicatorImmediately:false});
      });
  }

  handleBeginEditEmail = () => {
    // if (OriginalEmailAddressPrefix === undefined) {
    // }
    this.setState({
      OriginalEmailAddressPrefix: this.state.EmailAddressPrefix,
      EditEmail:true,
    })
  }

  handleEmailAddressPrefixValueChange = e => {
    let OriginalEmailAddressPrefix = this.state.OriginalEmailAddressPrefix;
    let EmailAddressPrefix = undefined;
    let EmailAddressPrefixIsAvailable = undefined;

    const newEmailAddressPrefix = e.target.value;
    if (newEmailAddressPrefix)
    {
      EmailAddressPrefix = newEmailAddressPrefix;
      EmailAddressPrefixIsAvailable = undefined;
    }
    else {
      EmailAddressPrefix = OriginalEmailAddressPrefix;
    }

    this.setState({
      OriginalEmailAddressPrefix,
      EmailAddressPrefix,
      EmailAddressPrefixIsAvailable,
    });

    this.handleValidateEmailAddressPrefix(EmailAddressPrefix);
  }

  getEmailAddress = emailAddressPrefix => {
    return `${emailAddressPrefix}@${process.env.REACT_APP_DOMAIN}`;
  }

  handleValidateEmailAddressPrefix = debounce((EmailAddressPrefix) => {
    let EmailAddressPrefixIsValid =
      EmailAddressPrefix.length <= 64
        && ValidateEmail(this.getEmailAddress(EmailAddressPrefix));
    let EmailAddressPrefixIsAvailable = undefined;
    this.setState({
      EmailAddressPrefixIsAvailable,
      EmailAddressPrefixIsValid,
    });

    if (!EmailAddressPrefixIsValid) {
      return;
    }

    if (this.state.OriginalEmailAddressPrefix !== EmailAddressPrefix) {
      this.setState({ShowEmailAddressPrefixProgressIndicatorImmediately: true});
    }
    API.get(GetCompanyEmailAddressPrefixPathForApi(this.context.User.CompanyID, EmailAddressPrefix))
      .then(resp => {
        EmailAddressPrefixIsAvailable = true;
      })
      .catch(err => {
        if (err && err.response && err.response.status === 400) {
          EmailAddressPrefixIsAvailable = false;
        }
      })
      .finally(() => {
        this.setState({
          EmailAddressPrefixIsAvailable,
          ShowEmailAddressPrefixProgressIndicatorImmediately: false,
        });
      });
  }, 250);

  handleUpdateEmailAddressPrefix = () => {
    this.setState({ ShowProgressIndicatorImmediately: true });
    API.put(GetCompanyEmailAddressPrefixPathForApi(this.context.User.CompanyID, this.state.EmailAddressPrefix))
      .then(resp => {
        let additionalStateToUpdate = {};
        if (resp.data && this.state.EmailAddressPrefix !== resp.data) {
          additionalStateToUpdate.EmailAddressPrefix = resp.data;
        }
        this.setState({
          EditEmail: false,
          EmailAddressPrefixIsValid: undefined,
          EmailAddressPrefixIsAvailable: undefined,
          OriginalEmailAddressPrefix: undefined,
          ...additionalStateToUpdate,
        });
        this.context.GetUser();
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ ShowProgressIndicatorImmediately: false });
      });
  }

  handleCancelEmailAddressPrefixChange = () => {
    this.setState({
      EditEmail:false,
      EmailAddressPrefix: this.state.OriginalEmailAddressPrefix,
      OriginalEmailAddressPrefix: undefined,
    });
  }

  getHighlighColorForChartDatum = () => {
    return this.props.theme.palette.text.purple;
  }

  getChartDataFromStats = stats => {
    let chartData = [];
    let nextDate = new Date();
    for (let x = requiredChartPoints; x > 0; x--) {
      const statIndex = requiredChartPoints - x;
      const datum = (stats.length > statIndex)
        ? {
          x,
          y: stats[statIndex][GetStatUTCKey()],
          text: dateformat(new Date(stats[statIndex].Date), "mmm d", true),
          date: new Date(stats[statIndex].Date),
          // dataAsOf: (x <= 1) ? dataAsOf : undefined,
          highlightColor: this.getHighlighColorForChartDatum(),
        }
        : {
          x,
          y: 0,
          text: dateformat(nextDate, "mmm d", true),
          date: nextDate,
          highlightColor: this.getHighlighColorForChartDatum(),
        };
      let d = datum.date;
      d.setDate(datum.date.getDate() - 1);
      nextDate = d;
      chartData.push(datum);
    }

    return chartData.reverse();
  }

  getPagesProcessedLineChart = data => {
    const {
      theme,
    } = this.props;

    return (
      <LineChart
        dimensions={{
          width: _chartWidth,
          height: _chartHeight,
        }}
        grid={{
          directions: []
        }}
        line={{
          beta: 1,
          curve: 'curveNatural',//'curveBundle',
          stroke: theme.palette.text.purple,
          strokeWidth: 5
        }}
        padding={{
          bottom: theme.spacing(5),
          left: theme.spacing(6),
          right: theme.spacing(5),
          top: theme.spacing(10),
        }}
        xAxis={{
          domainKey: 'x',
          nice: 0,
          tickFormat: function noRefCheck(){},
          // title: ''
        }}
        yAxis={{
          domainKey: 'y',
          nice: 0,
          tickFormat: function noRefCheck(){},
          // title: ''
        }}
        marker={{
          hidden: false,
          radius: 10,
        }}
        animationOptions={{
          delay: 0,
          duration: 400,
          easing: 'easeBack'
        }}
        data={data}
        scopedSlots={{
          TooltipComponent: LineChartTooltip,
        }}
      />
    );
  }

  handleShowPopover = (PopoverText, popoverAnchorRef) => {
    this.setState({ PopoverText });
    this.PopoverAnchorRef = popoverAnchorRef;
    setTimeout(() => this.setState({ PopoverText: null }), 1500); 
  }

  render() {
    const {
      PagesProcessedStats,
      EditEmail,
      PopoverText,
      OriginalEmailAddressPrefix,
      EmailAddressPrefix,
      EmailAddressPrefixIsValid,
      EmailAddressPrefixIsAvailable,
      ApiError,
      ShowProgressIndicatorImmediately,
      ShowPagesProcessedProgressIndicatorImmediately,
      ShowEmailAddressPrefixProgressIndicatorImmediately,
    } = this.state;
    const {
      cookies,
      theme,
      classes,
    } = this.props;
    const {
      User,
      Subscription,
      SubscriptionUsage,
    } = this.context;

    // const pagesProcessedData = this.SampleChartData;
    const pagesProcessedData = this.getChartDataFromStats(PagesProcessedStats);

    const subscriptionIsFree = Subscription && Subscription.PaymentSubscriptionID === "";
    const freePagesPerBillingCycle = Subscription && Subscription.FreePagesPerBillingCycle;
    const allTimePagesProcessed = SubscriptionUsage && SubscriptionUsage.AllTimePagesProcessed;
    const freePlanPagesProcessedWithoutAi = (allTimePagesProcessed > freePagesPerBillingCycle)
      ? allTimePagesProcessed - freePagesPerBillingCycle
      : 0;
    const freePlanFreePagesSurpassedContent = 
      (subscriptionIsFree && allTimePagesProcessed > freePagesPerBillingCycle)
        ? (
          <Tooltip placement="left" arrow
            title={`You're on the free plan. Upgrade now to realize these savings! ${
              freePlanPagesProcessedWithoutAi
            } page${(freePlanPagesProcessedWithoutAi) > 1 ? "s haven't" : " hasn't"} leveraged AI technology.`}
          >
            <IconButton size="small" style={{color:red[500],marginLeft:theme.spacing(1)}}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        ) : null;

    const cardMoneySaved = (
      <Grid item xs={(IsMobile()) ? 12 : 4}>
        <DashboardCard>
          <div className={classes.infoCardContainer}>
            <div className={classes.infoCardData}>
              <span className={classes.teal}>
                {
                  (SubscriptionUsage)
                    ? CurrencyWithSeparators(5.13 * SubscriptionUsage.AllTimeBillsCreated)
                    : "\u2014"
                }
              </span>
            </div>
            <div className={classes.infoCardTitle}>
              <span>
                Money Saved
              </span>
              {freePlanFreePagesSurpassedContent}
            </div>
          </div>
        </DashboardCard>
      </Grid>
    );

    const cardInvoicesProcessed = (
      <Grid item xs={(IsMobile()) ? 12 : 4}>
        <DashboardCard>
          <div className={classes.infoCardContainer}>
            <div className={classes.infoCardData}>
              <span className={classes.purple}>
                {
                  (SubscriptionUsage)
                    ? NumberWithSeparators(SubscriptionUsage.BillingCycleBillsCreated)
                    : "\u2014"
                }
              </span>
            </div>
            <div className={classes.infoCardTitle}>
              <span>
                Invoices processed <br /> this month
              </span>
            </div>
          </div>
        </DashboardCard>
      </Grid>
    );

    const cardFreePages = (
      <Grid item xs={(IsMobile()) ? 12 : 4}>
        <DashboardCard>
          <div className={classes.infoCardContainer}>
            <div className={classes.infoCardData}>
              <span className={classes.lightBlue}>
                {
                  (Subscription && SubscriptionUsage)
                    ? NumberWithSeparators(Math.max(0, Subscription.FreePagesPerBillingCycle - SubscriptionUsage.BillingCycleFreePagesProcessed))
                    : "\u2014"
                }
              </span>
            </div>
            <div className={classes.infoCardTitle}>
              <span>
                Free Pages <br /> remaining
              </span>
            </div>
          </div>
        </DashboardCard>
      </Grid>
    );

    const emailAddressPrefixIsUpdated = OriginalEmailAddressPrefix !== undefined
      && OriginalEmailAddressPrefix.toLowerCase() !== EmailAddressPrefix.toLowerCase();
    const emailAddressPrefixHelperText = (ShowEmailAddressPrefixProgressIndicatorImmediately)
      ? (
        <div style={{position:"relative",display:"flex",justifyContent:"flex-end"}}>
          <ProgressIndicator showImmediately constrained noBackground
            style={{
              marginTop:12,
              width:12,
              height:12,
            }}
            containerStyle={{
              width:"initial",
              top:"initial",
              left:"initial",
            }}
          />
        </div>
      )
      : (emailAddressPrefixIsUpdated)
        ? (EmailAddressPrefixIsValid === false)
          ? "Address invalid"
          : (typeof EmailAddressPrefixIsAvailable === "boolean")
            ? (EmailAddressPrefixIsAvailable)
              ? "Address available"
              : "Address unavailable"
            : null
        : null;
    const emailAddressFormHelperTextProps =
      (emailAddressPrefixIsUpdated)
        ? (EmailAddressPrefixIsAvailable === true)
          ? { style: {color: green[500], textAlign:"right"} }
          : { style: {color: red[500], textAlign:"right"} }
        : null;
    let updateEmailAddressPrefixButtons = [];
    if (emailAddressPrefixIsUpdated && EmailAddressPrefixIsAvailable) {
      updateEmailAddressPrefixButtons.push(
        <Button
          key="b_changeEmail"
          style={{backgroundColor: green[600],color:"white",margin:"-3px 4px 0px 4px"}}
          size="small"
          // color="secondary"
          aria-label="Change"
          onClick={() => this.handleUpdateEmailAddressPrefix()}
          variant="contained"
        >
          CHANGE
        </Button>  
      );
    }
    updateEmailAddressPrefixButtons.push(
      <Button
        key="b_cancelEmailChange"
        style={{backgroundColor: red[600],color:"white",margin:"-3px 4px 0px 4px"}}
        size="small"
        // color="secondary"
        aria-label="Cancel"
        onClick={() => this.handleCancelEmailAddressPrefixChange()}
        variant="contained"
      >
        CANCEL
      </Button>
    );

    const getCopyEmailToClipboardContent = content => {
      return (
        <CopyToClipboard
          key="ctc_putEmailAddressInClipboard"
          text={currentEmailAddress}
          onCopy={() => this.handleShowPopover("Copied to clipboard!", this.EmailAddressRef)}
        >
          {content}
        </CopyToClipboard>
      );
    }

    const currentEmailAddress = (EmailAddressPrefix)
      ? `${EmailAddressPrefix}@${process.env.REACT_APP_DOMAIN}`
      : User && User.InvoiceEmailAddress;
    const emailDomainDiv = (!IsMobile())
      ? (
        <div style={{marginLeft:4,marginTop:9,marginRight:8,fontSize:16,}}>
          {`@${process.env.REACT_APP_DOMAIN}`}
        </div>
      ) : null;
    let emailContent = (EditEmail)
      ? (
        <div style={{
          display:(!IsMobile()) ? "flex" : undefined,
          marginTop:theme.spacing(2.5),
        }}>
          <div style={{width:270,minHeight:64,}}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              autoFocus
              value={EmailAddressPrefix}
              onChange={this.handleEmailAddressPrefixValueChange}
              inputProps={{
                style:{textAlign:"right",},
              }}
              helperText={emailAddressPrefixHelperText}
              FormHelperTextProps={emailAddressFormHelperTextProps}
            />
          </div>
          {emailDomainDiv}
          <div style={{position:"relative",marginTop:6,}}>
            {updateEmailAddressPrefixButtons}
          </div>
        </div>
      )
      : (
          <div style={{display:"flex",marginTop:theme.spacing(3),alignItems:"center"}}
            ref={instance => this.EmailAddressRef = instance}
          >
            <div style={{flexGrow:1,overflowX:"hidden"}}>
              <Tooltip title={(currentEmailAddress) || ""} classes={{tooltip:classes.tooltip}}>
                {getCopyEmailToClipboardContent(
                  <span style={{fontSize:18,cursor:"pointer",overflowWrap:"break-word",}}>
                    {currentEmailAddress}
                  </span>
                )}
              </Tooltip>
            </div>
            <div style={{marginRight:-4}}>
              {getCopyEmailToClipboardContent(
                <Tooltip placement="left" arrow
                  title="Copy to clipboard"
                >
                  <IconButton size="small">
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div style={{marginLeft:8,marginRight:-4}}>
              <Tooltip placement="left" arrow
                title="Change email address"
              >
                <IconButton size="small" onClick={() => this.handleBeginEditEmail()}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
      );

    const invoiceInboxTooltipTitle = `Send invoices to ${this.getEmailAddress(EmailAddressPrefix)}. Each invoice will become a bill in QuickBooks.`;
    const invoiceInboxTooltip = (
      <Tooltip placement="left" arrow
        enterTouchDelay={(IsMobile()) ? 0 : undefined}
        leaveTouchDelay={(IsMobile()) ? 3000 : undefined}
        title={invoiceInboxTooltipTitle}
      >
        <IconButton size="small">
          <InfoIcon />
        </IconButton>
      </Tooltip>
    );
    const cardInvoiceInbox = (
      <Grid item xs={12}>
        <DashboardCard>
          <div style={{display:"flex",flexDirection:"column"}}>

            <div style={{
              display:"flex",
              alignItems:"center",
              marginTop:(!IsMobile()) ? theme.spacing(2) : undefined,
            }}>
              <div className={classes.cardTitle} style={{flexGrow:1}}>
                <span>
                  Invoice Inbox
                </span>
              </div>
              <div style={{marginRight:-4}}>
                {invoiceInboxTooltip}
              </div>
            </div>

            {emailContent}

          </div>
        </DashboardCard>
      </Grid>
    );
    
    const renderPaymentHistory = !Subscription?.PaymentPartnerID && !cookies.get("native_app_mode");

    const cardPagesProcessedContent = (ShowPagesProcessedProgressIndicatorImmediately)
      ? (
        <div style={{
          height:270,
          width:"100%",
          position:"relative",
        }}>
          <ProgressIndicator noBackground constrained showImmediately />
        </div>
      )
      : this.getPagesProcessedLineChart(pagesProcessedData);
    const cardPagesProcessed = (
        <Grid item xs={12}>
          <DashboardCard
            height={_chartHeight + 64}
            style={{
              marginBottom:theme.spacing(3),
              paddingRight:theme.spacing(0),
            }}
          >
            <div id={_chartId} style={{display:"flex", flexDirection:"column"}}>
              <div className={classes.cardTitle} style={{flexGrow:1}}>
                <span>
                  Pages Processed
                </span>
              </div>
              <div className={classes.chart}>
                {cardPagesProcessedContent}
              </div>
            </div>
          </DashboardCard>
        </Grid>
      );

    let cardPaymentHistory = null;
    if (renderPaymentHistory) {
      cardPaymentHistory = (
        <Grid item xs={12}>
          <PaymentHistoryDashboardCard
            onApiError={this.handleApiError}
          />
        </Grid>
      );
    }

    const billingDashboardCard = (
      <Grid item xs={12}>
        <BillingDashboardCard
          onUpgradeClick={this.handleUpgradeClick}
          onApiError={this.handleApiError}
        />
      </Grid>
    );

    const desktopGridItemLeftColumn = (
      <Grid item xs={(IsMobile()) ? 12 : 7}>
        <Grid container spacing={3}>
          {cardMoneySaved}
          {cardInvoicesProcessed}
          {cardFreePages}
          {cardPagesProcessed}
          {cardPaymentHistory}
        </Grid>
      </Grid>
    );

    const desktopGridItemRightColumn = (
      <Grid item xs={(IsMobile()) ? 12 : 5}>
        <Grid container spacing={3}>
          {cardInvoiceInbox}
          {billingDashboardCard}
        </Grid>
      </Grid>
    );

    const contentGrid = (IsMobile())
      ? (
        <Grid container spacing={3}>
          {cardInvoiceInbox}
          {billingDashboardCard}
          {cardInvoicesProcessed}
          {cardPagesProcessed}
          {cardFreePages}
          {cardMoneySaved}
          {cardPaymentHistory}
        </Grid>
      ) : (
        <Grid container spacing={3}>
          {desktopGridItemLeftColumn}
          {desktopGridItemRightColumn}
        </Grid>
      );

    const popover = (PopoverText)
      ? (
        <Popover
          open={Boolean(PopoverText)}
          onClose={() => this.handleShowPopover(null, null)}
          anchorOrigin={{
            vertical:'center',
            horizontal:'center',
          }}
          anchorEl={this.PopoverAnchorRef}
          anchorReference={"anchorEl"}
          transformOrigin={{
            vertical:'center',
            horizontal:'center',
          }}
        >
        {PopoverText}
      </Popover>
    ) : null;

    const content = (
      <React.Fragment>
        {popover}
        {contentGrid}
      </React.Fragment>
    );

    return (
      <UiCore
        title="Dashboard"
        content={content}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        apiError={ApiError}
        showFabOnMobileForReceiptCapture
      />
    );
  }
}

export default withCookies(withStyles(styles, { withTheme: true })(Dashboard));
