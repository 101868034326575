import React, { Component } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  progress: {
    // margin: theme.spacing(2),
    color:theme.palette.type === "dark" ? "#888" : theme.palette.primary.main,
  },
  progressContainer: {
    position: "fixed",
    top:0,
    left:0,
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.3)",
    zIndex: 1250,
    alignItems:"center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
});

class ProgressIndicator extends Component {
  render() {
    const {
      classes,
      showImmediately,
      showAfter2s,
      constrained,
      noBackground,
      containerStyle,
      style,
      linear,
      preContent,
      postContent,
    } = this.props;

    let delay = "800ms";
    if (showImmediately) {
      delay = "0";
    } else if (showAfter2s) {
      delay = "2000ms";
    }

    let circularContent, linearContent;
    if (linear) {
      linearContent = (
        <LinearProgress
          style={{
            width:"80%",
            ...style,
          }}
          className={classes.progress}
        />
      );
    } else {
      circularContent = (
        <CircularProgress
          style={{
            ...style,
          }}
          className={classes.progress}
        />
      );
    }

    return (
      <Fade in style={{transitionDelay: delay}}>
        <div
          className={classes.progressContainer}
          style={{
            position:(constrained) ? "absolute" : undefined,
            background: (noBackground) ? 0 : undefined,
            ...containerStyle,
          }}
          onContextMenu={e => e.preventDefault()}
        >
          {preContent}
          {circularContent}
          {linearContent}
          {postContent}
        </div>
      </Fade>
    );
  }
}

export default withStyles(styles, {withTheme: true})(ProgressIndicator);