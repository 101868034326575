import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { GlobalContext } from '../Context/Global.context';

import {
  SignOut,
} from '../Util/SignOut';

class Signout extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
     redirectPath: null,
    }
  }

  signOutWhenReady = () => {
    if (this.context) {
      return SignOut(this.props);
    } else {
      setTimeout(() => this.signOutWhenReady(), 250);
    }
  }

  componentDidMount() {
    this.signOutWhenReady();
  }

  render() {
    if (this.state.redirectPath) {
      return (
        <Redirect to={this.state.redirectPath} />
      );
    }

    return (
      <div></div>
    );
  }
}

export default Signout;
