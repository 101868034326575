import API, {
  GetUserPreferencesPathForApi,
} from '../Util/api';

const getSingleUserPreference = id => {
  return API.get(`${GetUserPreferencesPathForApi()}/${id}`)
    .then(resp => {
      return resp.data;
    });
}

export const GetSingleUserPreference_String = id => {
  return getSingleUserPreference(id)
    .then(pref => {
      return pref.StringValue;
    })
}

export const GetSingleUserPreference_Bool = id => {
  return getSingleUserPreference(id)
    .then(pref => {
      return pref.BoolValue;
    });
}

export const GetSingleUserPreference_Object = id => {
  return getSingleUserPreference(id)
    .then(pref => {
      return pref.MapValue;
    })
}

const saveSingleUserPreference = (id, singleUserPreference) => {
  if (!singleUserPreference || !id) {
    return Promise.resolve();
  }

  return API.put(`${GetUserPreferencesPathForApi()}/${id}`, singleUserPreference);
}

export const SaveSingleUserPreference_String = (id, StringValue) => {
  return saveSingleUserPreference(id, {StringValue});
}

export const SaveSingleUserPreference_Bool = (id, BoolValue) => {
  return saveSingleUserPreference(id, {BoolValue});
}

export const SaveSingleUserPreference_Object = (id, MapValue) => {
  return saveSingleUserPreference(id, {MapValue});
}