import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import DashboardCard from './DashboardCard';

import {
  CardClasses,
} from '../Util/Cards';

import { NumberWithSeparators } from '../Util/NumberFormatting';
import { GlobalContext } from '../Context/Global.context';

import ProgressIndicator from '../Components/ProgressIndicator';

import { IsMobile } from '../Util/MobileDetector';
import 'eazychart-css';

import dateformat from 'dateformat';

import red from '@material-ui/core/colors/red';

const styles = theme => ({
  ...CardClasses(theme),
});

class PaymentHistoryDashboardCard extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      // ShowProgressIndicatorImmediately: true,
    };
  }
  handleApiError = ApiError => {
    this.props.onApiError(ApiError);
    if (ApiError) {
      setTimeout(() => this.handleApiError(null), 100);
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
  }
  
  render() {
    const {
      classes,
      theme,
      cardHeight = 200,
      displayCardContentsOnly,
    } = this.props;
    const {
      SubscriptionInvoices,
    } = this.context;

    const progressIndicator = (!SubscriptionInvoices)
      ? (
        <div style={{ width: "100%", height: "100%" }}>
          <ProgressIndicator noBackground constrained showImmediately />
        </div>
      ) : null;

    let content = (
      <div style={{position:"relative",height:"100%",}}>
        {progressIndicator}
      </div>
    );

    if (SubscriptionInvoices) {
      const paymentHistoryContent = this.buildPaymentHistoryContent(theme, SubscriptionInvoices);

      if (displayCardContentsOnly) {
        return paymentHistoryContent;
      }
      
      const wrapperHeight = cardHeight - 35;
      content = (
        <div style={{ height: wrapperHeight, display: "flex", flexDirection: "column", overflowY: "hidden" }}>
          <div className={classes.cardTitle}>
            <span>
              Payment History
            </span>
          </div>
          <div style={{
            position: "relative",
            marginTop: theme.spacing(2),
            overflowX: "hidden",
            overflowY: "auto",
            flexGrow: 1,
          }}>
            {paymentHistoryContent}
          </div>
        </div>
      );
    }

    if (displayCardContentsOnly) {
      return content;
    }

    return (
      <DashboardCard height={cardHeight}>
        {content}
      </DashboardCard>
    );
  }

  buildPaymentHistoryContent(theme, SubscriptionInvoices) {
    const getInvoiceStatus = si => {
      let status = "";
      let color = theme.palette.text.purple;
      switch (si.Status) {
        case "paid":
          status = "Paid";
          color = theme.palette.text.purple;
          break;
        case "open":
          status = "Open";
          color = red[500];
          break;
        default:
          return null;
      }
      return (
        <span style={{
          color,
          border: `2px solid ${color}`,
          borderRadius: 8,
          display: "inline-block",
          padding: "4px 6px",
        }}>
          {status}
        </span>
      );
    };

    const subscriptionInvoiceGridItems = (SubscriptionInvoices)
      ? SubscriptionInvoices
        .filter(si => si.Status === "paid" || si.Status === "open")
        .filter(si => si.AmountDue > 0)
        .map(si => (
          <React.Fragment key={`rf_${si.ID}`}>
            <Grid item xs={3}>
              {dateformat(si.CreatedOn, (IsMobile()) ? "mmm d" : "mmm d, yyyy", true)}
            </Grid>
            <Grid item xs={3}>
              {getInvoiceStatus(si)}
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              ${NumberWithSeparators(si.AmountDue.toFixed(2))}
            </Grid>
            <Grid item xs={2}>
              <IconButton size="small" onClick={() => window.open(si.PDFUrl, "apc_subscriptionInvoice")}>
                <ArrowForwardIcon />
              </IconButton>
            </Grid>
          </React.Fragment>
        ))
      : [];

    const subscriptionInvoiceGrid = (SubscriptionInvoices)
      ? (
        <Grid container spacing={1} style={{ alignItems: "center" }}>
          {subscriptionInvoiceGridItems}
        </Grid>
      ) : null;

    const paymentHistoryContent = (!SubscriptionInvoices)
      ? (
        <ProgressIndicator noBackground constrained showImmediately />
      )
      : subscriptionInvoiceGrid;
    return paymentHistoryContent;
  }
}

export default withStyles(styles, { withTheme: true })(PaymentHistoryDashboardCard);

PaymentHistoryDashboardCard.propTypes = {
  classes: PropTypes.object.isRequired,
  onApiError: PropTypes.func.isRequired,
  updateKey: PropTypes.any,
  companyIdOverride: PropTypes.string,
  displayCardContentsOnly: PropTypes.bool,
  cardHeight: PropTypes.number,
};

PaymentHistoryDashboardCard.defaultProps = {
  displayCardContentsOnly: false,
};