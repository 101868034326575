import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';

import { NumericFormat } from 'react-number-format';

import { IsMobile } from '../Util/MobileDetector';

import PropTypes from 'prop-types';

const styles = theme => ({
});

class NumericTextField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: (new Date()).toString(),
    };
  }

  getNumericInput = numericInputProps => {
    const { inputRef, onChange, ...other } = numericInputProps;
    return (
      <NumericFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: numericInputProps.name,
              value: values.value,
            },
          });
        }}
        allowNegative={this.props.allowNegative || false}
        thousandSeparator
        valueIsNumericString
        decimalScale={(this.props.isCurrency) ? 2 : this.props.decimalPlaces || 0}
        fixedDecimalScale
        isAllowed={this.props.isAllowedFunc}
        prefix={this.props.isCurrency ? "$" : ""}
      />
    );

    // this.NumericInput.propTypes = {
    //   inputRef: PropTypes.func.isRequired,
    //   name: PropTypes.string,
    //   onChange: PropTypes.func.isRequired,
    // };
  }

  handleClearValue = () => {
    this.props.onValueChange("");
  }

  handleKeyDown = e => {
    if (this.props.onEnterKey && e.keyCode === 13) {
      e.preventDefault();
      this.props.onEnterKey();
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  }

  render() {
    const { 
      // classes,
      // theme, 
      id,
      updateId,
      autoFocus,
      label,
      required,
      value,
      onValueChange,
      disabled,
      size,
      isReadOnly,
      hideClearButton,
      forceShrinkLabel,
      style,
      placeholder,
      inputProps,
      tabIndex,
      helperText,
      formHelperTextProps,
    } = this.props;

    let fieldLabel = (label) 
      ? label + ((required) ? " *" : "")
      : null;

    let clearInputAdornment = 
    (value && !disabled) 
      ? (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="clear"
              onClick={this.handleClearValue}
            >
              <ClearIcon style={{fontSize:18}} />
            </IconButton>
          </InputAdornment>
        ) 
      : null;

    let fieldControl = null;
    if (isReadOnly) {
      if (value) {
        fieldControl = (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography variant="h6">
                {fieldLabel}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {value}
              </Typography>
            </Grid>
          </Grid>
        );
      }
    } else {
      fieldControl = (
        <TextField
          label={fieldLabel}
          value={value}
          size={size}
          fullWidth
          disabled={disabled}
          onChange={onValueChange}         
          variant="outlined"
          autoFocus={!IsMobile() && autoFocus}
          autoComplete="off"
          id={id}
          key={updateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
          onKeyDown={this.handleKeyDown}
          InputProps={{
            inputComponent: this.getNumericInput,
            endAdornment: !hideClearButton && clearInputAdornment,
            ...inputProps,
          }}
          InputLabelProps={{
            shrink: Boolean(value) || forceShrinkLabel,
          }}
          style={style}
          placeholder={placeholder}
          inputProps={{ tabIndex }}
          helperText={helperText}
          FormHelperTextProps={formHelperTextProps}
        />
      );
    }

    return fieldControl;
  }
}

NumericTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  decimalPlaces: PropTypes.number,
  allowNegative: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  onEnterKey: PropTypes.func,
  isAllowedFunc: PropTypes.func,
  forceShrinkLabel: PropTypes.bool,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  tabIndex: PropTypes.number,
  helperText: PropTypes.string,
  formHelperTextProps: PropTypes.object,
  isCurrency: PropTypes.bool,
  inputProps: PropTypes.object,
};

export default withStyles(styles, {withTheme: true})(NumericTextField);