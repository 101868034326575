import React, { Component } from 'react';

import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { NumberWithSeparators } from '../Util/NumberFormatting';
import API, { UploadFiles } from '../Util/api';
import axios from 'axios';
import { IsMobile } from '../Util/MobileDetector';

const styles = theme => ({
  dropZoneContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fileDropZone: {
    height: "100%",
  },
  gridContainer: {
    position:"relative",
    margin:"auto",
    width:300,
  },
  gridItem: {
    textAlign:"center",
  },
});

const UploadProgressItem = {
  FileName: "",
  Content: null,
};

export const HandleUploadProgress = (props, getCancelToken, getState, setState, handleAlert) =>
  (fileName, totalSize, completedSize, completedAtServer, completionResponse, completionError) => {
  
  let percentComplete = 100 * ((1 + completedSize) / totalSize);

  let items = [...getState("UploadProgressItems")];
  let itemIndex = -1;
  for (let i = 0; i < items.length; i++) {
    if (items[i].FileName === fileName) {
      itemIndex = i;
      break;
    }
  }
  if (itemIndex === -1) {
    let newItem = {...UploadProgressItem};
    newItem.FileName = fileName;
    items = items.concat(newItem);
    itemIndex = items.length - 1;
  }
  items[itemIndex].CompletedAtServer = completedAtServer;
  items[itemIndex].CompletionResponse = completionResponse;
  items[itemIndex].CompletionError = completionError;
  items[itemIndex].Content = (
    <div key={fileName} style={{marginBottom:16,overflowX:"hidden"}}>
      <DialogContentText>{fileName}</DialogContentText>
      <LinearProgress 
        color="primary"
        style={{
          backgroundColor: "#ddd",
        }}
        variant="determinate"
        value={percentComplete}
      />
    </div>
  );

  setState({UploadProgressItems: items});

  let bodyContent = [];
  let allComplete = true;
  for (let i = 0; i < items.length; i++) {
    bodyContent.push(items[i].Content);
    if (!items[i].CompletedAtServer) {
      allComplete = false
    }
  }

  handleAlert({ 
    Closed: allComplete,
    Title: "Upload Progress",
    BodyContent: bodyContent,
    FullWidth: true,
    CloseLabel: "Cancel",
    CloseCallback: () => getCancelToken().cancel(),
    DisableBlurClose: true,
  });

  if (items.length === getState("FileCount") && allComplete) {
    if (props.skipCompleteAlert) {
      if (props.onClose) {
        props.onClose(items);
      }
    } else {
      const completionMessageContent = 
        (!props.reservationUri)
          ? (
            <div>
              Uploads are being processed and will be available momentarily.
            </div>
            )
          : null;
            
      handleAlert({ 
        Title: "Upload complete",
        BodyContent: completionMessageContent,
        DialogWidth: "xs",
        CloseCallback: () => { if (props.onClose) props.onClose(items); },
      });
    }
  }
}

class CaptureCore extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      UploadProgressItems: [],
      FileCount: 0,
    }

    this.CancelToken = null;
    this.OpenFunc = null;
  }

  handleFileDrop = async files => {
    let reservationUri;
    if (this.props.reservationUri) {
      reservationUri = this.props.reservationUri;
    }
    const reservationParams = 
      (this.props.reservationParams)
        ? this.props.reservationParams
        : {};

    if (this.props.singleFile) {
      // singleFile
      if (files.length > 1) {
        files = [files[0]];
      }

      // singleFileMaxSize
      if (this.props.singleFileMaxSize && files[0].size > this.props.singleFileMaxSize) {
        return this.handleApiError(`File size must be ${NumberWithSeparators(this.props.singleFileMaxSize)} bytes or smaller.`);
      }
      
      // singleFileDisallowGIF
      if (this.props.singleFileDisallowGIF) {
        const foundGif = await new Promise((resolve) => {
          const isGif = result => {
            return /^GIF/.test(result);
          }
          const reader = new FileReader();
          reader.addEventListener('load', () => resolve(
            isGif(reader.result)
          ));
          reader.readAsText(files[0]);
        });
        if (foundGif) {
          return this.handleApiError("GIF uploads are not allowed.");
        }
      }
    }

    // Reset some items
    this.CancelToken = axios.CancelToken.source();
    this.setState({FileCount: files.length, UploadProgressItems: []});

    let onCompletedFileHandler = async (file, reservation) => {
      reservation.ContentType = file.type;
      reservation.OriginalFilename = file.name;
      reservation.OriginalFilepath = file.path;
      reservation.OriginalFileSize = file.size;

      if (this.props.onComplete && !this.props.includeResponseInOnComplete) {
        this.props.onComplete(reservation, file);
      }

      if (this.props.skipFinalization) {
        return { resp: { data: reservation } };
      } else {
        return await API.put(reservationUri, [reservation],
          {
            params: {
              ...reservationParams,
              uniqueId: reservation.UniqueId,
            }
          })
          .then(resp => { 
            if (this.props.onComplete && this.props.includeResponseInOnComplete) {
              this.props.onComplete(reservation, file, resp);
            }
            return { resp };
          })
          .catch(err => { return { err }; });
      }
    }

    UploadFiles(files, reservationUri, reservationParams, this.CancelToken, 
      HandleUploadProgress(
        this.props,
        () => this.CancelToken,
        name => this.state[name],
        state => this.setState(state),
        this.handleAlert
      ),
      onCompletedFileHandler, this.handleAlert, this.handleApiError);
  }

  handleBeginFileUpload = () => {
    if (this.OpenFunc) {
      this.OpenFunc();
    }
  }

  getInnerContent = openFunc => {
    const {
      classes,
      theme,
      children,
      onGetChildren,
      forceHideInstructionContent,
      singleFile,
      noMargin,
      marginTop,
      singleItemName,
      additionalContent,
    } = this.props;

    if (!this.OpenFunc && openFunc) {
      this.OpenFunc = openFunc;
    }

    const itemsName = `${(singleItemName) ? singleItemName : "File"}${(!singleFile) ? "s" : ""}`;
    const dragAndDropMessageGridItem = (!IsMobile())
      ? (
        <Grid item xs={12} className={classes.gridItem}>
          <Typography variant="h5">
            {`Drag and Drop ${itemsName}`}
          </Typography>
          <Typography variant="body1" style={{marginTop:4}}>
          or
          </Typography>
        </Grid>
      ) : null;

    const additionalGridItem = (additionalContent) ? (
      <Grid item xs={12} className={classes.gridItem}>
        {additionalContent}
      </Grid>
    ) : null;

    return (children)
      ? children
      : (onGetChildren)
        ? onGetChildren(openFunc)
        : (!forceHideInstructionContent)
          ? (
            <Grid container spacing={2} className={classes.gridContainer}
              style={{marginTop:(noMargin) ? 0 : (marginTop) ? marginTop : theme.spacing(8), }}>
              {dragAndDropMessageGridItem}
              <Grid item xs={12} className={classes.gridItem}>
                <Button variant="contained"
                  color="primary"
                  style={{marginTop:-4}}
                  onClick={openFunc}>
                  {
                    (IsMobile())
                      ? `Capture ${itemsName}`
                      : `Select ${itemsName}`
                  }
                </Button>
              </Grid>
              {additionalGridItem}
            </Grid>
          ) : null;
  }

  handleApiError = err => {
    this.props.onApiError(err);
  }
  
  handleAlert = details => {
    this.props.onAlert(details);
  }

  componentDidMount() {
    if (this.props.onSetBeginFileUploadFunc) {
      this.props.onSetBeginFileUploadFunc(this.handleBeginFileUpload);
    }
  }

  componentDidUpdate() {
  }

  render() {
    const {
      classes,
      theme,
      children,
      fullWidth,
      noContent,
      acceptTypes,
    } = this.props;

    return (
      <div style={{
        height:"100%",
        display: (noContent) ? "none" : undefined
      }}>
        <div className={classes.dropZoneContainer}>
          <Dropzone 
            onDrop={files => this.handleFileDrop(files)}
            noClick
            noKeyboard
            accept={acceptTypes}
          >
            {({getRootProps, getInputProps, open: openFunc}) => (
                <div {...getRootProps()} className={classes.fileDropZone}
                  style={{
                    width: 
                      (children)
                        ? "100%"
                        : (fullWidth)
                          ? "100%"
                          // this calculation fixes an issue with the drop zone width being larger than 100% on smaller screens (mobile devices)
                          : `calc(100% - ${theme.spacing(2)}px)`,
                  }}>
                  <input {...getInputProps()} />
                  {this.getInnerContent(openFunc)}
                </div>
            )}
          </Dropzone>
        </div>
      </div>
    );
  }
}

CaptureCore.propTypes = {
  classes: PropTypes.object.isRequired,
  reservationUri: PropTypes.string,
  reservationParams: PropTypes.object,
  skipFinalization: PropTypes.bool,
  onComplete: PropTypes.func,
  includeResponseInOnComplete: PropTypes.bool,
  singleItemName: PropTypes.string,
  singleFile: PropTypes.bool,
  singleFileMaxSize: PropTypes.number,
  singleFileDisallowGIF: PropTypes.bool,
  acceptTypes: PropTypes.string,
  forceHideInstructionContent: PropTypes.bool,
  skipCompleteAlert: PropTypes.bool,
  noMargin: PropTypes.bool,
  marginTop: PropTypes.number,
  fullWidth: PropTypes.bool,
  onApiError: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onSetBeginFileUploadFunc: PropTypes.func,
  children: PropTypes.object,
  onGetChildren: PropTypes.func,
  additionalContent: PropTypes.object,
};

export default withStyles(styles, {withTheme: true})(CaptureCore);