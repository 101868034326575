import API, {
  GetQuickBooksAdminSignoutPath,
  GetPartnerSignoutPathForApi,
  GetEmployeeUserSignoutPathForApi,
} from '../Util/api';
import {
  GetSignInPath,
} from '../Util/PathHelper';

export const GetUserSignOutPromise = () => {
  return API.get(GetQuickBooksAdminSignoutPath());
}

export const GetPartnerSignOutPromise = () => {
  return API.get(GetPartnerSignoutPathForApi());
}

export const GetEmployeeUserSignOutPromise = () => {
  return API.get(GetEmployeeUserSignoutPathForApi());
}

export const SignOut = props => {
    GetUserSignOutPromise()
      .catch(err => {
        // Ignore
      })
      .finally(props.history.push(GetSignInPath()));
}