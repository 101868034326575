import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import FilterIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/Sort';
import RefreshIcon from '@material-ui/icons/Refresh';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
// import RemoveIcon from '@material-ui/icons/RemoveCircle';

import UserApiKeyActionType from '../Model/UserApiKeyActionType';
import UiCore from '../Components/UiCore';
import MultiUseDialog from '../Components/MultiUseDialog';
import ProgressIndicator from '../Components/ProgressIndicator';
import CollapsibleLeftPane from '../Components/CollapsibleLeftPane';
// import CaptureCore from '../Components/CaptureCore';
import ActionDrawer from '../Components/ActionDrawer';
import TitleComponent from '../Components/TitleComponent';

import { IsMobile } from '../Util/MobileDetector';

import red from '@material-ui/core/colors/red';

import API, {
  // GetUserApiKeysPathForApi,
} from '../Util/api';

import ItemListBase from '../Components/ItemListBase';
import {
  GetIdSubPrefix, 
  GetCurrentViewType,
  GetNextViewType,
  HandleCycleViewType,
  HandleSetViewTypeAnchorEl,
  GetViewTypeMenuForDesktopTasks,
} from '../Util/ViewType';
import { NumberWithSeparators } from '../Util/NumberFormatting';
import { 
  GetCurrentSort,
  SaveSort,
} from '../Util/Sort';

import { GlobalContext } from '../Context/Global.context';
import {
  GetSavedFilterState,
  GetFilterFieldsAsMetaFieldFilters,
  CompareCollectionField,
} from '../Util/Filters';

import debounce from 'es6-promise-debounce';

const styles = theme => ({
  content: {
    height: "100%",
    width: "100%",
    display:"flex",
  },
  contentRight: {
    flexGrow:1,
    overflowX: "auto",
    height:"100%",
    // padding:theme.spacing(4),
  },
  contentRightContent: {
    display:"flex",
    height:"100%",
    overflowY: "auto",
    overflowX:"hidden",
  },
  itemListContainer: {
    width:"100%",
    // overflow:"auto",
  },
  infoGrid: {
    marginRight:theme.spacing(1),
    "& div": {
      whiteSpace: "nowrap",
    },
  },
  toolHeader: {
    // borderBottom:"1px solid",
    // borderBottomColor:theme.palette.divider,
    // backgroundColor:theme.palette.background.toolBar,
    display:"flex",
    alignItems: "center",
    justifyContent:"flex-end",
    // paddingLeft:theme.spacing(3),
    paddingRight:theme.spacing(2),
    paddingLeft:2,
    transition:"padding 200ms",
    [theme.breakpoints.down('xs')]: {
      // paddingLeft:theme.spacing(2),
      paddingRight:theme.spacing(1),
    },
  },
  toolHeaderTitle: {
    paddingLeft: theme.spacing(3),
  },
  toolHeaderLeft: {
    flexGrow:1,
    whiteSpace:"nowrap",
    display:"flex",
    alignItems:"center",
    zIndex:3,
  },
  toolHeaderRight: {
    display:"flex",
    alignItems:"center",
  },
  breadcrumbNoSelect: {
    fontFamily: "inherit",
    fontSize: "inherit",
    margin: theme.spacing(1),
    lineHeight: 1.75,
    padding: "6px 8px",
    userSelect: "none",
    // textTransform: "uppercase",
  },
  breadcrumbButton: {
    fontFamily: "inherit",
    fontSize: "inherit",
    margin: theme.spacing(1),
    minWidth: "inherit",
    letterSpacing: "inherit",
    textTransform: "inherit",
  },
  breadcrumbArrowForward: {
    fill: "#888",
    fontSize:14,
  },
  cardGridItem: {
    width: "100%",
  },
  emptyContainer: {
    display:"flex",
    justifyContent: "center",
    marginTop:150,
  },
  tableCell_FirstCell: {
    paddingLeft:15,
    paddingRight:20,
    [theme.breakpoints.down('xs')]: {
      paddingLeft:7,
    },
  },
  fab: {
    position: "fixed",
    zIndex: 1,
    right: theme.spacing(3),
    bottom: theme.spacing(2),
  },
  filterButtonContainer: {
    position:"relative",
  },
  activeFilterIndicator: {
    backgroundColor:red[500],
    borderRadius:4,
    width:8,
    height:8,
    position:"absolute",
    top:12,
    right:12,
    zIndex:1,
  },
});

class ItemCollectionBase extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
  
    const {
      collectionName,
      onConnectRefreshItemsFunction,
      onConnectOnActionFunction,
      onCollectionNameSet,
      loadItemsImmediately,
      // isPublicApi,
      // initialViewTypeForPublicApi,
    } = this.props;

    this.state = {
      Items: null,
      ItemsCollectionName: null,
      SelectedItems: [],
      Cursor: "",
      ShowGetMoreButton: false,
      
      CollectionFields: [],
      SortType: null,
      SortDescending: false,
      SortMenuAnchor: null,
      ViewType: "",
      ShowAddItemDialog: false,
      MetaFieldFilters: [],
      FullTextFilter: "",
      UserFiltersAreActive: false,
      GetMoreButtonLeftMargin: null,
      
      LeftPaneIsVisible: false,
      ForceHideLeftPane: false,
      ForceShowLeftPane: false,
      PostActionData: {},
      ShowFilterAndSortDrawer: this.props.showFilterAndSortDrawerOnLoad || false,
      ForceResetFilterAndSortDrawer: false,
      ActionConfirmationDialogDetails: { Open: false },
      ShowProgressIndicatorImmediately: false,
      ShowDialogProgressIndicatorImmediately: false,
    }

    this.ChangeViewRef = React.createRef();

    // These are connected after initial document loading to prevent double loading in areas like power search instant results
    if (onConnectRefreshItemsFunction) {
      onConnectRefreshItemsFunction(this.handleRefresh);
    }
    if (onConnectOnActionFunction) {
      onConnectOnActionFunction(this.handleAction);
    }
    if (onCollectionNameSet && collectionName) {
      onCollectionNameSet(collectionName);
    }

    this.ReloadItemsCheckIntervalID = null;
    this.AutoRefreshIntervalID = null;
    this.AutoRefreshIntervalMS = 15000;
    this.LastQueryDate = null;

    this.LastQueryUri = "";
    this.LastQueryParams = {};

    this.WindowIsFocused = true;

    if (loadItemsImmediately) {
      this.handleLoadItems(true, true, null, null, props.initialFullTextFilter);
    }
  }

  handleSaveCurrentStateToHistory(sortType, sortDescending) {
    // If returnContentOnly requested, do NOT replace history
    if (this.props.returnContentOnly) {
      return;
    }
    // let stateToSave = {
    //   SortType: (sortType !== null) ? sortType : this.state.SortType,
    //   SortDescending: (sortDescending !== null) ? sortDescending : this.state.SortDescending,
    // };
    // this.props.history.replace(this.props.location.pathname, { ...this.props.location.state, ...stateToSave });
  }

  selectedItemsChanged = selectedItems => {
    if (this.props.onSelectedItemsChanged) {
      this.props.onSelectedItemsChanged(selectedItems);
    }
  }

  itemsChanged = items => {
    if (this.props.onItemsChanged
      && this.props.collectionName === this.state.ItemsCollectionName) {
      this.props.onItemsChanged(items);
    }
  }

  getCollectionFields = () => {
    return this.props.onGetCollectionFieldsPromise()
      .then(CollectionFields => {
        if (CollectionFields) {
          CollectionFields = CollectionFields.sort(CompareCollectionField);
          this.setState({CollectionFields});
          return CollectionFields;
        }
        return [];
      });
  }

  handleLoadItems = async (isCollectionChange, reset, sortType, sortDescending, userFullTextFilter, userMetaFieldFilters) => {
    // // Use this opportunity to check for new notifications
    // if (this.context
    //   && this.context.UserPreferences 
    //   && this.context.UserPreferences.ActiveOrganizationID) {
    //   this.context.GetUserHasUnreadNotification(this.context.UserPreferences.ActiveOrganizationID);
    // }

    // this.handleSaveCurrentStateToHistory(sortType, sortDescending);
    let {
      contentUri,
      contentUriParams,
      contentUriMetaFieldFilters,
      collectionName,
      itemsName,
      defaultViewType,
      includeItemIds,
      initialSortTypeForPublicApi,
    } = this.props;
    let {
      SortType,
      CollectionFields,
      SortDescending,
      FullTextFilter,
      MetaFieldFilters,
    } = this.state;

    if (!(contentUri && collectionName)) {
      return;
    }
    
    if (reset) {
      this.stopAutoRefresh();
      if (isCollectionChange) {
        CollectionFields = await this.getCollectionFields();
      }
      const filterState = (!this.props.skipFilterSavedState)
        ? GetSavedFilterState(this.props, CollectionFields)
        : {};
      FullTextFilter = (typeof userFullTextFilter === "string") ? userFullTextFilter : filterState.FullTextFilter;
      // Combine user-based and collection-based MetaFieldFilters.
      MetaFieldFilters = [];
      if (!Array.isArray(userMetaFieldFilters) && filterState.Fields) {
        userMetaFieldFilters = MetaFieldFilters.concat(GetFilterFieldsAsMetaFieldFilters(
          filterState.Fields, filterState.SecondaryFields));
      }
      MetaFieldFilters = MetaFieldFilters.concat(userMetaFieldFilters);
      if (Array.isArray(contentUriMetaFieldFilters)) {
        MetaFieldFilters = MetaFieldFilters.concat(contentUriMetaFieldFilters);
      }
      this.setState({
        FullTextFilter,
        MetaFieldFilters,
        UserFiltersAreActive: this.getUserFiltersAreActive(FullTextFilter, userMetaFieldFilters || []),
      });
    }

    contentUriParams = contentUriParams || {};

    if (reset) {
      if (isCollectionChange) {
        if (this.props.isPublicApi) {
          if (initialSortTypeForPublicApi) {
            SortType = this.getValidSortType(initialSortTypeForPublicApi, CollectionFields);
          }
        } else {
          GetCurrentViewType(collectionName, 
            GetIdSubPrefix(collectionName, IsMobile(), this.props.isWorkspace),
            ViewType => this.setState({ViewType}), defaultViewType, this.handleApiError)
            .then(viewType => {
              this.handleViewTypeChanged(viewType);
            });

          await GetCurrentSort(collectionName)
            .then(initialSort => {
              SortType = (initialSort) ? this.getValidSortType(initialSort.SortType, CollectionFields) : null;
              SortDescending = (initialSort) ? initialSort.SortDescending : false;
              this.setState({ SortType, SortDescending });
            })
            .catch(this.handleApiError);
        }
      }
    }

    let params = {
      ...contentUriParams,
      cursor: (!reset) ? this.state.Cursor : "",
      timezoneOffsetMinutes: (new Date()).getTimezoneOffset(),
    };

    // Ensure outgoing SortType is valid against CollectionFields
    SortType = this.getValidSortType(sortType || SortType, CollectionFields);
    params.sortType = SortType;
    params.sortDescending = (typeof sortDescending === "boolean") ? sortDescending : SortDescending;
    params.fullTextFilter = FullTextFilter;
    params.metaFieldFilters_json = JSON.stringify(MetaFieldFilters);

    if (includeItemIds) {
      params.includeItemIds_json = JSON.stringify(includeItemIds);
    }

    this.setState({ ShowProgressIndicatorImmediately: true });

    // Save contentUri and params for auto-refresh
    this.LastQueryUri = contentUri;
    this.LastQueryParams = params;
    
    this.LastQueryDate = new Date();
    API.get(contentUri, { params })
      .then(resp => {
        let contentList = resp.data;

        if (!contentList[itemsName]) {
          console.log("ItemsName is incorrect", contentList);
        }

        let Items = [];
        const RefreshID = new Date();
        if (reset) {
          Items = contentList[itemsName].map(newItem => {
            return { ...newItem, RefreshID, };
          });
        } else {
          Items = [...this.state.Items];
          contentList[itemsName].forEach(newItem => {
            const existingItemFinder = Items.filter(item => item.ID === newItem.ID);
            if (existingItemFinder.length) {
              Items.splice(Items.indexOf(existingItemFinder[0]), 1);
            }
            Items.push({...newItem, RefreshID});
          });
        }
        
        let stateToUpdate = {
          Items,
          ItemsCollectionName: collectionName,
          ShowGetMoreButton: contentList[itemsName].length >= contentList.PageSize,
          Cursor: contentList.Cursor,
          ShowProgressIndicatorImmediately: false,
        };
        if (reset) {
          stateToUpdate.SelectedItems = [];
          this.selectedItemsChanged([]);
        }
        this.setState(stateToUpdate);
        this.itemsChanged(Items);

        if (reset) {
          this.doAutoRefresh("handleLoadItems");
        }
      })
      .catch(this.handleApiError);
  }

  handleRefresh = () => {
    this.handleLoadItems(false, true);
    if (this.props.onRefresh) {
      this.props.onRefresh();
    }
    if (this.props.skipFilterSavedState) {
      this.setState({ForceResetFilterAndSortDrawer:true});
      setTimeout(() => this.setState({ForceResetFilterAndSortDrawer:false}), 1);
    }
  }

  doAutoRefresh = source => {
    // console.log("doAutoRefresh", source);

    // AutoRefresh only for listed cases below
    switch (this.props.collectionName) {
      default:
        return;
    }

    // const isItemDragging = () => this.state.Items && [...this.state.Items].filter(i => i.IsDragging).length > 0;
    // if (this.WindowIsFocused
    //   && !isItemDragging()
    //   && this.AutoRefreshIntervalID
    //   && this.LastQueryUri
    //   && this.LastQueryDate
    // ) {
    //   const createdOnOrModifiedOnNewerThan = new Date(this.LastQueryDate.getTime() - this.AutoRefreshIntervalMS);
    //   const params = {
    //     ...this.LastQueryParams,
    //     createdOnOrModifiedOnNewerThan,
    //   }
    //   switch (this.state.ItemsCollectionName) {
    //     default:
    //       break;
    //   }
    //   this.LastQueryDate = new Date();
    //   API.get(this.LastQueryUri, { params })
    //   .then(resp => {
    //     // Abort if an item started dragging while we were calling server
    //     if (isItemDragging()) {
    //       // console.log("aborting");
    //       return;
    //     }
    //     let contentList = resp.data;
    //     if (contentList) {
    //       const changedItems = contentList[this.props.itemsName];
    //       // console.log("doAutoRefresh results", changedItems);
    //       if (changedItems && changedItems.length) {

    //         let Items = [...this.state.Items];
    //         switch (this.state.ItemsCollectionName) {
    //           default:
    //             break;
    //         }
    //         this.setState({Items});
    //       }
    //     }
    //   })
    //   .catch(err => {
    //     // Do nothing - there will be another cycle
    //   });
    // }
    // this.AutoRefreshIntervalID = setTimeout(() => this.doAutoRefresh("self"), this.AutoRefreshIntervalMS);
  }

  stopAutoRefresh = () => {
    if (this.AutoRefreshIntervalID) {
      clearTimeout(this.AutoRefreshIntervalID);
      this.AutoRefreshIntervalID = null;
    }
  }

  handleResetAutoRefresh = () => {
    this.stopAutoRefresh();
    this.doAutoRefresh("handleResetAutoRefresh");
  }

  handleSortMenuClose() {
    this.setState({ SortMenuAnchor: null });
  };

  handleSortChange = (SortType, SortDescending) => {
    if (!SortType) {
      SortType = this.state.SortType;
    }
    if (typeof SortDescending !== "boolean") {
      SortDescending = this.state.SortDescending;
    }
    this.handleSortMenuClose();
    this.setState({SortType, SortDescending});
    this.handleLoadItems(false, true, SortType, SortDescending);
    if (!this.props.isPublicApi) {
      SaveSort(this.props.collectionName, null, SortType, SortDescending);
    }
  }

  handleFlipSortDirection = () => {
    this.handleSortMenuClose();
    let sortDescending = !this.state.SortDescending;
    this.handleSortChange(this.state.SortType, sortDescending);
  }

  handleSortChangeFromTableHeader = sortType => {
    // Flip sort direction if same sortType selected
    if (this.state.SortType && this.state.SortType.indexOf(sortType) > -1) {
      this.handleFlipSortDirection();
    } else {
      this.handleSortChange(this.getValidSortType(sortType), false);
    }
  }

  getValidSortType = (sortType, collectionFields) => {
    if (!collectionFields || !collectionFields.length) {
      collectionFields = (this.state && this.state.CollectionFields)
        ? [...this.state.CollectionFields]
        : [];
    }
    let sortTypeFinder = collectionFields.filter(st => st.ID.indexOf(sortType) > -1);    
    if (sortTypeFinder.length) {
      return sortTypeFinder[0].ID;
    }
    return null;
  }

  getUserFiltersAreActive = (fullTextFilter, metaFieldFilters) => {
    return fullTextFilter
      || metaFieldFilters.filter(f => f.FieldValue).length
      || metaFieldFilters.filter(f => f.FieldValues && f.FieldValues.length).length;
  }

  handleFiltersChanged = debounce((FullTextFilter, MetaFieldFilters) => {
    this.setState({
      FullTextFilter,
      MetaFieldFilters,
      UserFiltersAreActive: this.getUserFiltersAreActive(FullTextFilter, MetaFieldFilters),
    });
    this.handleLoadItems(false, true, null, null, FullTextFilter, MetaFieldFilters);
  }, 250);

  handleSetAddItemDialogVisibility(show) {
    this.setState({ 
      ShowAddItemDialog: show,
      ShowDialogProgressIndicatorImmediately: false,
    });
  }

  handleAddItem = name => {
    if (!name && !this.props.addItemDialogIsConfirmation) {
      // Closes the dialog
      this.handleSetAddItemDialogVisibility(false);
      return;
    }
    if (!this.props.onAddItemPromise) {
      return;
    }
    this.setState({ShowDialogProgressIndicatorImmediately:true});
    this.props.onAddItemPromise(name)
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowDialogProgressIndicatorImmediately:false});
        this.handleSetAddItemDialogVisibility(false);
      });
  }

  handleForcePrependItems = items => {
    let newItems = [];
    items.forEach(i => {
      const existingItemFinder = this.state.Items.filter(ei => ei.ID === i.ID);
      if (!existingItemFinder.length) {
        newItems.push(i);
      }
    });
    if (newItems.length) {
      const Items = [...newItems, ...this.state.Items];
      this.setState({ Items });
      this.itemsChanged(Items)
    }
  }

  handleSelect = id => {
    let SelectedItems = [...this.state.SelectedItems];
    const selectedIndex = SelectedItems.map(i => i.ID).indexOf(id);
    if (selectedIndex === -1) {
      const itemFinder = [...this.state.Items].filter(i => i.ID === id);
      if (itemFinder.length) {
        SelectedItems.push(itemFinder[0]);
      }
    } else if (selectedIndex > -1) {
      SelectedItems.splice(selectedIndex, 1);
    }
    this.setState({ SelectedItems });
    this.selectedItemsChanged(SelectedItems);
  }

  handleSelectAll = e => {
    let SelectedItems = [...this.state.SelectedItems];
    let items = [...this.state.Items];
    if (!e.target.checked) {
      SelectedItems = [];
    } else {
      if (this.props.canSelectItem) {
        items = items.filter(i => this.props.canSelectItem(i));
      }
      SelectedItems = items;
    }
    this.setState({ SelectedItems });
    this.selectedItemsChanged(SelectedItems);
  }

  handleAction = (actionType, id_optional, silentProgress, params, selectedItems) => {
    if (id_optional) {
      selectedItems = [...this.state.Items].filter(i => i.ID === id_optional)
    }
    if (!selectedItems || !selectedItems.length) {
      selectedItems = [...this.state.SelectedItems];
    }
    if (selectedItems.length === 0) {
      return Promise.resolve();
    }
    if (!silentProgress) {
      this.setState({ShowProgressIndicatorImmediately: true});
    }
    
    let actionFinalizer = (PostActionData, refreshItems) => {
      let stateToUpdate = { PostActionData };
      if (!silentProgress && !refreshItems) {
        stateToUpdate.ShowProgressIndicatorImmediately = false;
      }
      this.setState(stateToUpdate);
      if (refreshItems) {
        this.handleRefresh();
      }
      if (this.props.onActionCompleted) {
        this.props.onActionCompleted(actionType);
      }
    }
    switch (actionType) {
    // case ActionType.Download:
    //     let uris = [];
    //     GetExecuteMultipleDownloadsPromise(uris)
    //       .then(() => {
    //         actionFinalizer();
    //       })
    //       .catch(this.handleApiError);
    //   break;
    // case UserApiKeyActionType.UserApiKey_Delete:
    //     this.showActionConfirmation(
    //       `Delete key${(selectedItems.length > 1) ? "s" : ""}?`,
    //       "This action cannot be undone. API calls referencing deleted keys will stop working.",
    //       {BodyClassName:"warning"},
    //       () => {
    //         API.delete(GetUserApiKeysPathForApi(),
    //           { data: { IDs: selectedItems.map(pm => pm.ID) } })
    //           .then(() => {
    //             actionFinalizer(undefined, true);
    //           })
    //           .catch(this.handleApiError);
    //       },
    //       () => actionFinalizer(undefined, false),
    //     );
    //   break;
    default:
        actionFinalizer(undefined, false);
      break;
    }

    return Promise.resolve();
  }

  showActionConfirmation = (Title, BodyText, extraProperties, confirmAction, cancelAction) => {
    this.setState({
      ActionConfirmationDialogDetails: {
        Open:true,
        Title,
        IsConfirmation:true,
        BodyText,
        CancelCallback:() => this.hideActionConfirmation(cancelAction),
        CloseCallback:() => this.hideActionConfirmation(cancelAction),
        // ConfirmLabel,
        ConfirmCallback:() => this.hideActionConfirmation(confirmAction),
        ...extraProperties,
      },
    });
  }

  hideActionConfirmation = action => {
    this.setState({
      ActionConfirmationDialogDetails: { ...this.state.ActionConfirmationDialogDetails, Open: false, },
    });
    if (action) {
      action();
    }
  }

  handleUpdateRevisedItem = debounce(revisedItem => {
    let Items = [...this.state.Items];
    if (Items && Items.length && revisedItem.ID) {
      let itemFinder = Items.filter(i => i.ID === revisedItem.ID);
      if (itemFinder.length) {
        Items.splice(Items.indexOf(itemFinder[0]), 1, revisedItem);
        this.setState({Items});
        this.itemsChanged(Items);
      }
    }
  }, 250);

  handleFabClick = e => {
    if (this.props.onFabClick) {
      this.props.onFabClick(e);
    } else if (this.props.onAddItemPromise) {
      this.handleSetAddItemDialogVisibility(true);  
    }
  }

  checkReloadItemsFlag = () => {
    if (this.context && this.context.GetReloadItemsFlag()) {
      this.context.ClearReloadItemsFlag();
      this.handleRefresh();
    }
    this.ReloadItemsCheckIntervalID = setTimeout(this.checkReloadItemsFlag, 1000);
  }

  handleSetShowFilterAndSortDrawerVisibility = visible => {
    this.setState({
      ShowFilterAndSortDrawer: visible,
    });
  }

  handleLeftPaneVisibilityChange = LeftPaneIsVisible => {
    this.setState({LeftPaneIsVisible});
  }

  handleItemListContainerScroll = e => {
    this.setState({
      GetMoreButtonLeftMargin: e.target.scrollLeft,
    });
  }

  handleApiError = err => {
    if (this.props.onApiError) {
      this.props.onApiError(err);
    }
    this.setState({
      ApiError: err, 
      ShowProgressIndicatorImmediately: false,
      ShowDialogProgressIndicatorImmediately: false,
    });
  }

  handleAlert = Alert => {
    this.setState({Alert});
    if (this.props.onAlert) {
      this.props.onAlert(Alert);
    }
  }

  handleGetAllItems = () => {
    return [...this.state.Items];
  }

  handleSetAllItems = Items => {
    this.setState({Items});
  }

  handleProjectsNavClicked = () => {
    if (IsMobile()) {
      return;
    }
    this.setState({ForceShowLeftPane:true});
    setTimeout(() => {
      this.setState({ForceShowLeftPane:false});
    }, 1);
  }

  handleViewTypeChanged = viewType => {
    if (this.props.onViewTypeChanged) {
      this.props.onViewTypeChanged(viewType);
    }
    // This has been disabled for now as it appears to be a bad user experience
    // if (viewType && viewType.startsWith("Kanban")) {
    //   this.setState({ForceHideLeftPane:true});
    //   setTimeout(() => {
    //     this.setState({ForceHideLeftPane:false});
    //   }, 1);
    // }
  }

  handleSetKanbanColumnRefreshFunc = f => {
    this.KanbanColumnRefreshFunc = f;
  }

  handleWindowFocused = focused => {
    this.WindowIsFocused = true;
  }

  handleWindowBlured = () => {
    this.WindowIsFocused = false;
  }

  componentDidMount() {
    if (!this.props.returnContentOnly) {
      // To ensure a document will always be opened in a new tab, we must keep this component's window name unique
      window.name = "apc_itemCollection";
    }

    window.addEventListener('blur', this.handleWindowBlured);
    window.addEventListener('focus', this.handleWindowFocused);

    // if (this.props.collectionName === "FolderSearchResults") {
    //   let node = GetNodeFromPath(this.props);
    //   if (node) {
    //     this.handleNodeSelect(node);
    //   }
    // }

    if (!this.props.loadItemsImmediately && this.props.onGetCollectionFieldsPromise) {
      this.getCollectionFields();
    }

    if (this.props.onSetUpdateRevisedItemFunction) {
      this.props.onSetUpdateRevisedItemFunction(this.handleUpdateRevisedItem);
    }
    if (this.props.onSetGetAllItemsFunction) {
      this.props.onSetGetAllItemsFunction(this.handleGetAllItems);
    }
    if (this.props.onSetSetAllItemsFunction) {
      this.props.onSetSetAllItemsFunction(this.handleSetAllItems);
    }
    if (this.props.onSetResetAutoRefreshFunc) {
      this.props.onSetResetAutoRefreshFunc(this.handleResetAutoRefresh);
    }
    
    this.checkReloadItemsFlag();
  }

  componentWillUnmount() {
    clearTimeout(this.ReloadItemsCheckIntervalID);
    clearTimeout(this.AutoRefreshIntervalID);
    window.removeEventListener('blur', this.handleWindowBlured);
    window.removeEventListener('focus', this.handleWindowFocused);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.contentUri !== prevProps.contentUri && !this.props.skipResetOnContentUriChange)
      || this.props.collectionName !== prevProps.collectionName
      || JSON.stringify(this.props.contentUriParams) !== JSON.stringify(prevProps.contentUriParams)
      || JSON.stringify(this.props.contentUriMetaFieldFilters) !== JSON.stringify(prevProps.contentUriMetaFieldFilters)
      || this.props.passThroughComponent !== prevProps.passThroughComponent
      || this.props.initialFullTextFilter !== prevProps.initialFullTextFilter
    ) {
      this.setState({
        CollectionFields: [],
        Items: null,
        SelectedItems: [],
      });
      this.itemsChanged(null);
      this.selectedItemsChanged([]);
      this.handleLoadItems(true, true, null, null, this.props.initialFullTextFilter);
    }

    if (this.props.addItemDialogConfirm === true && prevProps.addItemDialogConfirm === false) {
      this.handleAddItem();
    }

    if (this.state.Items && this.props.forcePrependItems && this.props.forcePrependItems !== prevProps.forcePrependItems) {
      this.handleForcePrependItems(this.props.forcePrependItems);
    }

    if (prevProps.forceRefresh !== this.props.forceRefresh && this.props.forceRefresh === true) {
      this.handleRefresh();
    }
    if (prevProps.forceRefreshKanbanColumns !== this.props.forceRefreshKanbanColumns
      && this.props.forceRefreshKanbanColumns) {
      this.handleRefreshKanbanColumns();
    }

    if (this.props.onSetUpdateRevisedItemFunction
      && prevProps.onSetUpdateRevisedItemFunction !== this.props.onSetUpdateRevisedItemFunction) {
      this.props.onSetUpdateRevisedItemFunction(this.handleUpdateRevisedItem);
    }
    if (this.props.onSetGetAllItemsFunction
      && prevProps.onSetGetAllItemsFunction !== this.props.onSetGetAllItemsFunction) {
      this.props.onSetGetAllItemsFunction(this.handleGetAllItems);
    }
    if (this.props.onSetSetAllItemsFunction
      && prevProps.onSetSetAllItemsFunction !== this.props.onSetSetAllItemsFunction) {
      this.props.onSetSetAllItemsFunction(this.handleSetAllItems);
    }

    if (prevProps.alert !== this.props.alert) {
      this.setState({Alert: this.props.alert});
    }
    if (prevProps.apiError !== this.props.apiError) {
      this.handleApiError(this.props.apiError);
    }
  }

  render() {
    const {
      Alert,
      ApiError,
      Items,
      ItemsCollectionName,
      SelectedItems,
      ViewType,
      // FullTextFilter,
      // MetaFieldFilters,
      ShowGetMoreButton,
      GetMoreButtonLeftMargin,
      CollectionFields,
      SortType,
      SortDescending,
      PostActionData,
      SensitiveFields,
      UserFiltersAreActive,
      LeftPaneIsVisible,
      ForceHideLeftPane,
      ForceShowLeftPane,
      // SortMenuAnchor,
      ShowAddItemDialog,
      ActionConfirmationDialogDetails,
      // ShowFilterAndSortDrawer,
      // ForceResetFilterAndSortDrawer,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
      // ShowDialogProgressIndicator,
      ShowDialogProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      theme,
      isWorkspace,
      itemName,
      // itemsName,
      passThroughComponent,
      collectionName,
      additionalCardStyle,
      disableCardActions,
      hideSubscriptionExpirationAlert,
      pageTitle,
      pageTitleFunc,
      titleComponentTitle,
      onGetHeadCells,
      onGetCardGridItems,
      onGetCardGridItemsForKanban,
      onGetTableRows,
      addItemDialogTitle,
      addItemDialogNamePlaceholder,
      addItemDialogBodyContent,
      addItemDialogIsConfirmation,
      addItemDialogConfirmLabel,
      onAddItemPromise,
      onFabClick,
      allowFabOnDesktop,
      fabTop,
      fabLeft,
      fabSize,
      fabPosition,
      onGetFabMenu,
      fabMenuCoords,
      forceMiniFab,
      // captureReservationParams,
      // onSetBeginFileUploadFunc,
      returnContentOnly,
      toolHeaderTitle,
      customNoResultsMessage,
      allowSelect,
      dialogContent,
      miscContent,
      totalItems,
      selectedNode,
      leftPaneContent,
      leftPaneStyle,
      leftPaneInnerStyle,
      toolHeaderStyle,
      toolHeaderControlSize,
      hideToolHeader,
      toolHeaderLeftContent,
      additionalToolHeaderRightContent,
      secondaryNavTabs,
      hideFilters,
      hideFilterSortDrawer,
      // skipFilterSavedState,
      // hideSearchAllFilter,
      // initialFullTextFilter,
      disableActionDrawer,
      itemListContainerStyle,
      itemListStyle,
      // history,
      // location,
      // organizationId,
      // projectId,
      isPublicApi,
      onItemClick,
      allowKanban,
      singleLineTableCells,
      showOrgAsTitleOnDesktop,
      showProgressIndicator,
      showProgressIndicatorImmediately,
    } = this.props;

    let headCells, tableRows, cardGridItems;

    if (collectionName === ItemsCollectionName) {
      headCells = onGetHeadCells(Items, SensitiveFields);
      tableRows = onGetTableRows(headCells, Items, SensitiveFields, classes, theme,
        this.handleSelect, SelectedItems.map(i => i.ID), this.handleAction,
        PostActionData, SortType, SortDescending, onItemClick);
      if (ViewType && ViewType.startsWith("Kanban") && onGetCardGridItemsForKanban) {
        cardGridItems = onGetCardGridItemsForKanban(Items, SensitiveFields, classes, theme,
          this.handleSelect, SelectedItems.map(i => i.ID), this.handleAction,
          PostActionData, SortType, SortDescending, false, additionalCardStyle || {},
          ViewType, onItemClick, disableCardActions);
      } else {
        cardGridItems = onGetCardGridItems(Items, SensitiveFields, classes, theme,
          this.handleSelect, SelectedItems.map(i => i.ID), this.handleAction,
          PostActionData, SortType, SortDescending, false, additionalCardStyle || {},
          ViewType, onItemClick, disableCardActions);
      }
    }
    
    const numSelected = SelectedItems.length;
    const indeterminate = numSelected > 0 && Items && numSelected < Items.length;
    const allSelected = numSelected > 0 && Items && numSelected === Items.length;

    let itemContent;
    let itemDisplay;
    if (cardGridItems && cardGridItems.length) {
      itemDisplay = (
        <ItemListBase
          cardGridItems={cardGridItems}
          headCells={headCells}
          tableBodyContent={tableRows}
          showGetMoreButton={ShowGetMoreButton}
          onGetMoreItems={this.handleLoadItems}
          getMoreButtonLeftMargin={GetMoreButtonLeftMargin}
          sortType={SortType}
          singleLineTableCells={singleLineTableCells}
          collectionFields={CollectionFields}
          sortDescending={SortDescending}
          onSortChangeFromTableHeader={this.handleSortChangeFromTableHeader}
          viewType={ViewType}
          indeterminate={indeterminate}
          allSelected={allSelected}
          onSelectAll={(allowSelect) ? this.handleSelectAll : undefined}
          leftPaneIsVisible={LeftPaneIsVisible}
          style={itemListStyle}
        />
      );
      let noResultsMessage;
      if (!(ViewType && ViewType.startsWith("Kanban")) && Items !== null && (!cardGridItems || !cardGridItems.length)) {
        noResultsMessage = (
          <div className={classes.emptyContainer}>{customNoResultsMessage || "No results"}</div>
        );
      }
      itemContent = (
        <React.Fragment>
          {itemDisplay}
          {noResultsMessage}
          {passThroughComponent}
        </React.Fragment>
      );
    } else {
      itemContent = (
        <React.Fragment>
          {passThroughComponent}
        </React.Fragment>
      );
    }

    let infoGridItems = [];
    if (totalItems && !IsMobile() && !UserFiltersAreActive) {
      infoGridItems.push(
        <Grid item key="grid_totalItems">
          <Typography>{NumberWithSeparators(totalItems)} total</Typography>
        </Grid>
      );
    }
    if (SelectedItems.length > 0) {
      infoGridItems.push(
        <Grid item key="grid_selectedCount">
          <Typography>{NumberWithSeparators(SelectedItems.length)} selected</Typography>
        </Grid>
      );
    }
    const infoGrid = (allowSelect && infoGridItems.length)
      ? (
        <Grid container spacing={2} wrap="nowrap" className={classes.infoGrid}>
          {infoGridItems}
        </Grid>
      )
      : null;

    const selectAllComponent = (
      allowSelect
      && (ViewType === "Card" || ViewType.startsWith("Kanban"))
      && Items
      && Items.length
    )
      ? (
        <Tooltip title="Select">
          <Checkbox
            size={toolHeaderControlSize || undefined}
            color="secondary"
            indeterminate={indeterminate}
            checked={indeterminate || allSelected}
            onChange={this.handleSelectAll}
          />
        </Tooltip>
      ) : null;

    let switchViewComponent;
    // For mobile and everything on desktop except where Kanban is allowed,
    // show a simple switcher between Card and List
    if (IsMobile() || !allowKanban) {
      let nextViewTitle = "";
      let nextViewIcon = null;
      switch (GetNextViewType(ViewType)) {
        case "Card":
          nextViewTitle = "Card view";
          nextViewIcon = <ViewModuleIcon />;
          break;
        case "List":
        default:
          nextViewTitle = "List view";
          nextViewIcon = <ViewListIcon />;
          break;
      }
      switchViewComponent = (
        <div>
          <Tooltip title={nextViewTitle}>
            <IconButton
              onClick={() => HandleCycleViewType(state => this.setState(state),
                this.handleApiError, collectionName, GetIdSubPrefix(collectionName, IsMobile(), isWorkspace),
                ViewType, isPublicApi,
              )}
              color="inherit"
            >
              {nextViewIcon}
            </IconButton>
          </Tooltip>
        </div>
      );
    }
    // For desktop where Kanban is allowed, show menu switcher for Card, List, Kanban
    if (!IsMobile() && allowKanban) {
      switchViewComponent = (
        <div ref={instance => this.ChangeViewRef = instance}>
          <Tooltip title="Change view">
            <IconButton
              size={(toolHeaderControlSize || undefined)}
              color="inherit"
              onClick={() => HandleSetViewTypeAnchorEl(state => this.setState(state), this.ChangeViewRef)}
            >
              <ViewListIcon />
            </IconButton>
          </Tooltip>
          {GetViewTypeMenuForDesktopTasks(
            id => this.state[id], 
            state => this.setState(state), 
            this.handleApiError,
            this.handleViewTypeChanged,
          )}
        </div>
      );
    }

    const toolHeaderTitleContent = (toolHeaderTitle)
      ? (
        <Typography className={classes.toolHeaderTitle}>
          {toolHeaderTitle}
        </Typography>
      )
      : null;

    const activeFilterIndicator = (UserFiltersAreActive)
      ? (
        <div 
          className={classes.activeFilterIndicator}
          style={{
            top:(IsMobile() && toolHeaderControlSize === "small") ? 4 : 12,
            right:(IsMobile() && toolHeaderControlSize === "small") ? 4 : 12,
          }}
        />
      ) : (<div />);
    const filterSortIcon = (hideFilters)
      ? (<SortIcon />)
      : (<FilterIcon />);
    const filterButtonComponent = (!hideFilterSortDrawer)
      ? (
        <div className={classes.filterButtonContainer}>
          {activeFilterIndicator}
          <Tooltip title={(hideFilters) ? "Sort" : "Filter & Sort"}>
            <IconButton
              size={(toolHeaderControlSize || undefined)}
              color="inherit"
              onClick={() => this.handleSetShowFilterAndSortDrawerVisibility(true)}
            >
              {filterSortIcon}
            </IconButton>
          </Tooltip>
        </div>
      ) : null;

    const refreshComponent = (Items)
      ? (
        <Tooltip title="Refresh">
          <IconButton
            size={(toolHeaderControlSize || undefined)}
            color="inherit"
            onClick={this.handleRefresh}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      ) : null;

    const toolHeaderRightComponents = (!passThroughComponent)
      ? (
        <div style={{
          display:"flex",
          alignItems:"center",
          marginLeft:theme.spacing(2),
        }}>
          {additionalToolHeaderRightContent}
          {infoGrid}
          {selectAllComponent}
          {filterButtonComponent}
          {switchViewComponent}
          {refreshComponent}
        </div>
      ) : null;

    const addFabButton = (
        (!SelectedItems || !SelectedItems.length)
        && (onAddItemPromise || onFabClick) 
        && (IsMobile() || allowFabOnDesktop)
      )
      ? (
        <Fab 
          color="secondary"
          aria-label="Add" 
          className={classes.fab}
          size={(fabSize) ? fabSize : undefined}
          style={{
            top:fabTop,
            left:fabLeft,
            position:fabPosition,
          }}
          onClick={this.handleFabClick}
        >
          <AddIcon />
        </Fab>
      )
      : null;

    const miniFabButton = (
        (selectedNode || forceMiniFab)
        && (onAddItemPromise || onFabClick) 
        && !IsMobile()
      )
      ? (
        <Tooltip title={`Add${(itemName) ? " " + itemName.toLowerCase() : ""}`}>
          <IconButton color="inherit" aria-label="Add"
            style={{marginLeft:theme.spacing(1)}}
            onClick={this.handleFabClick}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      )
      : null;

    let breadcrumbNodeComponents = [];
    if (selectedNode) {
      let node = selectedNode;
      const getClickFunction = selectedNode => {
        return (this.props.onBreadcrumbSelect)
          ? () => this.props.onBreadcrumbSelect(selectedNode)
          : () => {};
      };
      while (node) {
        if (breadcrumbNodeComponents.length > 0) {
          breadcrumbNodeComponents.splice(0, 0,
            <ArrowForwardIcon
              key={`arrow_${node.UniqueId}`}
              className={classes.breadcrumbArrowForward} />
          );  
        }
        if (node.IsRoot || node.NoSelect) {
          breadcrumbNodeComponents.splice(0, 0,
            <Typography variant="body1"
              key={node.UniqueId}
              className={classes.breadcrumbNoSelect}
              // style={{textTransform:(node.ItemIndex === 0) ? "inherit" : null}}
            >
              {node.Name}
            </Typography>
          );
        } else {
          breadcrumbNodeComponents.splice(0, 0,
            <Button
              key={node.UniqueId}
              className={classes.breadcrumbButton}
              onClick={getClickFunction(node)}
            >
              <span style={{color:node.HexColor}}>
                {node.Name}
              </span>
            </Button>
          );
        }
        node = node.ParentNode;
      }
    }
    const toolHeaderHeight = 48;
    const toolHeader = (!hideToolHeader && cardGridItems) ? (
      <div className={classes.toolHeader} 
        style={{
          height:toolHeaderHeight,
          // paddingLeft:(!LeftPaneIsVisible) ? 10 : undefined,
          ...toolHeaderStyle,
        }}
      >
        <div className={classes.toolHeaderLeft}>
          {toolHeaderLeftContent}
          {toolHeaderTitleContent}
          {breadcrumbNodeComponents}
          {miniFabButton}
        </div>
        <div className={classes.toolHeaderRight}>
          {toolHeaderRightComponents}
        </div>
      </div>
    ) : null;

    const addItemDialogDetails = (onAddItemPromise)
      ? {
        Open:ShowAddItemDialog,
        ShowProgressIndicatorImmediately:ShowDialogProgressIndicatorImmediately,
        Title:addItemDialogTitle,
        IsConfirmation:addItemDialogIsConfirmation,
        RequireTextInput1:(!addItemDialogIsConfirmation),
        TextInput1Label:(!addItemDialogIsConfirmation) ? "Name" : undefined,
        TextInput1PlaceHolder:(!addItemDialogIsConfirmation) ? addItemDialogNamePlaceholder : undefined,
        BodyContent:(addItemDialogBodyContent) ? addItemDialogBodyContent : undefined,
        CancelCallback:() => this.handleSetAddItemDialogVisibility(false),
        CloseCallback:() => this.handleSetAddItemDialogVisibility(false),
        ConfirmLabel:addItemDialogConfirmLabel || "ADD",
        ConfirmCallback:this.handleAddItem,
      }
      : {
        Open: false,
      };

    let collapsibleLeftPane;
    if (leftPaneContent) {
      collapsibleLeftPane = (
        <CollapsibleLeftPane 
          paneInnerStyle={{
            textAlign: "center",
            padding:0,
            ...leftPaneInnerStyle,
          }}
          paneStyle={leftPaneStyle}
          onVisibilityChange={this.handleLeftPaneVisibilityChange}
          forceHide={ForceHideLeftPane}
          forceShow={ForceShowLeftPane}
        >
          {leftPaneContent}
        </CollapsibleLeftPane>
      );
    }

    const internalProgressIndicator = (returnContentOnly
      && (ShowProgressIndicator || showProgressIndicator 
        || ShowProgressIndicatorImmediately || showProgressIndicatorImmediately))
      ? (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately || showProgressIndicatorImmediately} />
      ) : null;

    // const userPreferences = this.context && this.context.UserPreferences;
    const onAction = (...props) => this.handleAction(props[0], props[1] || null, props[2] || false);

    const allowActionDrawer = SelectedItems.length > 0;
    let actionListItems = [];

    switch (collectionName) {
      case "UserApiKeys":
        actionListItems.push(
          <ListItem key="action_userApiKey_delete" button tabIndex={(!allowActionDrawer) ? -1 : undefined} onClick={() => onAction(UserApiKeyActionType.UserApiKey_Delete)}>
            <ListItemIcon>
              {UserApiKeyActionType.UserApiKey_Delete.Icon}
            </ListItemIcon>
            <ListItemText primary={UserApiKeyActionType.UserApiKey_Delete.Label} />
          </ListItem>
        );
        break;
      default:
        break;
    }

    const actionDrawer = (!disableActionDrawer)
      ? (
        <ActionDrawer
          actionListItems={actionListItems}
          allowDrawer={allowActionDrawer}
        />
      ) : null;

    const titleComponent = (titleComponentTitle)
      ? (
        <TitleComponent title={titleComponentTitle} />
      ) : null;

    const content = (
      <div className={classes.content}>
        <MultiUseDialog Details={ActionConfirmationDialogDetails} />
        <MultiUseDialog Details={addItemDialogDetails} />
        {dialogContent}
        {miscContent}
        {titleComponent}
        {addFabButton}
        {(onGetFabMenu) ? onGetFabMenu(fabMenuCoords) : null}
        {/*{collapsibleLeftPane}*/}
        <div className={classes.contentRight}>
          {internalProgressIndicator}
          {toolHeader}
          <div className={classes.contentRightContent}
            style={{
              height:(!hideToolHeader) ? `calc(100% - ${toolHeaderHeight}px)` : undefined,
            }}
          >
            <div className={classes.itemListContainer}
              style={itemListContainerStyle}
              onScroll={this.handleItemListContainerScroll}
            >
              {/*(collectionName === "FolderSearchResults"
                || collectionName === "Documents"
                || collectionName === "TaskDocuments"
                || collectionName === "AssetItemDocuments")
                ? (
                  <CaptureCore
                    organizationId={organizationId}
                    projectId={projectId}
                    history={history}
                    location={location}
                    node={selectedNode}
                    forceHideInstructionContent
                    reservationParams={captureReservationParams}
                    onSetBeginFileUploadFunc={onSetBeginFileUploadFunc}
                    onClose={() => this.handleRefresh()}
                    onApiError={this.handleApiError}
                    onAlert={this.handleAlert}>
                    {itemContent}
                  </CaptureCore>
                )
                : */itemContent
              }
            </div>
            {actionDrawer}
          </div>
        </div>
      </div>
    );

    if (returnContentOnly) {
      return content;
    }

    return (
      <UiCore title={(pageTitleFunc) ? pageTitleFunc(Items) : pageTitle}
        alert={Alert}
        apiError={ApiError}
        hideSubscriptionExpirationAlert={hideSubscriptionExpirationAlert}
        showProgressIndicator={ShowProgressIndicator || showProgressIndicator}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately || showProgressIndicatorImmediately}
        // constrainedProgressIndicator
        content={content}
        navPane={collapsibleLeftPane}
        secondaryNavTabs={secondaryNavTabs}
        showOrgAsTitleOnDesktop={showOrgAsTitleOnDesktop}
        onProjectsNavClicked={this.handleProjectsNavClicked}
      />
    );
  }
}

ItemCollectionBase.propTypes = {
  pageTitle: PropTypes.string,
  pageTitleFunc: PropTypes.func,
  titleComponentTitle: PropTypes.string,

  isWorkspace: PropTypes.bool,
  passThroughComponent: PropTypes.object,

  contentUri: PropTypes.string.isRequired,
  contentUriParams: PropTypes.object,
  contentUriMetaFieldFilters: PropTypes.array,
  metaFieldFilters: PropTypes.array,
  collectionName: PropTypes.string.isRequired,
  itemsName: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  defaultViewType: PropTypes.string.isRequired,

  onGetCollectionFieldsPromise: PropTypes.func.isRequired,
  onGetHeadCells: PropTypes.func.isRequired,
  onGetCardGridItems: PropTypes.func.isRequired,
  onGetCardGridItemsForKanban: PropTypes.func,
  onGetTableRows: PropTypes.func.isRequired,
  singleLineTableCells: PropTypes.bool,

  onItemsChanged: PropTypes.func,
  onRefresh: PropTypes.func,
  onConnectRefreshItemsFunction: PropTypes.func,
  onConnectOnActionFunction: PropTypes.func,
  onSelectedItemsChanged: PropTypes.func,
  onCollectionNameSet: PropTypes.func,

  selectedNode: PropTypes.object,  
  onBreadcrumbSelect: PropTypes.func,
  totalItems: PropTypes.number,
  allowSelect: PropTypes.bool,
  canSelectItem: PropTypes.func,
  disableActionDrawer: PropTypes.bool,
  addItemDialogTitle: PropTypes.string,
  addItemDialogNamePlaceholder: PropTypes.string,
  addItemDialogBodyContent: PropTypes.object,
  addItemDialogIsConfirmation: PropTypes.bool,
  addItemDialogConfirm: PropTypes.bool,
  addItemDialogConfirmLabel: PropTypes.string,
  onAddItemPromise: PropTypes.func,
  onFabClick: PropTypes.func,
  allowFabOnDesktop: PropTypes.bool,
  fabTop: PropTypes.number,
  fabLeft: PropTypes.number,
  fabSize: PropTypes.string,
  fabPosition: PropTypes.string,
  onGetFabMenu: PropTypes.func,
  fabMenuCoords: PropTypes.object,
  forceMiniFab: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  forceRefreshKanbanColumns: PropTypes.bool,
  onSetResetAutoRefreshFunc: PropTypes.func,
  additionalCardStyle: PropTypes.object,
  leftPaneContent: PropTypes.object,
  leftPaneStyle: PropTypes.object,
  leftPaneInnerStyle: PropTypes.object,
  toolHeaderStyle: PropTypes.object,
  toolHeaderControlSize: PropTypes.string,
  secondaryNavTabs: PropTypes.object,
  toolHeaderLeftContent: PropTypes.object,
  additionalToolHeaderRightContent: PropTypes.object,
  hideSensitiveFields: PropTypes.bool,
  hideFilters: PropTypes.bool,
  hideFilterSortDrawer: PropTypes.bool,
  hideSearchAllFilter: PropTypes.bool,
  hideToolHeader: PropTypes.bool,
  showFilterAndSortDrawerOnLoad: PropTypes.bool,
  skipFilterSavedState: PropTypes.bool,
  initialFullTextFilter: PropTypes.string,
  returnContentOnly: PropTypes.bool,
  loadItemsImmediately: PropTypes.bool,
  skipResetOnContentUriChange: PropTypes.bool,
  showProgressIndicator: PropTypes.bool,
  showProgressIndicatorImmediately: PropTypes.bool,
  showOrgAsTitleOnDesktop: PropTypes.bool,
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  isPublicApi: PropTypes.bool,
  initialSortTypeForPublicApi: PropTypes.string,
  initialViewTypeForPublicApi: PropTypes.string,
  dialogContent: PropTypes.object,
  miscContent: PropTypes.object,
  allowKanban: PropTypes.bool,
  captureReservationParams: PropTypes.object,
  onSetBeginFileUploadFunc: PropTypes.func,
  itemListContainerStyle: PropTypes.object,
  itemListStyle: PropTypes.object,
  onSetUpdateRevisedItemFunction: PropTypes.func,
  onSetGetAllItemsFunction: PropTypes.func,
  onSetSetAllItemsFunction: PropTypes.func,
  onViewTypeChanged: PropTypes.func,
  apiError: PropTypes.object,
  alert: PropTypes.object,
  onAlert: PropTypes.func,
  onItemClick: PropTypes.func,
  disableCardActions: PropTypes.bool,
  onActionCompleted: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(ItemCollectionBase);