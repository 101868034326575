import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import UiCore from '../Components/UiCore';
import EmployeesDialog from '../Components/EmployeesDialog';
import { GlobalContext } from '../Context/Global.context';

import { ValidateEmail } from '../Util/Regex';

import API, {
  GetCompanyPathForApi,
  GetCompanyIntegrationConnectionVerificationPathForApi,
} from '../Util/api';
import {
  GetQuickBooksDisconnectPath,
} from '../Util/PathHelper';
import {
  HandleSignInAndConnect,
  QuickBooksButtonClasses,
} from '../Util/QuickBooks';

import green from '@material-ui/core/colors/green';

const styles = theme => ({
  quickBooksConnectionContent: {
    marginBottom:theme.spacing(8),
  },
  ...QuickBooksButtonClasses,
});

class Settings extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      Company: null,
      OriginalCompany: null,
      RejectionEmailIsValid: true,
      ShowEmployeesDialogVisibility: false,
      ShowProgressIndicatorImmediately: false,
    }
  }

  componentDidMount() {
    this.context.GetUser(true)
      .then(user => {
        this.loadCompany();
      })
      .catch(this.handleApiError);
  }

  handleApiError = ApiError => {
    this.setState({ApiError});
  }

  loadCompany = () => {
    this.setState({ShowProgressIndicatorImmediately:true});

    API.get(GetCompanyPathForApi(this.context.User.CompanyID))
      .then(resp => {
        let Company = resp.data;
        const setCompletionState = () => {
          this.setState({
            Company,
            OriginalCompany:Company,
          });
        }
        // Verify connection if Company.IntegrationConnectionIsActive
        // Host will set IntegrationConnectionIsActive if invalid_grant error returned
        if (!Company.IntegrationID || !Company.IntegrationConnectionIsActive) {
          setCompletionState();
        } else {
          return API.get(GetCompanyIntegrationConnectionVerificationPathForApi(Company.ID, Company.IntegrationID))
            .catch(err => {
              Company.IntegrationConnectionIsActive = false;              
            })
            .finally(() => {
              setCompletionState();
            });
        }
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately:false});
      });
  }

  handleUpdateCompany = company => {
    this.setState({ShowProgressIndicatorImmediately:true});

    if (!company) {
      company = {...this.state.Company};
    }

    API.put(GetCompanyPathForApi(this.context.User.CompanyID), company)
      .then(resp => {
        this.setState({Company:resp.data})
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately:false});
      });
  }

  handleRejectionEmailChange = e => {
    const RejectionEmail = e.target.value || this.state.Company.LastSignInUserEmail;
    let Company = {...this.state.Company};
    if (Company.OriginalRejectionEmail === undefined) {
      Company.OriginalRejectionEmail = Company.RejectionEmail || "";
    }
    Company.RejectionEmail = RejectionEmail;
    this.setState({
      Company,
      RejectionEmailIsValid: ValidateEmail(RejectionEmail),
    });
  }

  handleAllowVendorCreationChange = e => {
    const AllowVendorCreation = e.target.checked;
    let Company = {...this.state.Company};
    if (Company.OriginalAllowVendorCreation === undefined) {
      Company.OriginalAllowVendorCreation = Company.AllowVendorCreation || false;
    }
    Company.AllowVendorCreation = AllowVendorCreation;
    this.setState({ Company });
  }

  setShowEmployeesDialogVisibility = ShowEmployeesDialogVisibility => {
    this.setState({ShowEmployeesDialogVisibility});

    if (ShowEmployeesDialogVisibility === false) {
      // Because the current user may have been affected, reset the context
      this.context.Reset();
    }
  }

  render() {
    const {
      classes,
      theme,
    } = this.props;
    const {
      Company,
      // OriginalCompany,
      RejectionEmailIsValid,
      ShowEmployeesDialogVisibility,
      ApiError,
      ShowProgressIndicatorImmediately,
    } = this.state;

    const showUpdateButton = Company
      && RejectionEmailIsValid
      && (
        (Company.OriginalRejectionEmail !== undefined
          && Company.OriginalRejectionEmail !== Company.RejectionEmail
        )
        || (Company.OriginalAllowVendorCreation !== undefined
          && Company.OriginalAllowVendorCreation !== Company.AllowVendorCreation
        )
      );
    const updateButtonGridItem = (showUpdateButton)
      ? (
        <Grid item>
          <Button variant="contained" onClick={() => this.handleUpdateCompany()}
            style={{
              backgroundColor: green[500],
              color: "white",
            }}
          >
            Update
          </Button>
        </Grid>
      ) : null;

    // const voteForAllowVendorCreationButtonOrMessage =
    //   (!Company)
    //     ? null
    //     : (Company && Company.AllowVendorCreation)
    //       ? (
    //         <div>
    //           You have voted for this feature.
    //         </div>
    //       )
    //       : (
    //         <Button variant="contained" size="small" onClick={
    //           () => this.handleUpdateCompany({
    //             ...OriginalCompany,
    //             AllowVendorCreation: true,
    //           })
    //         }>
    //           Vote for this feature
    //         </Button>
    //       );

    let quickBooksConnectionContent;
    const isQuickBooksCompany = Company && Company.IntegrationType === "QuickBooks" && Company.IntegrationID;
    if (isQuickBooksCompany) {
      quickBooksConnectionContent = (
        <div className={classes.quickBooksConnectionContent}>
          {
            (Company.IntegrationConnectionIsActive)
              ? (
                <div>
                  <Typography>
                    {`${Company.CompanyName} is connected to this app.`}
                  </Typography>
                  <a href={GetQuickBooksDisconnectPath(Company.ID, Company.IntegrationID)}>
                    Disconnect from QuickBooks
                  </a>
                </div>
              )
              : (
                <div className={classes.connectToQuickBooksButton}
                  onClick={() => HandleSignInAndConnect()}
                />
              )
          }
        </div>
      );
    }

    const employeesDialog = (ShowEmployeesDialogVisibility)
      ? (
        <EmployeesDialog
          companyId={Company && Company.ID}
          onApiError={this.handleApiError}
          onClose={() => this.setShowEmployeesDialogVisibility(false)}
        />
      ) : null;

    const employeesButtonGridItem = (isQuickBooksCompany) ? (
      <Grid item style={{marginTop:theme.spacing(3)}}>
        <Button variant="contained" onClick={() => this.setShowEmployeesDialogVisibility(true)}>
          Employees
        </Button>
      </Grid>
    ) : null;

    const content = (!Company)
      ? null
      : (
        <Container maxWidth="sm" style={{marginTop:theme.spacing(1)}}>
          {employeesDialog}
          {quickBooksConnectionContent}

          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                id="rejectionEmail"
                label="Email address for handling rejected invoices"
                fullWidth
                variant="outlined"
                onChange={this.handleRejectionEmailChange}
                helperText={(!RejectionEmailIsValid) ? "Enter a valid e-mail" : ""}
                value={(Company && Company.RejectionEmail) || ""}
                onKeyDown={e => {
                  if (e.keyCode === 13 && RejectionEmailIsValid) {
                    e.preventDefault();
                    this.handleUpdateCompany();
                  }
                }}
              />
            </Grid>
            <Grid item>
              <div style={{display:"flex",alignItems:"center"}}>
                <FormControlLabel
                  control={
                    <Switch
                      id="allowVendorCreation"
                      color="secondary"
                      onClick={this.handleAllowVendorCreationChange}
                      checked={(Company && Company.AllowVendorCreation) || false}
                     />
                  }
                  label="Allow vendor creation" />
              </div>
            </Grid>
            {updateButtonGridItem}
            {employeesButtonGridItem}
          </Grid>
        </Container>
      );

    return (
      <UiCore
        title="Settings"
        content={content}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        apiError={ApiError}
        apiContext="User"
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Settings);
