import React, {Component} from 'react';

import IconButton from '@material-ui/core/IconButton';
// import TextField from '@material-ui/core/TextField';
// import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import CloseIcon from '@material-ui/icons/Close';

import { IsMobile } from '../Util/MobileDetector';
import ExpenseViewer from '../Components/ExpenseViewer';
import ProgressIndicator from '../Components/ProgressIndicator';

import API, {
  GetExpenseReviewPathForApi,
  GetExpenseApprovalPathForApi,
} from '../Util/api';

const styles = theme => ({
  dialogPaper: {
    height:(!IsMobile()) ? "90%" : undefined,
  },
});

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

class ExpenseViewDialog extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicatorImmediately: false,
    }
  }

  handleClose = refreshHistory => {
    if (this.props.onClose) {
      this.props.onClose(refreshHistory);
    }
  }

  handleApproval = approvalResult => {
    this.setState({ShowProgressIndicatorImmediately: true});
    
    let expense = {...this.props.expense};
    expense.ApprovalResult = approvalResult;
    return API.post(GetExpenseApprovalPathForApi(this.context.EmployeeID, expense.ID), expense)
      .then(resp => {
        this.handleClose(true);
      });
  }

  handleApproveExpense = () => {
    return this.handleApproval("Approved");
  }

  handleDenyExpense = () => {
    return this.handleApproval("Denied");
  }

  handleSubmitExpense = () => {
    this.setState({ShowProgressIndicatorImmediately: true});
    
    return API.post(GetExpenseReviewPathForApi(this.context.EmployeeID, this.props.expense.ID), this.props.expense)
      .then(resp => {
        this.handleClose(true);
      });
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    const {
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      // theme,
      expense,
    } = this.props;

    const content = (expense) ? (
      <ExpenseViewer
        readOnly={expense.Stage !== "Deleted" && expense.Stage !== "Review"}
        returnContentOnly
        onLoadSingleExpense={() => Promise.resolve({data: {Expenses: [expense]}})}
        onSubmitExpense={(expense.Stage === "Review" || expense.Stage === "Deleted") ? () => this.handleSubmitExpense() : null}
        onApproveExpense={(expense.Stage === "Approval") ? () => this.handleApproveExpense() : null}
        onDenyExpense={(expense.Stage === "Approval") ? () => this.handleDenyExpense() : null}
      />
    ) : null;

    const progressIndicator = (ShowProgressIndicatorImmediately)
      ? ( <ProgressIndicator constrained showImmediately={ShowProgressIndicatorImmediately} /> )
      : null;

    return (
      <Dialog
        open
        fullScreen={IsMobile()}
        fullWidth={!IsMobile()}
        maxWidth={expense && expense.PageCount > 0 ? "lg" : "md"}
        // TransitionComponent={Transition}
        classes={{
          paper:classes.dialogPaper,
        }}
        onClose={() => this.handleClose()}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        {progressIndicator}
        <DialogTitle id="dialog-title">
          <div style={{display:"flex",textAlign:"left"}}>
            <div style={{flexGrow:1}}>
              
            </div>
            <div style={{display:"flex",marginTop:-8,marginRight:-16}}>
              <IconButton onClick={() => this.handleClose()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {content}          
        </DialogContent>
      </Dialog>
    );
  }
}

ExpenseViewDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  expense: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(ExpenseViewDialog);
