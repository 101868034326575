import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import { IsMobile } from '../Util/MobileDetector';

const defaultCardHeight = 180;
const styles = theme => ({
	card: {
  },
  cardActionArea: {
    fontFamily:"inherit",
  },
	cardContent: {
    fontWeight:500,
    fontSize:20,
		minHeight:(!IsMobile()) ? defaultCardHeight : undefined,
		maxHeight:(!IsMobile()) ? defaultCardHeight : undefined,
	},
});

class DashboardCard extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  render() {
    // const {
    // } = this.state;
    const {
      height,
      style,
      placeholder,
      children,
      onClick,
      classes,
    } = this.props;

    const placeholderColor = "#aaa";

    const cardContent = (
      <CardContent
        className={classes.cardContent}
        style={{
          height,
          minHeight: height,
          maxHeight: height,
          ...style,
        }}
        onClick={onClick}
      >
        {children}
      </CardContent>
    );

    const cardChildren = (onClick)
      ? (
        <CardActionArea className={classes.cardActionArea}>
          {cardContent}
        </CardActionArea>
      )
      : cardContent;

    return (
      <Card
        className={classes.card}
        elevation={(placeholder) ? 0 : 4}
        style={{
          color: (placeholder) ? placeholderColor : undefined,
          backgroundColor: (placeholder) ? "transparent" : undefined,
          border: (placeholder) ? `3px dashed ${placeholderColor}` : undefined,
        }}
      >
        {cardChildren}
      </Card>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DashboardCard);

DashboardCard.propTypes = {
  classes: PropTypes.object,
  placeholder: PropTypes.bool,
  height: PropTypes.number,
  style: PropTypes.object,
  children: PropTypes.any,
};