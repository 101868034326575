import axios from 'axios';

const api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_HOST + "/api/v1/",
});

export default api

export const GetSupportAdminPathForApi = () => {
  return "/supportAdmin";
}

export const GetSupportStatsPathForApi = () => {
  return "/support/stats";
}

export const GetSupportStatOrganizationCountPathForApi = () => {
  return `${GetSupportStatsPathForApi()}/organizationCount`;
}

export const GetSupportStatUserCountPathForApi = () => {
  return `${GetSupportStatsPathForApi()}/userCount`;
}

export const GetSupportStatActiveUsersPathForApi = () => {
  return `${GetSupportStatsPathForApi()}/activeUsers`;
}

export const GetSupportStatBillableUsersPathForApi = () => {
  return `${GetSupportStatsPathForApi()}/billableUsers`;
}

export const GetSupportStatUserProvidersPathForApi = () => {
  return `${GetSupportStatsPathForApi()}/userProviders`;
}

export const GetSupportCompaniesPathForApi = () => {
  return "/support/companies";
}

export const GetSupportCompanyPathForApi = companyId => {
  return `${GetSupportCompaniesPathForApi()}/${companyId}`;
}

export const GetSupportUsersPathForApi = () => {
  return "/support/users";
}

export const GetSupportUserActivityPathForApi = () => {
  return "/support/userActivity";
}

export const GetUserPathForApi = () => {
  return "/user";
}

export const GetUserProfilePathForApi = () => {
  return `${GetUserPathForApi()}/profile`;
}

export const GetUserSmsNumberPathForApi = () => {
  return `${GetUserPathForApi()}/smsNumber`;
}

export const GetUserSmsNumberVerifyPathForApi = smsChangeCode => {
  return `${GetUserPathForApi()}/smsNumberVerify/${smsChangeCode}`;
}

export const GetUserEmployeeIdPathForApi = employeeId => {
  return `${GetUserPathForApi()}/employeeId/${employeeId}`;
}

export const GetUserCompaniesPathForApi = () => {
  return `${GetUserPathForApi()}/companies`;
}

export const GetUserCompanyPathForApi = companyId => {
  return `${GetUserCompaniesPathForApi()}/${companyId}`;
}

export const GetUserSigninPathForApi = () => {
  return `${GetUserPathForApi()}/signin`;
}

export const GetQuickBooksAdminSignoutPath = () => {
  return `${GetUserPathForApi()}/signout`;
}

export const GetUserEmailLoginVerifyPathForApi = () => {
  return `${GetUserPathForApi()}/emailLoginVerify`;
}

export const GetUserEmailLoginOTPSendPathForApi = () => {
  return `${GetUserPathForApi()}/emailLoginOTPSend`;
}

export const GetUserEmailLoginOTPVerifyPathForApi = () => {
  return `${GetUserPathForApi()}/emailLoginOTPVerify`;
}

export const GetUserPreferencesPathForApi = () => {
  return "/user/preferences";
}

export const GetEmployeeUserPreferencesPathForApi = () => {
  return "/employeeUser/preferences";
}

export const GetPartnerPreferencesPathForApi = () => {
  return "/partner/preferences";
}

// export const GetUserApiKeysPathForApi = user => {
//   return `${GetUserPathForApi()}/apiKeys`;
// }

// export const GetUserApiKeyPathForApi = userApiKeyId => {
//   return `${GetUserApiKeysPathForApi()}/${userApiKeyId}`;
// }

export const GetPartnerPathForApi = () => {
  return "/partner";
}

export const GetPartnerSigninPathForApi = () => {
  return `${GetPartnerPathForApi()}/signin`;
}

export const GetPartnerSignoutPathForApi = () => {
  return `${GetPartnerPathForApi()}/signout`;
}

export const GetPartnerEmailLoginVerifyPathForApi = () => {
  return `${GetPartnerPathForApi()}/emailLoginVerify`;
}

export const GetPartnerEmailLoginOTPSendPathForApi = () => {
  return `${GetPartnerPathForApi()}/emailLoginOTPSend`;
}

export const GetEmployeeUserPathForApi = () => {
  return "/employeeUser";
}

export const GetEmployeeUserSigninPathForApi = () => {
  return `${GetEmployeeUserPathForApi()}/signin`;
}

export const GetEmployeeUserSignoutPathForApi = () => {
  return `${GetEmployeeUserPathForApi()}/signout`;
}

export const GetEmployeeUserEmailLoginVerifyPathForApi = () => {
  return `${GetEmployeeUserPathForApi()}/emailLoginVerify`;
}

export const GetEmployeeUserEmailLoginOTPSendPathForApi = () => {
  return `${GetEmployeeUserPathForApi()}/emailLoginOTPSend`;
}

export const GetEmployeeUserEmailLoginOTPVerifyPathForApi = () => {
  return `${GetEmployeeUserPathForApi()}/emailLoginOTPVerify`;
}

export const GetPartnerBillingPaymentCardsPathForApi = () => {
  return `${GetPartnerPathForApi()}/billingPaymentCards`;
}

export const GetPartnerBillingSubscriptionPathForApi = companyId => {
  return `${GetPartnerPathForApi()}/billingSubscription`;
}

export const GetPartnerBillingSubscriptionUsagePathForApi = () => {
  return `${GetPartnerPathForApi()}/billingSubscriptionUsage`;
}

export const GetPartnerBillingSubscriptionInvoicesPathForApi = companyId => {
  return `${GetPartnerBillingSubscriptionPathForApi()}/invoices`;
}

export const GetCompaniesPathForApi = () => {
  return `/companies`;
}

export const GetCompanyPathForApi = companyId => {
  return `${GetCompaniesPathForApi()}/${companyId}`;
}

export const GetCompanyIntegrationsPathForApi = companyId => {
  return `${GetCompanyPathForApi(companyId)}/integrations`;
}

export const GetCompanyIntegrationPathForApi = (companyId, integrationId) => {
  return `${GetCompanyIntegrationsPathForApi(companyId)}/${integrationId}`;
}

export const GetCompanyIntegrationConnectionVerificationPathForApi = (companyId, integrationId) => {
  return `${GetCompanyIntegrationPathForApi(companyId, integrationId)}/connectionVerification`;
}

export const GetCompanyStatsPathForApi = companyId => {
  return `${GetCompanyPathForApi(companyId)}/stats`;
}

export const GetCompanyPagesProcessedStatsPathForApi = companyId => {
  return `${GetCompanyStatsPathForApi(companyId)}/pagesProcessed`;
}

export const GetCompanyBillingPaymentCardsPathForApi = companyId => {
  return `${GetCompanyPathForApi(companyId)}/billingPaymentCards`;
}

export const GetCompanyBillingSubscriptionPathForApi = companyId => {
  return `${GetCompanyPathForApi(companyId)}/billingSubscription`;
}

export const GetCompanyBillingSubscriptionUsagePathForApi = companyId => {
  return `${GetCompanyPathForApi(companyId)}/billingSubscriptionUsage`;
}

export const GetCompanyBillingSubscriptionInvoicesPathForApi = companyId => {
  return `${GetCompanyBillingSubscriptionPathForApi(companyId)}/invoices`;
}

export const GetCompanyEmailAddressPrefixesPathForApi = companyId => {
  return `${GetCompanyPathForApi(companyId)}/emailAddressPrefixes`;
}

export const GetCompanyEmailAddressPrefixPathForApi = (companyId, emailAddressPrefix) => {
  return `${GetCompanyEmailAddressPrefixesPathForApi(companyId)}/${emailAddressPrefix}`;
}

export const GetCompanyEmployeesPathForApi = companyId => {
  return `${GetCompanyPathForApi(companyId)}/employees`;
}

export const GetCompanyEmployeePathForApi = (companyId, employeeId) => {
  return `${GetCompanyEmployeesPathForApi(companyId)}/${employeeId}`;
}

export const GetCompanyQuickBooksDisconnectPathForApi = companyId => {
  return `${GetCompanyPathForApi(companyId)}/quickBooksDisconnect`;
}

export const GetEmployeesPathForApi = () => {
  return "/employees";
}

export const GetEmployeePathForApi = employeeId => {
  return `${GetEmployeesPathForApi()}/${employeeId}`;
}

export const GetExpensePageUploadsPathForApi = employeeId => {
  return `${GetEmployeePathForApi(employeeId)}/expensePageUploads`;
}

export const GetExpensesPathForApi = employeeId => {
  return `${GetEmployeePathForApi(employeeId)}/expenses`;
}

export const GetExpensePathForApi = (employeeId, expenseId) => {
  return `${GetExpensesPathForApi(employeeId)}/${expenseId}`;
}

export const GetExpensePagesPathForApi = (employeeId, expenseId) => {
  return `${GetExpensePathForApi(employeeId, expenseId)}/pages`;
}

export const GetExpensePagePathForApi = (employeeId, expenseId, pageId) => {
  return `${GetExpensePagesPathForApi(employeeId, expenseId)}/${pageId}`;
}

export const GetExpenseStagePathForApi = (employeeId, expenseId, stage) => {
  return `${GetExpensePathForApi(employeeId, expenseId)}/stages/${stage}`;
}

export const GetExpenseInfoPathForApi = employeeId => {
  return `${GetEmployeePathForApi(employeeId)}/expenseInfo`;
}

export const GetCompletedExpensesPathForApi = employeeId => {
  return `${GetEmployeePathForApi(employeeId)}/completedExpenses`;
}

export const GetExpensesProcessingPathForApi = employeeId => {
  return `${GetEmployeePathForApi(employeeId)}/expensesProcessing`;
}

export const GetExpenseReviewsPathForApi = employeeId => {
  return `${GetEmployeePathForApi(employeeId)}/expenseReviews`;
}

export const GetExpenseReviewPathForApi = (employeeId, expenseId) => {
  return `${GetExpenseReviewsPathForApi(employeeId)}/${expenseId}`;
}

export const GetExpenseApprovalsPathForApi = employeeId => {
  return `${GetEmployeePathForApi(employeeId)}/expenseApprovals`;
}

export const GetExpenseApprovalPathForApi = (employeeId, expenseId) => {
  return `${GetExpenseApprovalsPathForApi(employeeId)}/${expenseId}`;
}

export const GetPublicVideoPath = filename => {
  return `https://storage.googleapis.com/${
      process.env.REACT_APP_GOOGLE_PROJECTID}-videos/${filename}`;
}

export function GetUploadFilePromise(file, reservationUri, reservationParams, cancelToken, onUploadProgressHandler, onCompletedFileHandler) {
  const handleUploadProgressInternal = (e, completedSize) => onUploadProgressHandler(file.name, file.size, completedSize);
  handleUploadProgressInternal(null, 0);
  
  // Get an upload reservation
  // Will fail if Content-Type is not set with the value that will be used in the PUT
  return api.get(reservationUri,
    {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      params: reservationParams,
    })
    .then(async resp => {
      // As long as resp.data contains a SignedUrl property, we can ignore its actual type 
      let reservation = resp.data;
      // Initiate upload
      let initiateResult = await axios.put(reservation.SignedUrl, {},
        {
          headers: {
            "Content-Type": "application/octet-stream",
            // "Content-Length": "0", // This is now considered unsafe. Axios or the browser should automatically set this.
            "x-goog-resumable": "start",
          },
        }
      );
      let sessionUri = initiateResult.headers.location;
      let offset = 0;
      let chunkSize = 1024 * 1024;
      let processChunk = offset => new Promise((resolve, reject) => {
        try {
          let r = new FileReader();
          let chunk = file.slice(offset, chunkSize + offset);
          r.onload = evt => {
            if (evt.target.error) {
              reject(evt.target.error);
            } else {
              resolve(evt.target.result);
            }
          }
          r.readAsArrayBuffer(chunk);
        } catch (err) { reject(err); }
      });


      // Read each chunk and upload synchronously
      while (offset < file.size || file.size === 0) {
        // Get chunk
        let binaryChunk = await processChunk(offset);
        let byteEnd = (file.size === 0) ? 0 : Math.min(offset+chunkSize-1, file.size-1)
        // Upload chunk
        await axios.put(sessionUri, binaryChunk,
          { 
            headers: (file.size === 0) ? {} : { 
              // "Content-Length": `${file.size.toString()}`, // This is now considered unsafe. Axios or the browser should automatically set this.
              "Content-Range": `bytes ${offset.toString()}-${byteEnd.toString()}/${file.size.toString()}`,
            },
            onUploadProgress: e => handleUploadProgressInternal(e, byteEnd),
            cancelToken: cancelToken.token,
          }
        )
        .catch (err => {
          if (axios.isCancel(err)) {
            throw err;
          } 
            // A 308 is desired after uploading a chunk
          else if (err.response !== undefined && err.response.status !== 308) {
            throw err;
          }
        });
        // Stop here if this is a 0-byte file
        if (file.size === 0) {
          break
        }
        // Increment offset
        offset += binaryChunk.byteLength;
      } /* END CHUNK LOOP */

      // Success - Send revised upload reservation to server
      const { resp: completionResp, err: completionErr } = await onCompletedFileHandler(file, reservation);

      // Final progress update for file
      onUploadProgressHandler(file.name, file.size, file.size, true, completionResp, completionErr);

      if (completionErr) {
        return Promise.reject(completionErr);
      }
    });
}

export function UploadFiles(files, reservationUri, reservationParams, cancelToken, onUploadProgressHandler, 
  onCompletedFileHandler, onAlert, onApiError) {
  // files will be uploaded concurrently
  for (let i = 0; i < files.length; i++) {
    GetUploadFilePromise(files[i], reservationUri, reservationParams, cancelToken, onUploadProgressHandler, onCompletedFileHandler)
      // .then(() => { })
      .catch(err => {
        if (axios.isCancel(err)) {
          onAlert({
            Title: "Upload canceled",
            BodyText: "Item(s) have been canceled."
          });
        } else {
          onApiError(err);
        }
      });
  }
}

export function GetUploadBinaryPromise(binary, signedUrl, reservationUri, reservationParams, cancelToken, 
  onUploadProgressHandler, onCompletedFileHandler, onReservationObtained) {
  
  const handleUploadProgressInternal = (e, completedSize) => onUploadProgressHandler(binary.length, completedSize);
  handleUploadProgressInternal(null, 0);

  const handleUpload = async signedUrl => {
    // Initiate upload
    // As long as reservation contains a SignedUrl property, we can ignore its actual type 
    let initiateResult = await axios.put(signedUrl, {},
      {
        headers: {
          "Content-Type": "application/octet-stream",
          // "Content-Length": "0", // This is now considered unsafe. Axios or the browser should automatically set this.
          "x-goog-resumable": "start",
        },
      }
    );
    let sessionUri = initiateResult.headers.location;
    let offset = 0;
    let chunkSize = 1024 * 1024;
    // Read each chunk and upload synchronously
    while (offset < binary.length || binary.length === 0) {
      // Get chunk
      let binaryChunk = binary.slice(offset, chunkSize + offset);
      let byteEnd = (binary.length === 0) ? 0 : Math.min(offset+chunkSize-1, binary.length-1)
      // Upload chunk
      await axios.put(sessionUri, binaryChunk,
        { 
          headers: (binary.length === 0) ? {} : { 
            // "Content-Length": `${binary.length.toString()}`, // This is now considered unsafe. Axios or the browser should automatically set this.
            "Content-Range": `bytes ${offset.toString()}-${byteEnd.toString()}/${binary.length.toString()}`,
          },
          onUploadProgress: e => handleUploadProgressInternal(e, byteEnd),
          cancelToken: cancelToken.token,
        }
      )
      .catch (err => {
        if (axios.isCancel(err)) {
          throw err;
        } 
          // A 308 is desired after uploading a chunk
        else if (err.response !== undefined && err.response.status !== 308) {
          throw err;
        }
      });
      // Stop here if this is a 0-byte binary
      if (binary.length === 0) {
        break
      }
      // Increment offset
      offset += binaryChunk.byteLength;
    } /* END CHUNK LOOP */

    // Success - Call onCompletedFileHandler()
    const { resp: completionResp, err: completionErr } = await onCompletedFileHandler(binary);

    // Final progress update for binary
    onUploadProgressHandler(binary.length, binary.length, true, completionResp, completionErr);
  };
  
  if (signedUrl) {
    return new Promise(resolve => {
      resolve(handleUpload(signedUrl));
    });
  } else {
    // Get an upload reservation if we don't already have a signedUrl
    // Will fail if Content-Type is not set with the value that will be used in the PUT
    return api.get(reservationUri,
      {
        headers: {
          "Content-Type": "application/octet-stream",
        },
        params: reservationParams,
      })
      .then(resp => {
        if (onReservationObtained) {
          onReservationObtained(resp.data);
        }

        handleUpload(resp.data.SignedUrl);
      });
  }
}