import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { GlobalContext } from '../Context/Global.context';

import MultiUseDialog from '../Components/MultiUseDialog';
import ProgressIndicator from '../Components/ProgressIndicator';

import {
  HandleSignIn,
  // HandleSignInAndConnect,
  GetScopesForSignIn,
  GetScopesForSignInAndConnect,
  GetRedirectUriForSignIn,
  GetQuickBooksSignInState,
  GetQuickBooksStateFromQueryString,
  GetQuickBooksRealmIDFromQueryString,
  GetQuickBooksAuthCodeFromQueryString,
  GetQuickBooksErrorFromQueryString,
} from '../Util/QuickBooks';

import {
  GetUserHomePath,
} from '../Util/PathHelper';
import {
  BuildUpSignInPackage,
} from '../Util/SignIn';
import API, {
  GetUserSigninPathForApi,
} from '../Util/api';
import {
  GetTextWithUrlsAsRawAnchors,
  GetBodyHtmlWithModifiedAnchors,
} from '../Util/Regex';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    display:"flex",
    overflow:"hidden",
  },
});

class IntuitSignIn extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      redirectToReferrer: false,
      DialogDetails: { Open: false },
      ShowProgressIndicatorImmediately: true,
    }

    this.QuickBooksSignInState = GetQuickBooksStateFromQueryString(props);
    this.QuickBooksAuthorizationCode = GetQuickBooksAuthCodeFromQueryString(props);
    this.QuickBooksRealmID = GetQuickBooksRealmIDFromQueryString(props);
    this.QuickBooksError = GetQuickBooksErrorFromQueryString(props);
  }

  handleApiError = err => {
    const msgWithAnchors = (err.response.data.message)
      ? GetTextWithUrlsAsRawAnchors(err.response.data.message.replaceAll("\n","<br />"), this.props.theme)
      : null;
    this.handleShowDialog({
      Open: true,
      Title: "Sorry, something went wrong...",
      BodyContent: (msgWithAnchors)
        ? "<div style=\"font-size:16px\">"
          + GetBodyHtmlWithModifiedAnchors(msgWithAnchors, this.props.theme, true)
          + "</div>"
        : "",
      BodyText: (!msgWithAnchors)
        ? "Please try again."
        : null,
      BodyContentIsRawHtml: Boolean(msgWithAnchors),
      CloseCallback: () => this.tryCloseWindowOrRedirectToRoot(),
    });
  }

  tryCloseWindowOrRedirectToRoot = () => {
    window.close();
    this.props.history.replace(GetUserHomePath());
  }

  handleSignin = () => {
    // console.log(this.QuickBooksSignInState);
    // console.log(this.QuickBooksError);
    // console.log(this.QuickBooksRealmID);
    // console.log(this.QuickBooksAuthorizationCode);

    if (this.QuickBooksError) {
      this.tryCloseWindowOrRedirectToRoot();
    }

    if (!this.QuickBooksAuthorizationCode) {
      return HandleSignIn();
    }

    if (this.QuickBooksSignInState.state !== GetQuickBooksSignInState().state) {
      return this.handleApiError({response:{data:{message:"Sign-in state invalid"}}});
    }

    const OAuthScopes = (this.QuickBooksSignInState.isCompanyConnect)
      ? GetScopesForSignInAndConnect()
      : GetScopesForSignIn();

    const SignInPackage = BuildUpSignInPackage(this.state.SignInPackage, {
      UserProvider: "intuit",
      OAuthRealmID: this.QuickBooksRealmID,
      OAuthCode: this.QuickBooksAuthorizationCode,
      OAuthScopes,
      OAuthRedirectUri: GetRedirectUriForSignIn(),
    });

    API.post(GetUserSigninPathForApi(), SignInPackage)
      .then(resp => {
        this.setState({ redirectToReferrer: true });
      })
      .catch(this.handleApiError);
  }

  handleShowDialog = DialogDetails => {
    if (!DialogDetails) {
      this.setState({DialogDetails: { Open: false }});
      return;
    }

    this.setState({DialogDetails});
  }

  componentDidMount() {
    this.handleSignin();
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    const {
      redirectToReferrer,
      DialogDetails,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
    } = this.props;

    if (redirectToReferrer === true) {
      const from = this.QuickBooksSignInState.from || GetUserHomePath();
      return (
        <Redirect to={from} />
      );
    }

    const progressIndicator = (ShowProgressIndicatorImmediately)
      ? ( <ProgressIndicator showImmediately /> )
      : null;

    return (
      <div className={classes.root}>
        {progressIndicator}

        <MultiUseDialog Details={DialogDetails} />
      </div>
    );
  }
}

IntuitSignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  signInOnly: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(IntuitSignIn);
