export function GetUserApiKeysPath() {
	return "/user/apiKeys";
}

export function GetUserProfilePath() {
	return "/user/profile";
}

export function GetUserApiKeyPath(userApiKeyId) {
	return `${GetUserApiKeysPath()}/${userApiKeyId}`;
}

export function GetRootPath() {
	return "/";
}

export function GetDashboardPath() {
	return "/dashboard";
}

export function GetPartnerDashboardPath() {
	return "/partner/dashboard";
}

export function GetPartnerBillingPath() {
	return "/partner/billing";
}

export function GetExpensesPath() {
	return "/expenses";
}

export function GetReceiptCapturePath() {
	return `${GetExpensesPath()}/capture`;
}

export function GetExpenseReviewPath() {
	return `${GetExpensesPath()}/review`;
}

export function GetExpenseApprovalPath() {
	return `${GetExpensesPath()}/approval`;
}

export function GetExpenseHistoryPath() {
	return `${GetExpensesPath()}/history`;
}

export function GetHomePath(isCompanyAdmin) {
	if (isCompanyAdmin) {
		return GetDashboardPath();
	} else {
		return GetReceiptCapturePath();
	}
}

export function GetUserHomePath() {
	return GetDashboardPath();
}

export function GetPartnerHomePath() {
	return GetPartnerDashboardPath();
}

export function GetSignInPath(route) {
	return `/signin${(route) ? "/" + route : ""}`;
}

export function GetEmployeeUserSignInPath() {
	return "/employee/signin";
}

export function GetPartnerSignInPath() {
	return "/partner/signin";
}

export function GetIntuitSignInPath() {
	return "/signin/intuit";
}

export function GetQuickBooksDisconnectPath(companyId, realmId) {
	return `/quickbooks/disconnect?companyId=${companyId}&realmId=${realmId}`;
}

export function GetUserSignOutPath() {
	return "/signout";
}

export function GetPartnerSignOutPath() {
	return "/partner/signout";
}

export function GetBillingPath() {
	return "/billing";
}

export function GetHelpPath() {
	return "/help";
}

export function GetSettingsPath() {
	return "/settings";
}

export function GetSupportPath() {
	return "/support";
}

export function GetSupportStatsPath() {
	return `${GetSupportPath()}/stats`;
}

export function GetSupportCompaniesPath() {
	return `${GetSupportPath()}/companies`;
}

export function GetSupportOrganizationsRequiringSubscriptionPath() {
	return `${GetSupportPath()}/organizationsRequiringSubscription`;
}

export function GetSupportCompanyDetailPath(companyId) {
	return `${GetSupportCompaniesPath()}/${companyId}`;
}

export function GetSupportUsersPath() {
	return `${GetSupportPath()}/users`;
}

export function GetSupportUserActivityPath() {
	return `${GetSupportPath()}/userActivity`;
}

export function GetSupportErrorEventsPath() {
	return `${GetSupportPath()}/errorEvents`;
}

export function IsFirstUrlPathPartPartner(url, baseUrlOptional) {
	try {
		let parsedUrl;
		try {
			// Try as if the url is absolute
			parsedUrl = new URL(url);
		} catch (e) {
			// If it's not absolute, handle it as a relative URL
			parsedUrl = new URL(url, baseUrlOptional || window.location.origin);
		}

		// Get the pathname and remove the leading slash
		const pathSegments = parsedUrl.pathname.split('/').filter(part => part !== '');

		// Check if the first segment is exactly 'partner'
		return pathSegments[0] === 'partner';
	} catch (error) {
		return false;
	}
}