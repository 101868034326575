import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
// import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';

import {
  CardElement,
} from '@stripe/react-stripe-js';

import { CurrencyWithSeparators } from '../Util/NumberFormatting';
import { ValidateEmail } from '../Util/Regex';
import { GlobalContext } from '../Context/Global.context';
import MultiUseDialog from '../Components/MultiUseDialog';
import ProgressIndicator from '../Components/ProgressIndicator';

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
  pricingBlue: {
    color: theme.palette.text.darkBlue,
  },
  cardElement_base: {
    color: theme.palette.text.primary,
    fontSize: 16,
    padding: "18.5px 15px",
    border: "1px solid",
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
    "&:hover": {
      border: "1px solid",
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
  },
  cardElement_focus: {
    padding: "17.5px 14px",
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    "&:hover": {
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
    },
  },
});

class AddPaymentDialog extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      Open: true,
      CardholderName: (props.card) ? props.card.CardholderName : "",
      BillingEmail: (props.card) ? props.card.BillingEmail : "",
      BillingEmailIsValid: (props.card) ? ValidateEmail(props.card.BillingEmail) : true,
      ShowDeleteCardConfirmationDialog: false,
      ShowProgressIndicatorImmediately: false,
    }
  }

  componentDidMount() {
    if (!this.props.card) {
      if (this.context.Subscription && this.context.Subscription.BillingEmail) {
        this.setState({BillingEmail: this.context.Subscription.BillingEmail});
      } else if (this.context.Partner && this.context.Partner.BillingEmail) {
        this.setState({BillingEmail: this.context.Partner.BillingEmail});
      }
    }
  }

  handleApiError = ApiError => {
    this.props.onApiError(ApiError);
    if (ApiError) {
        setTimeout(() => this.handleApiError(null), 100);
    }
  }

  handleBillingEmailChange = e => {
    const BillingEmail = e.target.value;
    this.setState({
      BillingEmail,
      BillingEmailIsValid: (BillingEmail) ? ValidateEmail(BillingEmail) : true,
    });
  }

  getStripeToken = () => {
    const card = this.props.elements.getElement("card");
    return this.props.stripe.createToken(card,
      {
        name: this.state.CardholderName,
        email: this.state.BillingEmail,
      }
    )
      .then(stripeResp => {
        if (stripeResp.error) {
          this.setState({ShowProgressIndicatorImmediately:false});
          return Promise.reject({
            response: {
              data: {
                message: stripeResp.error.message,
              },
            },
          });
        }
        return stripeResp.token;
      });
  }

  handleAddCard = apiPromise => {
    this.setState({ShowProgressIndicatorImmediately:true});
    
    this.getStripeToken()
      .then(token => {
        return this.props.onAddCard(token.id, this.state.BillingEmail, token.card.address_zip)
          .then(() => {
            this.handleClose();
          });
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately:false})
      });
  }

  handleUpdateCard = () => {
    this.setState({ShowProgressIndicatorImmediately:true});

    this.getStripeToken()
      .then(token => {
        return this.props.onUpdateCard(this.props.card.CardID, token.id, this.state.BillingEmail,
          token.card.address_zip)
          .then(() => {
            this.handleClose();
          });
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately:false})
      });
  }

 setShowDeleteCardConfirmationDialog = ShowDeleteCardConfirmationDialog => {
    this.setState({ShowDeleteCardConfirmationDialog});
  }

  handleDeleteCard = () => {
    this.setState({ShowProgressIndicatorImmediately:true});
    return this.props.onDeleteCards([this.props.card.CardID])
      .then(() => {
        this.handleClose();
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately:false})
      });
  }

  handleClose = isCancel => {
    this.props.onClose(isCancel);
  }

  allowAddOrUpdate = () => {
    const {
      CardholderName,
      BillingEmail,
      BillingEmailIsValid,
    } = this.state;
    return (CardholderName && BillingEmail && BillingEmailIsValid);
  }

  render() {
    const {
      CardholderName,
      BillingEmail,
      BillingEmailIsValid,
      Open,
      ShowDeleteCardConfirmationDialog,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      card,
      classes,
      theme,
    } = this.props;
    const {
      Subscription,
    } = this.context;

    const progressIndicator = (ShowProgressIndicatorImmediately)
      ? ( <ProgressIndicator constrained showImmediately={ShowProgressIndicatorImmediately} /> )
      : null;

    const deleteCardConfirmationDialogDetails = {
        Open:ShowDeleteCardConfirmationDialog,
        DialogWidth: "xs",
        IsConfirmation: true,
        ConfirmLabel: "Delete",
        ConfirmButtonStyle: {
          backgroundColor: red[500],
          color:"white",
        },
        Title:`Are you sure you want to delete this card${(card) ? " ending in " + card.Last4 : ""}?`,
        ConfirmCallback: () => this.handleDeleteCard(),
        CancelCallback: () => this.setShowDeleteCardConfirmationDialog(false),
        CloseCallback: () => this.setShowDeleteCardConfirmationDialog(false),
      };

    const handleAddOrUpdateCard = (card) ? this.handleUpdateCard : this.handleAddCard;
    const addOrUpdateButton = (
      <Button variant="contained" onClick={handleAddOrUpdateCard}
        style={{
          backgroundColor:(this.allowAddOrUpdate()) ? green[500] : undefined,
          color:(this.allowAddOrUpdate()) ? "white" : undefined
        }}
        disabled={!this.allowAddOrUpdate()}
      >
      {(card) ? "Update" : "Add"}
      </Button>
    );
    const removeButton = (card && !card.IsDefault)
      ? (
        <Button onClick={() => this.setShowDeleteCardConfirmationDialog(true)}>
          Remove
        </Button>
      ) : null

    const dialogActions = (
      <DialogActions>
        {removeButton}
        <Button variant="contained" onClick={this.handleClose}>
          Cancel
        </Button>
        {addOrUpdateButton}
      </DialogActions>
    );

    const dialogTitle = (card) ? `Update payment method ending in ${card.Last4}` : "Add payment method"

    const cardElementGridItem = (
      <Grid item>
        <Typography variant="body1" style={{fontSize: 12,fontWeight:400, marginBottom:4}}>
          Credit Card
        </Typography>
        <CardElement
          focus
          options={{
            classes: {
              base: classes.cardElement_base,
              focus: classes.cardElement_focus,
            },
            style: {
              color:"blue",
            },
          }}
        />
      </Grid>
    );

    const onTextFieldKeyDown = e => {
      if (this.allowAddOrUpdate() && e.keyCode === 13) {
        e.preventDefault();
        handleAddOrUpdateCard();
      }
    }

    return (
      <Dialog
        open={Open}
        maxWidth="xs"
        fullWidth
        onClose={this.handleClose}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          {progressIndicator}
          <MultiUseDialog Details={deleteCardConfirmationDialogDetails} />
          <DialogContentText style={{textAlign:"center"}}>
            <span className={classes.pricingBlue}>
              Pricing
            </span>
            &nbsp;is&nbsp;
            <span className={classes.pricingBlue}>
              {Subscription && CurrencyWithSeparators(Subscription.PricePerPageUSD)}
            </span>
            &nbsp;per page&nbsp;
          </DialogContentText>
          
          <Grid container direction="column" spacing={2} style={{marginTop:theme.spacing(2)}}>
            {cardElementGridItem}
            <Grid item>
              <TextField
                label="Cardholder name"
                fullWidth
                variant="outlined"
                onChange={event => this.setState({CardholderName: event.target.value})}
                value={CardholderName}
                onKeyDown={onTextFieldKeyDown}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Billing email"
                fullWidth
                variant="outlined"
                onChange={this.handleBillingEmailChange}
                helperText={(!BillingEmailIsValid) ? "Enter a valid e-mail" : ""}
                value={BillingEmail}
                onKeyDown={onTextFieldKeyDown}
              />
            </Grid>
          </Grid>
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AddPaymentDialog);

AddPaymentDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired,
  card: PropTypes.object,
  onAddCard: PropTypes.func.isRequired,
  onUpdateCard: PropTypes.func.isRequired,
  onDeleteCards: PropTypes.func.isRequired,
  onApiError: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};