import React, { Component } from 'react';

import UiCore from '../Components/UiCore';
import { GlobalContext } from '../Context/Global.context';
import ProgressIndicator from '../Components/ProgressIndicator';
import {
  GetHomePath,
  GetSignInPath,
} from '../Util/PathHelper';

class Root extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicatorImmediately: true,
    }
  }

  routeWhenReady() {
    // Check for context API error and pass along.
    // This is important as it will catch 401s, which when passed to UiCore will redirect to /signin.
    if (this.context && this.context.ApiError) {
      // this.setState({ApiError:this.context.ApiError});
      this.props.history.replace(GetSignInPath());
      return;
    }
    if (this.context.User) {
        this.props.history.replace(GetHomePath(this.context.User.IsCompanyAdmin));
    } else {
      setTimeout(() => this.routeWhenReady(), 250);
    }
  }

  componentDidMount() {
    this.routeWhenReady();
    setTimeout(() => this.setState({ShowProgressIndicatorImmediately:false}), 10000);
  }

  handleApiError = err => {
    this.setState({
      ApiError: err,
      ShowProgressIndicatorImmediately: false,
    });
  }

  render() {
    const {
      ApiError,
      ShowProgressIndicatorImmediately,
    } = this.state;

    let uiCore = (
      <UiCore
        forcedApiError={ApiError}
      />
    );
    
    let progressIndicator = (ShowProgressIndicatorImmediately)
      ? (
        <ProgressIndicator showProgressIndicatorImmediately={ShowProgressIndicatorImmediately} />
      )
      : null;

    let content = (ApiError)
      ? uiCore 
      : (
        <div style={{
          width:"100%",
          height:"100%",
        }}>
          {progressIndicator}
        </div>
      );

    return content;
  }
}

export default Root;
