import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CheckboxIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckedIcon from '@material-ui/icons/CheckCircle';

import BillingDashboardCard from '../Components/BillingDashboardCard';
import DashboardCard from '../Components/DashboardCard';
import AddEditPaymentDialog from '../Components/AddEditPaymentDialog';
import PaymentHistoryDashboardCard from '../Components/PaymentHistoryDashboardCard';
import UiCore from '../Components/UiCore';
import { GlobalContext } from '../Context/Global.context';
import { IsMobile } from '../Util/MobileDetector';
import API, {
  GetCompanyBillingPaymentCardsPathForApi,
  GetCompanyBillingSubscriptionPathForApi,
  // GetPartnerBillingPaymentCardsPathForApi,
} from '../Util/api';
import MultiUseDialog from '../Components/MultiUseDialog';
import {
  IsFirstUrlPathPartPartner,
} from '../Util/PathHelper';

import {
   CardClasses,
} from '../Util/Cards';

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

/*
const redButtonStyle = {
  background:"red",
  color:"white",
  margin:(IsMobile()) ? 0 : undefined,
};
*/

const _billingPaymentCardHeight = 240;
const _creditCardLeft = 32;
const _creditCardRight = 32;
const _creditCardTop = 24;
const _creditCardBottom = 16;
const styles = theme => ({
  cardIcon: {
    position:"absolute",
    left:_creditCardLeft,
    top:_creditCardTop,
  },
  cardName: {
    fontSize:22,
    position:"absolute",
    left:_creditCardLeft + 64,
    top:_creditCardTop + 16,
  },
  cardholderName: {
    position:"absolute",
    left:_creditCardLeft,
    top:96,
  },
  cardNumber: {
    position:"absolute",
    left:_creditCardLeft,
    top:124 ,
  },
  cardCheckbox: {
    position:"absolute",
    right:_creditCardRight - 12,
    top:_creditCardTop,
  },
  cardBackup: {
    position:"absolute",
    right:_creditCardRight,
    top:_creditCardTop + 64,
    fontSize:16,
    color:"#999",
  },
  cardEdit: {
    position:"absolute",
    left:_creditCardLeft - 16,
    bottom:_creditCardBottom,
  },
  ...CardClasses(theme),
});

class Billing extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    const ShowAddEditPaymentDialog =
      (props.location && props.location.state && props.location.state.AddPayment) || false;
    props.history.replace(props.location.pathname);

    this.state = {
      BillingPaymentCards: [],
      EditPaymentCard: null,
      ShowAddEditPaymentDialog,
      ShowPartnerSetupDialog: false,
      ShowConfirmRemovePartnerCodeDialog: false,
      UpdateBillingDashboardCardKey: null,
      ShowProgressIndicatorImmediately: false,
    }
  }

  componentDidMount() {
    if (IsFirstUrlPathPartPartner(this.props.location.pathname)) {
      this.context.GetPartner(true)
        .then(partner => {
          this.handleLoadBillingPaymentCards();
        })
        .catch(this.handleApiError);
    } else {
      this.context.GetUser(true)
        .then(user => {
          this.handleLoadBillingPaymentCards();
        })
        .catch(this.handleApiError);
    }
  }

  handleApiError = ApiError => {
    this.setState({
      ApiError,
      ShowProgressIndicatorImmediately: false,
    });
  }

  getBillingPaymentCardsPathForApi = () => {
    return GetCompanyBillingPaymentCardsPathForApi(this.context.User.CompanyID);
    
    // return this.context.APIContext.Type === "Partner"
    //   ? GetPartnerBillingPaymentCardsPathForApi()
    //   : GetCompanyBillingPaymentCardsPathForApi(this.context.User.CompanyID);
  }

  handleLoadBillingPaymentCards = () => {
    this.setState({ShowProgressIndicatorImmediately:true});
    const apiPath = this.getBillingPaymentCardsPathForApi();
    return API.get(apiPath)
      .then(resp => {
        this.setState({BillingPaymentCards: resp.data.BillingPaymentCards});
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately:false});
      });
  }

  handleAddCard = (TokenID, BillingEmail, PostalCode) => {
    const apiPath = this.getBillingPaymentCardsPathForApi();
    return API.post(apiPath, {
      TokenID,
      BillingEmail,
      PostalCode,
    });
  }

  handleUpdateCard = (CardID, TokenID, BillingEmail, PostalCode, SetAsDefault) => {
    const apiPath = this.getBillingPaymentCardsPathForApi();
    return API.put(apiPath, {
      CardID,
      TokenID,
      BillingEmail,
      PostalCode,
      SetAsDefault,
    });
  }

  handleSetCardAsDefault = card => {
    const BillingPaymentCards = [...this.state.BillingPaymentCards]
      .map(bpc => ({...bpc, IsDefault: bpc.CardID === card.CardID}));
    this.setState({
      BillingPaymentCards,
      ShowProgressIndicatorImmediately:true,
    });
    this.handleUpdateCard(card.CardID, "", card.BillingEmail, card.PostalCode, true)
      .then(() => {
        this.handleLoadBillingPaymentCards();
      })
      .catch(err => {
        this.setState({ShowProgressIndicatorImmediately:false});
        this.handleApiError(err);
      });
  }

  handleDeleteCards = cardIDs => {
    const apiPath = this.getBillingPaymentCardsPathForApi();
    return API.delete(apiPath,
      { data: { IDs: cardIDs } }
    );
  }

  setShowAddEditPaymentDialog = (ShowAddEditPaymentDialog, EditPaymentCard, updateCardsAndDashboardCard) => {
    this.setState({
      ShowAddEditPaymentDialog,
      EditPaymentCard,
    });
    if (updateCardsAndDashboardCard) {
      this.handleLoadBillingPaymentCards();
      this.setState({UpdateBillingDashboardCardKey: new Date()});
    }
  }

  handleAddPayment = () => {
    this.setShowAddEditPaymentDialog(true);
  }

  handleEditPayment = card => {
    this.setShowAddEditPaymentDialog(true, card);
  }

  setShowPartnerSetupDialog = ShowPartnerSetupDialog => {
    this.setState({ ShowPartnerSetupDialog });
  }

  setShowConfirmRemovePartnerCodeDialog = ShowConfirmRemovePartnerCodeDialog => {
    this.setState({ ShowConfirmRemovePartnerCodeDialog });
  }

  handlePartnerSetup = PaymentPartnerID => {
    this.setShowPartnerSetupDialog(false);
    return this.handlePartnerSetupInternal(PaymentPartnerID, false);
  }

  handlePartnerSetupInternal = (PaymentPartnerID, AcceptEmptyPaymentPartnerID) => {
    if (!PaymentPartnerID && !AcceptEmptyPaymentPartnerID) {
      return;
    }

    this.setState({ShowProgressIndicatorImmediately: true});
    let subscription;
    if (PaymentPartnerID) {
      // Add PaymentPartnerID to the subscription
      subscription = {
        ...this.context.Subscription,
        PaymentPartnerID,
      };
    } else {
      // Remove PaymentPartnerID from the subscription
      const { PaymentPartnerID, ...remainder } = this.context.Subscription;
      subscription = {
        ...remainder
      };
    }
    return API.put(GetCompanyBillingSubscriptionPathForApi(this.context.User.CompanyID), subscription)
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately: false});
        this.context.GetSubscriptionUsage();
      });
  }

  handlePartnerCodeRemoval = () => {
    this.setShowConfirmRemovePartnerCodeDialog(false);
    return this.handlePartnerSetupInternal(undefined, true);
  }

  getPartnerSection = Subscription => {
    const {
      theme,
      classes,
    } = this.props;

    const hasPartnerCode = !!(Subscription?.PaymentPartnerID ?? false);
    let content = null;
    if (Subscription) {
      if (hasPartnerCode) {
        content = (
          <Grid item xs={12}>
            <div style={{
              display:"flex",
              alignItems:"center",
              marginTop:(!IsMobile()) ? theme.spacing(2) : undefined,
            }}>
              <div
                style={{paddingRight:8}}>
                {Subscription.PaymentPartnerID}
              </div>
              {/*
                The below Remove button is mostly functional.  It functions through the point of
                removing the Subsciption.PaymentPartnerID.  However, it does NOT cancel the
                associated Stripe subscription (Subsciption.PaymentSubscriptionID).
              */}
              {/* <Button variant="contained"
                onClick={() => this.setShowConfirmRemovePartnerCodeDialog(true)}
                size="small"
                style={{
                  ...redButtonStyle,
                }}
              >
                <span style={{whiteSpace:"nowrap"}}>Remove</span>
              </Button> */}
            </div>
          </Grid>
        );
      } else {
        // if (!Subscription.PaymentSubscriptionID) {
        //   content = (
        //     <Button
        //       variant="contained"
        //       style={{marginTop:theme.spacing(4)}} 
        //       onClick={() => this.setShowPartnerSetupDialog(true)}>
        //       I have a Partner Code
        //     </Button>
        //   );
        // }
      }
    }
    
    if (!content) {
      return [null, false];
    }

    const tooltipTitle = "This code connects you with your AP Central Partner.";
    const tooltip = (
      <Tooltip placement="left" arrow
        enterTouchDelay={(IsMobile()) ? 0 : undefined}
        leaveTouchDelay={(IsMobile()) ? 3000 : undefined}
        title={tooltipTitle}
      >
        <IconButton size="small">
          <InfoIcon />
        </IconButton>
      </Tooltip>
    );
    
    const cardContent = (
      <DashboardCard>
        <div>
          <div style={{
            display:"flex",
            alignItems:"center",
          }}>
            <div className={classes.cardTitle} style={{flexGrow:1}}>
              Partner Code {hasPartnerCode ? "" : "(optional)"}
            </div>
            <div style={{marginRight:-4}}>
              {tooltip}
            </div>
          </div>

          {content}

        </div>
      </DashboardCard>
    );
    return cardContent;
  }

  render() {
    const {
      BillingPaymentCards,
      EditPaymentCard,
      ShowAddEditPaymentDialog,
      UpdateBillingDashboardCardKey,
      ShowPartnerSetupDialog,
      ShowConfirmRemovePartnerCodeDialog,
      ApiError,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      theme,
      stripe,
      elements,
      location,
    } = this.props;
    const {
      Partner,
      Subscription,
    } = this.context;

    let creditCardGridItems = [
      <Grid item xs={(IsMobile()) ? 12 : 6} key="k_placeholder">
        <DashboardCard placeholder height={_billingPaymentCardHeight}
          style={{
            display:"flex",
            alignItems:"center",
          }}
          onClick={this.handleAddPayment}
        >
          <Grid container direction="column" spacing={1}
            style={{
              display:"flex",
              alignItems:"center",
            }}
          >
            <Grid item>
              <AddIcon fontSize="large" />
            </Grid>
            <Grid item>
              ADD PAYMENT
            </Grid>                    
          </Grid>
        </DashboardCard>
      </Grid>  
    ];

    BillingPaymentCards.forEach(bpc => {
      const backup = (!bpc.IsDefault)
        ? (
          <div className={classes.cardBackup}>
            Backup
          </div>
        ) : null;
      const color = (bpc.IsDefault) ? "#ddd" : undefined;
      let cardCheckbox = (
          <Checkbox
            id={`card_default_${bpc.CardID}`}
            checked={bpc.IsDefault}
            onChange={e => { if (e.target.checked) this.handleSetCardAsDefault(bpc); }}
            checkedIcon={(<CheckedIcon style={{
              fontSize:32,
              color:green[500],
              backgroundColor:"white",
              borderRadius:24,
            }} />)}
            icon={(<CheckboxIcon style={{
              fontSize:32,
              color,
            }} />)}
          />
      );
      if (!bpc.IsDefault) {
        cardCheckbox = (
          <Tooltip title="Make this the primary card">
            {cardCheckbox}
          </Tooltip>
        );
      }
      creditCardGridItems.push(
        <Grid item xs={(IsMobile()) ? 12 : 6} key={`k_${bpc.CardID}`}>
          <DashboardCard
            height={_billingPaymentCardHeight}
            style={{
              position:"relative",
              color,
              background: (bpc.IsDefault) ? "linear-gradient(150deg, #1f95d9, #355abb 70%)" : "#ccc",
            }}
            >
            <div className={classes.cardIcon}>
              <AccountBalanceIcon style={{fontSize:48}} />
            </div>
            <div className={classes.cardName}>
              {(bpc.Brand) ? bpc.Brand.toUpperCase() : "Credit Card"}
            </div>
            <div className={classes.cardholderName}>
              {bpc.CardholderName}
            </div>
            <div className={classes.cardNumber}>
              xxxx xxxx xxxx {bpc.Last4}
            </div>
            <div className={classes.cardCheckbox}>
              {cardCheckbox}
            </div>
            <div className={classes.cardEdit}>
              <Tooltip title="Update card">
                <IconButton onClick={() => this.handleEditPayment(bpc)}>
                  <SettingsIcon style={{
                    fontSize:24,
                    color,
                  }} />
                </IconButton>
              </Tooltip>
            </div>
            {backup}
          </DashboardCard>
        </Grid>
      );
    });

    const isPartnerContext = IsFirstUrlPathPartPartner(location.pathname);
    const isUserContext = !isPartnerContext;

    const partnerSetupCard = this.getPartnerSection(Subscription);

    const partnerSetupDialog = (
      <MultiUseDialog
        Details={{
          Open: ShowPartnerSetupDialog,
          RequireTextInput1: true,
          TextInput1Label: "Partner Code",
          ConfirmLabel: "Enter",
          ConfirmButtonStyle: {
            backgroundColor: green[500],
            color:"white",
          },
          ConfirmCallback: this.handlePartnerSetup,
          CancelCallback: () => this.setShowPartnerSetupDialog(false),
          CloseCallback: () => this.setShowPartnerSetupDialog(false),
        }}
      />  
    )

    const confirmPartnerRemoveDialog = (
      <MultiUseDialog
        Details={{
          Open: ShowConfirmRemovePartnerCodeDialog,
          DialogWidth: "xs",
          IsConfirmation: true,
          ConfirmLabel: "Remove",
          ConfirmButtonStyle: {
            backgroundColor: red[500],
            color:"white",
          },
          Title:`Are you sure you want to remove the Partner Code?`,
          ConfirmCallback: this.handlePartnerCodeRemoval,
          CancelCallback: () => this.setShowConfirmRemovePartnerCodeDialog(false),
          CloseCallback: () => this.setShowConfirmRemovePartnerCodeDialog(false),
        }}
      />  
    )

    const showCreditCardSection =
      (isPartnerContext && Partner)
      || (isUserContext && Subscription && !Subscription.PaymentPartnerID);
    const creditCardGrid = (showCreditCardSection)
      ? (
        <Grid container spacing={3} style={{marginBottom:(!IsMobile()) ? theme.spacing(6) : undefined}}>
          {creditCardGridItems}
        </Grid>
      ) : null;

    const gridItemLeftColumn = (
      <Grid item xs={(IsMobile()) ? 12 : 7}>
        {creditCardGrid}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {partnerSetupCard}
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </Grid>
    );

    const billingDashboardCardGridItem = (isUserContext) ? (
      <Grid item>
        <BillingDashboardCard
          updateKey={UpdateBillingDashboardCardKey}
          onUpgradeClick={() => this.setShowAddEditPaymentDialog(true)}
          onApiError={this.handleApiError}
        />
      </Grid>
    ) : null;

    const cardPaymentHistoryGridItem = (isPartnerContext) ? (
      <Grid item>
        <PaymentHistoryDashboardCard
          onApiError={this.handleApiError}
          companyIdOverride={undefined}
          cardHeight={600}
        />
      </Grid>
    ) : null;

    const gridItemRightColumn = (
      <Grid item xs={(IsMobile()) ? 12 : 5}>
        <Grid container direction="column">
          {billingDashboardCardGridItem}
          {cardPaymentHistoryGridItem}
        </Grid>
      </Grid>
    );

    const addEditPaymentDialog = (ShowAddEditPaymentDialog && stripe && elements)
      ? (
        <AddEditPaymentDialog
          stripe={stripe}
          elements={elements}
          card={EditPaymentCard}
          onAddCard={this.handleAddCard}
          onUpdateCard={this.handleUpdateCard}
          onDeleteCards={this.handleDeleteCards}
          onApiError={this.handleApiError}
          onClose={isCancel => this.setShowAddEditPaymentDialog(false, null, !isCancel)}
        />
      )
      : null;

    const content = (
      <React.Fragment>
        {addEditPaymentDialog}
        {isUserContext ? partnerSetupDialog : null}
        {isUserContext ? confirmPartnerRemoveDialog : null}
        <Grid container spacing={3}>
          {gridItemLeftColumn}
          {gridItemRightColumn}
        </Grid>
      </React.Fragment>
    );

    return (
      <UiCore
        title="Billing"
        content={content}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        apiError={ApiError}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Billing);
