import { ValidateEmail } from '../Util/Regex';
import red from '@material-ui/core/colors/red';

export const RequiredFieldStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor:red[500],
  },
  "& .MuiCheckbox-root": {
    "& .MuiSvgIcon-root": {
      fill:red[500],
    },
  },
  "& .MuiRadio-root": {
    "& .MuiSvgIcon-root": {
      fill:red[500],
    },
  },
};

export function GetRegexMaskFromMask(mask) {
  //let sample = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  let rKeyboard = /[a-zA-Z0-9 ,./<>?;:"'`~!@#$%^&*()[\]{}_\-+=|\\]/;
  let rLetter = /[a-zA-Z]/;
  let rLetterOptional = /[a-zA-Z ]/;
  let rDigit = /[0-9]/;
  let rDigitOptional = /[0-9 ]/;
  let rLetterOrDigit = /[a-zA-Z0-9]/;
  let rLetterOrDigitOptional = /[a-zA-Z0-9 ]/;
  let regex = [];
  for (let i = 0; i < mask.length; i++) {
    switch (mask[i]) {
      case "&":
      regex.push(rKeyboard);
      break;
      case "C":
      regex.push(rKeyboard);
      break;
      case "L":
      regex.push(rLetter);
      break;
      case "?":
      regex.push(rLetterOptional);
      break;
      case "0":
      case "#":
      regex.push(rDigit);
      break;
      case "9":
      regex.push(rDigitOptional);
      break;
      case "A":
      regex.push(rLetterOrDigit);
      break;
      case "a":
      regex.push(rLetterOrDigitOptional);
      break;
      case ">":
      break;
      case "<":
      break;
      case "\\":
      if (mask.length - 1 > i) {
        i++;
        regex.push(mask[i]);
      }
      break;      
      default:
      regex.push(mask[i]);
      break;
    }
  }
  return regex;
}

export function GetRequiredPositionsFromMask(mask) {
  let required = [];
  for (let i = 0; i < mask.length; i++) {
    switch (mask[i]) {
      case "&":
      required.push(true);
      break;
      case "C":
      required.push(false);
      break;
      case "L":
      required.push(true);
      break;
      case "?":
      required.push(false);
      break;
      case "0":
      case "#":
      required.push(true);
      break;
      case "9":
      required.push(false);
      break;
      case "A":
      required.push(true);
      break;
      case "a":
      required.push(false);
      break;
      case ">":
      break;
      case "<":
      break;
      case "\\":
      if (mask.length - 1 > i) {
        i++;
        required.push(true);
      }
      break;      
      default:
      required.push(false);
      break;
    }
  }
  return required;
}

export function GetModifiersFromMask(mask) {
  let modifiers = [];
  for (let i = 0, p = 0; i < mask.length; i++, p++) {
    switch (mask[i]) {
      case ">":
      modifiers.push({position: p, modifier: ">"});
      p--;
      break;
      case "<":
      modifiers.push({position: p, modifier: "<"});
      p--;
      break;
      case "\\":
      if (mask.length - 1 > i) {
        i++;
      }
      break;      
      default:
      break;
    }
  }
  return modifiers;
}

export function GetStaticCharactersFromMask(mask) {
  let characters = [];
  for (let i = 0, p = 0; i < mask.length; i++, p++) {
    switch (mask[i]) {
      case ">":
      case "<":
      p--;
      break;
      case "\\":
      if (mask.length - 1 > i) {
        i++;
        characters.push({position: p, character: mask[i]})
      }
      break;      
      default:
      break;
    }
  }
  return characters;
}

export function GetTextMatchIsValid(field, value) {
  if (!field.TextMatchType) {
    return true;
  }
  if (!field.Required && !value.length) {
    return true;
  }
  switch (field.TextMatchType) {
    case "Email":
      return ValidateEmail(value);
    default:
      break;
  }
}

export function GetMaskedFieldValueIsValid(mask, value) {
    var regex = GetRegexMaskFromMask(mask);
    var required = GetRequiredPositionsFromMask(mask);
    for (let i = 0; i < required.length; i++) {
      if (required[i] && (!value || !value.substr(i, 1).match(regex[i]))) {
        return false;
      }
    }
    return true;
}

export const GetFieldForFilterAndSort = (ID, LabelOrName, Type, ID_for_filter_if_different,
  forcedOperators, forcedSelectionListPromiseFuncID, allowNewSelectionListItems, SortOnly,
  type_for_api_if_different, ID_for_workspace_filter_if_different, forceBasicTextBoxForWorkspace,
  forcedOperatorsForWorkspace, allowMultipleValues) => {

  let field = {
    ID,
    FilterID: ID_for_filter_if_different,
    WorkspaceFilterID: ID_for_workspace_filter_if_different,
    API_Type: type_for_api_if_different,
    LabelOrName,
    Type: Type || "Text",
    Value: "",
    SortOnly,
  };
  if (field.Type === "Text" && (forcedSelectionListPromiseFuncID || allowMultipleValues)) {
    field.DisplaySelectionList = true;
    field.AllowMultipleValues = allowMultipleValues
    field.AllowNewSelectionListItems = allowNewSelectionListItems;
    field.ForcedSelectionListPromiseFuncID = forcedSelectionListPromiseFuncID;
    field.FormatCreateLabelPrefix = "Use";
  }

  field.ForcedOperators = forcedOperators;
  field.ForceBasicTextBoxForWorkspace = forceBasicTextBoxForWorkspace;
  field.ForcedOperatorsForWorkspace = forcedOperatorsForWorkspace;
  return field;
}