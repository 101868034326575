import React from 'react';

// DO NOT USE LOOK AHEAD OR LOOK BEHIND - SAFARI DOESN'T SUPPORT AS OF 04/2022

export function ValidateEmail(address) 
{
	// return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(address);
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(address);
}

export const GetTopPathSegment = path => {
  const r = new RegExp(/(\/[a-zA-Z0-9-]*)/);
  return path.match(r)[0];
}

export const GetDeepestPathSegment = path => {
  const r = new RegExp(/(\/[a-zA-Z0-9-]*)*((\/[a-zA-Z0-9-]+)(\/:[a-zA-z0-9-]*)?)+/);
  return path.match(r)[3];
}

export const GetPathWithoutParam = path => {
  const r = new RegExp(/(\/[a-zA-Z0-9-]+)+/);
  return path.match(r)[0];
}


// This could probably be better. Known issue... trailing period on URL is captured.
export const UrlRegEx = new RegExp(/((?:http[s]?|ftp):\/\/[^\s]+[^. ])/gi);

export const GetTextWithUrlsAsAnchors = (text, theme, linkText, maxLength) => {
	if (maxLength && text.length > maxLength) {
    text = text.substr(0, maxLength) + "...";
  }
  return text
		.split(UrlRegEx)
    .map(t => 
      (t.match(UrlRegEx))
        ? <span 
            key={`url_${Math.random() * 999999}`} 
            target="apc_urlFollow"
            style={{
              wordBreak:"break-all",
              color: theme.palette.type === "dark" ? "#aaa" : undefined,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={e => { e.stopPropagation(); window.open(t, "apc_urlFollow"); }}
            href={t}
          >
            {linkText || t}
          </span>
        : t
    );
}

export const GetTextWithUrlsAsRawAnchors = (text, theme, linkText, maxLength) => {
  if (maxLength && text.length > maxLength) {
    text = text.substr(0, maxLength) + "...";
  }
  return text
    .split(UrlRegEx)
    .map(t => 
      (t.match(UrlRegEx))
        ? `<a href="${t}">${linkText || t}</a>`
        : t
    )
    .join("");
}

// DO NOT USE LOOK AHEAD OR LOOK BEHIND - SAFARI DOESN'T SUPPORT AS OF 04/2022

const FullAnchorTagRegex = new RegExp(/(<a[\s]+[^>]+>(?:.(?!<\/a>))*.<\/a>)/gi);
const AnchorAttributesRegex = new RegExp(/<a[\s]+([^>]+)>(?:.(?!<\/a>))*.<\/a>/gi);
const AnchorTextRegex = new RegExp(/<a[\s]+[^>]+>((?:.(?!<\/a>))*.)<\/a>/gi);
const HrefValueRegex = new RegExp(/(href=")(.*)(")/i);

export const GetBodyHtmlWithModifiedAnchors = (bodyHtml, theme, openInNewWindow) => {
  let newBodyHtml = "";
  bodyHtml
    .split(FullAnchorTagRegex)
    .forEach(t => {
      if (t.match(FullAnchorTagRegex)) {
        const attMatches = [...t.matchAll(AnchorAttributesRegex)];
        if (attMatches.length > 0) {
          let href, linkText;
          for (let i = 0; i < attMatches[0].length; i++) {
            const att = attMatches[0][i];
            if (att.startsWith("href=")) {
              // console.log("href is", attMatches[0][i]);
              const hrefRaw = attMatches[0][i];
              const hrefMatch = hrefRaw.match(HrefValueRegex);
              if (hrefMatch.length === 4) {
                href = hrefMatch[2];
              }
              break;
            }
          }
          if (href) {
            const textMatches = [...t.matchAll(AnchorTextRegex)];
            if (textMatches.length > 0 && textMatches[0].length > 1) {
              // console.log("text is", textMatches[0][1]);
              linkText = textMatches[0][1];
              // console.log(href, linkText);
              // word-break:break-all; 
              newBodyHtml += `<span target="apc_urlFollow" style="color:${theme.palette.type === "dark" ? "#aaa" : "inherit"}; text-decoration:underline; cursor:pointer;"onClick="event.stopPropagation(), ${(openInNewWindow) ? "window.open('" + href + "', 'apc_urlFollow')" : "window.location.href='" + href + "'"}">${linkText}</span>`;
            }
          } else {
            newBodyHtml += t;
          }
        } else {
          newBodyHtml += t;
        }
      } else {
        newBodyHtml += t;
      }
    });
    return newBodyHtml;
}

// DO NOT USE LOOK AHEAD OR LOOK BEHIND - SAFARI DOESN'T SUPPORT AS OF 04/2022