import dateformat from 'dateformat';

export const GetNewExpense = () => {
	return {
		Expense: {
			VendorName: "",
			ReceiptDate: dateformat(new Date(), "yyyy-mm-dd"),
			TaxTotal: NaN,
			LineItems: [{
				Description: "",
				LineAmount: NaN,
			}],
			PrivateNote: "",
		},
	};
}

export const GetSubTotal = expense => {
	return (expense && expense.LineItems)
  	? expense.LineItems
    	.map(li => li.LineAmount)
    	.reduce((partial, amt) => partial + (amt || 0), 0)
  	: NaN;
}

export const GetTotal = expense => {
	return (expense)
    ? (GetSubTotal(expense) || 0) + (expense.TaxTotal || 0)
    : NaN;
}