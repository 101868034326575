import React from 'react';
import {
  BrowserRouter,
  Route,
  Switch, 
  // Redirect, 
  useLocation,
} from 'react-router-dom';

import TitleComponent from './Components/TitleComponent';
import Signin from './Screens/Signin';
import Signout from './Screens/Signout';
import NotFound404 from './Screens/NotFound404';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import EULA from './Screens/EULA';
import Licenses from './Screens/Licenses';
import Root from './Screens/Root';
import Dashboard from './Screens/Dashboard';
import UserProfile from './Screens/UserProfile';
import Billing from './Screens/Billing';
import Help from './Screens/Help';
import Settings from './Screens/Settings';
import ExpenseCapture from './Screens/ExpenseCapture';
import ExpenseReview from './Screens/ExpenseReview';
import ExpenseApproval from './Screens/ExpenseApproval';
import ExpenseHistory from './Screens/ExpenseHistory';
import PartnerDashboard from './Screens/PartnerDashboard';

// import UserApiKeys from './Screens/UserApiKeys';
import IntuitSignIn from './Screens/IntuitSignIn';
import QuickBooksDisconnect from './Screens/QuickBooksDisconnect';

// import Test from './Screens/test';

import {
  GetMuiTheme,
} from './Util/Theme';

// import SupportStats from './Screens/Stats';
import SupportCompanies from './Screens/SupportCompanies';
// import SupportOrganizationDetail from './Screens/OrganizationDetail';
// import SupportOrganizationsRequiringSubscription from './Screens/OrganizationsRequiringSubscription';
// import SupportUsers from './Screens/Users';
import SupportUserActivity from './Screens/UserActivity';
import SupportErrorEvents from './Screens/ErrorEvents';
import GlobalContextProvider from './Context/Global.context';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { withCookies } from 'react-cookie';
// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
// import { DndProvider } from 'react-dnd-multi-backend';
// import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import {loadStripe} from '@stripe/stripe-js';
import {
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
// import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import './App.css';

// const Authentication = {
//   // rootRedirectRequested(cookies) {
//   //   let c = cookies.get("rootRedirect");
//   //   if (c !== undefined && c === "true") {
//   //     // Remove cookie
//   //     cookies.remove("rootRedirect");
//   //     return true;
//   //   }
//   //   return false;
//   // },
//   isAuthenticated(cookies) {
//     let c = cookies.get("apcentralsession_v1");
//     return c !== undefined;
//   },
// }

// const withTitle = ({ component: Component, title }) => {
//   return class Title extends React.Component {
//     render() {
//       return (
//         <React.Fragment>
//           <CssBaseline />
//           <TitleComponent title={title} />
//           <Component {...this.props} />
//         </React.Fragment>
//       );
//     }
//   };
// };

const withTitleAndTheme = ({ component: Component, title }) => {
  return class Title extends React.Component {
    render() {
      return (
        <MuiThemeProvider theme={GetMuiTheme(this.props.cookies)}>
          <CssBaseline />
          <TitleComponent title={title} />
          <Component {...this.props} />
        </MuiThemeProvider>
      );
    }
  };
};

const withTheme = ({ component: Component }) => {
  return class ThemedComponent extends React.Component {
    render() {
      return (
        <MuiThemeProvider theme={GetMuiTheme(this.props.cookies)}>
          <CssBaseline />
          <Component {...this.props} />
        </MuiThemeProvider>
      );
    }
  };
};

const ProtectedRoute = withCookies(({ component: Component, render, cookies, ...rest }) => {
  return (<Route {...rest}
    render={
      (props) => {
        // if (Authentication.rootRedirectRequested(cookies) === true) {
        //   console.log("redirecting");
        //   return (
        //     <Redirect to={{
        //       pathname: '/',
        //       state: { from: props.location }
        //     }} />
        //   );
        // }
        // else {
          // if (Authentication.isAuthenticated(cookies) === true) {
            // console.log("isAuthenticated");
            return (
              <GlobalContextProvider>
                {
                  (render)
                    ? render({...props, cookies})
                    : <Component cookies={cookies} {...props} />
                }
              </GlobalContextProvider>
            );
          // } else {
          //   // console.log("login redirect");
          //   return (
          //     <Redirect to={{
          //       pathname: '/signin',
          //       state: { 
          //         from: props.location, 
          //       }
          //     }} />
          //   );
          // }
        // }
      }
    } 
  />)
  }
);

const PublicRoute = withCookies(({ component: Component, cookies, ...rest }) =>
  <Route {...rest}
    render={
      (props) => {
        return (
          <Component cookies={cookies} {...props} />
        );
      }
    }
  />
);

function CustomEventRouterLocationChanged() {
  const location = useLocation();

  // Listen for router location change events
  React.useEffect(
    () => {
      window.dispatchEvent(
        new CustomEvent('routerLocationChanged', {
          detail: { pathname: location.pathname }
        }));
    },
    [location]
  );

  // This component renders no content
  return null;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const options = {
  mode: 'payment',
  amount: 1099,
  currency: 'usd',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

const BillingComponent = withTitleAndTheme({
  component: props => {
    return (
      <Elements stripe={stripePromise} options={options}>
        <ElementsConsumer>
          {({stripe, elements}) => (
            <Billing {...props} stripe={stripe} elements={elements} />
          )}
          
        </ElementsConsumer>
      </Elements>
    );
  },
  title: "Billing",
});

class App extends React.Component {
  render() {
    // Live chat - hidden by default
    const script1 = document.createElement("script");
    script1.id = "liveChat1";
    const script1Body = document.createTextNode(`var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siq0c28e03a39bb4e09f77fe92030c3ac493dcfbd280bc944638cef87389c1dd725", values:{},ready:function(){$zoho.salesiq.floatbutton.visible("hide");}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`);
    script1.appendChild(script1Body);
    document.head.appendChild(script1);

    if (process.env.REACT_APP_DEPLOYMENT_TYPE === "production") {
      // <!-- Matomo tag -->
      const script2 = document.createElement("script");
      script2.id = "matomo1";
      const script2Body = document.createTextNode(`var _mtm = window._mtm = window._mtm || []; _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'}); (function() { var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0]; g.async=true; g.src='https://cdn.matomo.cloud/nucleusone.matomo.cloud/container_fLhspPWE.js'; s.parentNode.insertBefore(g,s); })();`);
      //`var _paq = window._paq = window._paq || []; /* tracker methods like "setCustomDimension" should be called before "trackPageView" */ _paq.push(['trackPageView']); _paq.push(['enableLinkTracking']); (function() { var u="https://nucleusone.matomo.cloud/"; _paq.push(['setTrackerUrl', u+'matomo.php']); _paq.push(['setSiteId', '13']); var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0]; g.async=true; g.src='https://cdn.matomo.cloud/nucleusone.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s); })();`
      script2.appendChild(script2Body);
      document.head.appendChild(script2);

    //   // <!-- Google tag (gtag.js) -->
    //   const script1 = document.createElement("script");
    //   script1.id = "ga1";
    //   script1.async = true;
    //   script1.src = "https://www.googletagmanager.com/gtag/js?id=G-J8M4GFZ1BP";
    //   document.head.appendChild(script1);
    //   const script2 = document.createElement("script");
    //   script2.id = "ga2";
    //   const script2Body = document.createTextNode("window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-J8M4GFZ1BP');");
    //   script2.appendChild(script2Body);
    //   document.head.appendChild(script2);
    }

    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENTID}>
        {/* <DndProvider options={HTML5toTouch}> */}
        <BrowserRouter>
          <CustomEventRouterLocationChanged />
          <Switch>
            <ProtectedRoute exact path={"/"} component={withTitleAndTheme({ component: Root })} />
            <ProtectedRoute exact path={"/user/profile"} component={withTitleAndTheme({ component: UserProfile, title: "User Profile" })} />
            <ProtectedRoute exact path={"/dashboard"} component={withTitleAndTheme({ component: Dashboard, title:"Dashboard" })} />
            <ProtectedRoute exact path={"/billing"} component={BillingComponent} />
            <ProtectedRoute exact path={"/help"} component={withTitleAndTheme({ component: Help, title:"Help" })} />
            <ProtectedRoute exact path={"/settings"} component={withTitleAndTheme({ component: Settings, title:"Settings" })} />

            <ProtectedRoute exact path={"/expenses/capture"} component={withTitleAndTheme({ component: ExpenseCapture, title:"Expense Capture" })} />
            <ProtectedRoute exact path={"/expenses/review"} component={withTitleAndTheme({ component: ExpenseReview, title:"Expense Review" })} />
            <ProtectedRoute exact path={"/expenses/approval"} component={withTitleAndTheme({ component: ExpenseApproval, title:"Expense Approval" })} />
            <ProtectedRoute exact path={"/expenses/history"} component={withTitleAndTheme({ component: ExpenseHistory, title:"Expense History" })} />
            
            <ProtectedRoute exact path={"/partner/billing"} component={BillingComponent} />
            <ProtectedRoute exact path={"/partner/dashboard"} component={withTitleAndTheme({ component: PartnerDashboard, title:"Dashboard" })} />

            {/*<ProtectedRoute
              path={[
                "/user/apiKeys/:collectionItemID",
                "/user/apiKeys",
              ]}
              component={withTitleAndTheme({ component: UserApiKeys, title: "API Keys" })}
            />*/}
          
            <PublicRoute exact path={"/signin/intuit"} component={withTitleAndTheme({ component: IntuitSignIn, title: "Intuit sign in" })} />
            <PublicRoute exact path={"/signin/:route"} component={withTitleAndTheme({ component: Signin, title: "Sign in" })} />
            <PublicRoute exact path={"/signin"} component={withTitleAndTheme({ component: Signin, title: "Sign in" })} />
            <PublicRoute path={"/quickbooks/disconnect"} component={withTitleAndTheme({ component: QuickBooksDisconnect, title: "QuickBooks Disconnect" })} />

            {/*<ProtectedRoute path={"/support/stats"} component={withTitleAndTheme({ component: SupportStats, title: "Stats" })} />*/}
            {/* <ProtectedRoute path={"/support/companies/:companyID"} component={withTitleAndTheme({ component: SupportCompanyDetail, title: "Company Detail" })} /> */}
            <ProtectedRoute path={"/support/companies"} component={withTitleAndTheme({ component: SupportCompanies, title: "Companies" })} />
            {/*<ProtectedRoute path={"/support/users"} component={withTitleAndTheme({ component: SupportUsers, title: "Users" })} />*/}
            <ProtectedRoute path={"/support/userActivity"} component={withTitleAndTheme({ component: SupportUserActivity, title: "User Activity" })} />
            <ProtectedRoute path={"/support/logs"} component={withTitleAndTheme({ component: SupportErrorEvents, title: "Logs" })} />
            
            <ProtectedRoute path={"/signout"} component={Signout} />
            
            {/*<PublicRoute path={"/test"} component={withTheme({ component: Test })} />*/}

            <PublicRoute path={"/privacy"} component={withTitleAndTheme({ component: PrivacyPolicy, title: "Privacy Policy" })} />
            <PublicRoute path={"/eula"} component={withTitleAndTheme({ component: EULA, title: "EULA" })} />
            <PublicRoute path={"/licenses"} component={withTitleAndTheme({ component: Licenses, title: "Licenses" })} />

            <PublicRoute component={withTheme({ component: NotFound404 })} />
          </Switch>
        </BrowserRouter>
      {/* </DndProvider> */}
      </GoogleOAuthProvider>
    );
  }
}

export default App;