export function BytesToSize(bytes) {
	var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes === 0) return '0 Byte';
	var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	return NumberWithSeparators(Math.round(bytes / Math.pow(1024, i), 2)) + ' ' + sizes[i];
}

export function NumberWithSeparators(number, decimalPrecision) {
	if (number === undefined || number === null) {
        return "";
    }
	const numString = (decimalPrecision === undefined || decimalPrecision === null)
		? number.toString()
		: number.toFixed(decimalPrecision)
	return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function CurrencyWithSeparators(number) {
	const numString = NumberWithSeparators(number, 2);
    return ((numString === "") ? "" : "$")
		+ numString;
}
