import React, {Component} from 'react';

import ExpenseViewer from '../Components/ExpenseViewer';

import API, {
  GetExpenseReviewsPathForApi,
  GetExpenseReviewPathForApi,
} from '../Util/api';
import { GlobalContext } from '../Context/Global.context';

class ExpenseReview extends Component {
  static contextType = GlobalContext;
  
  constructor(props) {
    super(props);
    
    this.state = {
    }
  }

  handleLoadSingleExpense = employeeId => {
    const params = {
      pageSize: 1,
    }
    return API.get(GetExpenseReviewsPathForApi(employeeId || this.context.EmployeeID), { params });
  }

  handleSubmitExpense = expense => {
    return API.post(GetExpenseReviewPathForApi(this.context.EmployeeID, expense.ID), expense);
  }

  handleDeleteExpense = expense => {
    return API.delete(GetExpenseReviewPathForApi(this.context.EmployeeID, expense.ID));
  }

  render() {
    return (
      <ExpenseViewer
        dialogTitle="Expense Review"
        allowEmployeeChange
        onLoadSingleExpense={this.handleLoadSingleExpense}
        onSubmitExpense={this.handleSubmitExpense}
        onDeleteExpense={this.handleDeleteExpense}
        showFabOnMobileForReceiptCapture
      />
    );
  }
}

export default ExpenseReview;
