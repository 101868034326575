import React from 'react';
import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
// import Tooltip from '@material-ui/core/Tooltip';
// import Collapse from '@material-ui/core/Collapse';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Menu from '@material-ui/core/Menu';
import Fab from '@material-ui/core/Fab';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

// import GetHelpIcon from '@material-ui/icons/HelpOutline';
// import LightModeIcon from '@material-ui/icons/Brightness7';
// import DarkModeIcon from '@material-ui/icons/Brightness4';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
// import HomeIcon from '@material-ui/icons/Home';
import CompanyIcon from '@material-ui/icons/Domain';
// import UserIcon from '@material-ui/icons/Person';
import SubscriptionsIcon from '@material-ui/icons/Payment';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import StatsIcon from '@material-ui/icons/Assessment';
import UserProfileIcon from '@material-ui/icons/AccountCircle';
// import EditIcon from '@material-ui/icons/Edit';
import UsersIcon from '@material-ui/icons/Group';
import UserActivityIcon from '@material-ui/icons/Timeline';
import ErrorEventsIcon from '@material-ui/icons/Error';
// import { SupportIcon } from '../Util/Icons';
// import CloseIcon from '@material-ui/icons/Close';

import CaptureIcon from '@material-ui/icons/Receipt';
import ReviewIcon from '@material-ui/icons/Pageview';
import ApprovalIcon from '@material-ui/icons/ThumbsUpDown';
import HistoryIcon from '@material-ui/icons/History';

import CollapsibleLeftPane from '../Components/CollapsibleLeftPane';
import MultiUseDialog from './MultiUseDialog';
import ProgressIndicator from '../Components/ProgressIndicator';
import { GlobalContext } from '../Context/Global.context';
import { IsMobile } from '../Util/MobileDetector';
// import {
//   GetDarkMode,
//   CycleTheme,
// } from '../Util/Theme';
import {
  GetSignInPath,
  GetHomePath,
  GetUserProfilePath,

  GetDashboardPath,
  GetBillingPath,
  GetSettingsPath,
  GetHelpPath,

  // GetPartnerDashboardPath,
  // GetPartnerBillingPath,

  GetReceiptCapturePath,
  GetExpenseReviewPath,
  GetExpenseApprovalPath,
  GetExpenseHistoryPath,
  
  GetSupportStatsPath,
  GetSupportCompaniesPath,
  GetSupportUsersPath,
  GetSupportUserActivityPath,
  GetSupportErrorEventsPath,
} from '../Util/PathHelper';

import {
  SignOut,
} from '../Util/SignOut';
import {
  HandleSignInAndConnect,
} from '../Util/QuickBooks';

import API, {
  GetUserCompanyPathForApi,
} from '../Util/api';

import {
  ExpenseCaptureCore,
  GetNewReservationParams,
} from '../Screens/ExpenseCapture';

import red from '@material-ui/core/colors/red';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    display:"flex",
    flexDirection:"column",
    overflow:"hidden",
  },
  appBar: {
    zIndex:1249,//1300,
    backgroundColor: theme.palette.background.default,
    display:"flex",
    textAlign:"right",
    padding: (IsMobile()) ? 0 : theme.spacing(1),
    paddingRight: (IsMobile()) ? 0 : theme.spacing(3),
  },
  navDrawerPaper: {
    minWidth: 250,
    maxWidth: 300,
  },
  nestedNavDrawerList: {
    paddingLeft: theme.spacing(3),
    paddingTop:0,
    paddingBottom:0,
  },
  navList: {
    "& .MuiTypography-root": {
      fontSize:24
    },
  },
  navListItemRoot: {
    justifyContent:"center",
  },
  navListItemTextRoot: {
    "& .MuiTypography-root": {
      fontWeight: "500 !important",
      color: (!IsMobile()) ? "white" : undefined,
    },
  },
  navListItemSelected: {
    backgroundColor: `${theme.palette.background.default} !important`,
    "& .MuiTypography-root": {
      color: theme.palette.secondary.main,
    },
  },
  navListItemContent: {
    display:"flex",
    position:"relative",
  },
  notificationDot: {
    width:8,
    height:8,
    borderRadius:16,
    backgroundColor:red[500],
    position: "absolute",
    right:-8,
    top:14,
  },
  navPaneAndTheRest: {
    display:"flex",
    width:"100%",
    height:(IsMobile()) ? 0 : "100%",
    flexGrow:1,
  },
  theRest: {
    flexGrow:1,
    height:"100%",
    display:"flex",
    flexDirection:"column",
    overflowX:"auto",
  },
  screenContent: {
    position: "relative", // For ProgressIndicator.constrained
    flexGrow:1,
    display:"flex",
    flexDirection:"column",
    height:"100%",
    marginTop:(IsMobile()) ? theme.spacing(3) : theme.spacing(9),
    overflowY:"auto",
    padding:(IsMobile()) ? theme.spacing(3) : theme.spacing(4),
    paddingTop:0,
    paddingBottom:0,
  },
  userMenu: {
    alignSelf:"center",
    marginRight: (IsMobile()) ? theme.spacing(2) : 0,
    // marginRight: -6,
  },
  navDrawerButton: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(2),
  },
  fab: {
    zIndex: 1,
    bottom: theme.spacing(3.5),
    alignSelf: "center",
    position: "absolute",
  },
});

class UiCore extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      auth: true,
      anchorEl: null,
      OtherAppBarContent: null,
      NavDrawerOpen: false,
      DialogDetails: {
        Open: false,
      },
      SupportMenu_MouseX: null,
      SupportMenu_MouseY: null,
      SupportMenuOpen: false,
      ShowProgressIndicator: false,
      ShowProgressIndicatorAfter2s: false,
      ShowProgressIndicatorImmediately: false,
      OrganizationMembers: new Date(),
    };

    this.endOfSupportMenuRef = React.createRef();
    this.ScreenContentRef = React.createRef();

    this.WindowIsFocused = true;
    this.AutoRefreshIntervalID = null;
    this.AutoRefreshIntervalMS = 5000;

    this.BeginFileUploadFunc = () => {};
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleSignOut = event => {
    this.handleAppBarMenuClose();

    SignOut(this.props);
  };

  handleAppBarMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  toggleNavDrawer = (open) => () => {
    this.setState({
      NavDrawerOpen: open,
    });
  };

  handleAlert = Alert => {
    this.handleShowDialog(Alert);
  }

  handleApiError = (err, title_optional) => {
  	if (!err.response) {
      err = {
  			response: {
  				status: 500,
  				data: {
            message: err.toString()
          }
  			}
  		};
  	}

    switch(err.response.status) {
      case 401:
      // 401: Unauthorized
      this.props.history.push(
        GetSignInPath(),
        { from: this.props.location },
      );
      break;
      case 402:
      // 402: Payment Required
      // We use this status code to indicate the subscription for the active project is expired.
      // let src = this.props.cookies.get("subscriptionRedirect");
      // if (src !== undefined) {
      //   // Remove cookie
      //   this.props.cookies.remove("subscriptionRedirect");
      //   this.props.history.push(GetOrganizationSubscriptionPath(src));
      // }
      // src = this.props.cookies.get("expiredSubscriptionRedirect");
      // if (src !== undefined) {
      //   // Remove cookie
      //   this.props.cookies.remove("expiredSubscriptionRedirect");
      //   this.props.history.push(`/expiredSubscriptions/${src}`);
      // }
      break;
      case 403:
      // 403: Forbidden
      // If there's a cookie to redirect, avoid delivering error
      let rrc = this.props.cookies.get("rootRedirect");
      if (rrc !== undefined && rrc === "true") {
        // Remove cookie
        this.props.cookies.remove("rootRedirect");
        this.context.Reset();
        this.props.history.push("/");
      } else {
        let noAccessDialogDetails = {
          Title: title_optional || "Sorry, you do not have access to this item.",
          BodyText: (err.response.data.message) ? err.response.data.message : undefined,
        }
        if (err.closeCallback !== undefined) {
          noAccessDialogDetails.CloseCallback = err.closeCallback;
        }
        this.handleShowDialog(noAccessDialogDetails);
      }
      break;
      case 418:
        // This status is pushed by the server in an attempt to let the client figure things out.
      break;
      case 404:
      case 410:
      case 500:
      default:
      // 500: Internal Server Error
      let dialogDetails = {
        Title: title_optional || "Sorry, something went wrong...",
        BodyText: (err.response.data.message) ? err.response.data.message : "Please try again.",
      }
      if (err.closeCallback !== undefined) {
        dialogDetails.CloseCallback = err.closeCallback;
      }
      this.handleShowDialog(dialogDetails);
      break;
    }

    this.setState({
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    });
  }

  handleShowDialog = details => {
    if (!details) {
      this.setState({DialogDetails: { Open: false }});
      return;
    }
    this.setState({
      DialogDetails: {
        Open: (details.Closed) ? false : new Date(),
        IsConfirmation: details.IsConfirmation,
        RequireTextInput1: details.RequireTextInput1,
        DialogWidth: details.DialogWidth,
        FullWidth: details.FullWidth,
        Title: details.Title,
        BodyText: details.BodyText,
        BodyContent: details.BodyContent,
        BodyClassName: details.BodyClassName,
        TextInput1Label: details.TextInput1Label,
        TextInput1PlaceHolder: details.TextInput1PlaceHolder,
        TextInput1DefaultValue: details.TextInput1DefaultValue,
        CancelCallback: (details.CancelCallback) ? details.CancelCallback : () => {},
        CloseLabel: details.CloseLabel,
        CloseCallback: (details.CloseCallback) ? details.CloseCallback : () => {},
        DisableBlurClose: (details.DisableBlurClose) ? details.DisableBlurClose : false,
        ConfirmLabel: details.ConfirmLabel,
        ConfirmCallback: (details.ConfirmCallback) ? details.ConfirmCallback : () => {},
      }
    });
  }

  handleCloseDialog = () => {
    this.setState({ DialogDetails: {...this.state.DialogDetails, Open: false, }, });
  }

  handleSetSupportMenuVisibility = visible => event => {
    this.setState({
      SupportMenu_MouseY: (visible) ? event.clientY : null,
      SupportMenu_MouseX: (visible) ? event.clientX : null,
    });
  }

  handleSupportMenuClick = id => event => {
    this.handleSetSupportMenuVisibility(false)();
    switch (id) {
      case "stats":
        this.props.history.push(GetSupportStatsPath());
        break;
      case "organizations":
        this.props.history.push(GetSupportCompaniesPath());
        break;
      case "users":
        this.props.history.push(GetSupportUsersPath());
        break;
      case "userActivity":
        this.props.history.push(GetSupportUserActivityPath());
        break;
      case "errorEvents":
        this.props.history.push(GetSupportErrorEventsPath());
        break;
      default:
        break;
    }
  }

  handleNavClick = path => e => {
    this.props.history.push(path);
  }

  handleThemeCycle = () => {
    this.handleAppBarMenuClose();
    // CycleTheme(this.props.cookies);
  }

  handleRouteTo = (uri, windowOpenTarget) => {
    if (!uri) {
      return;
    }
    this.setState({NavDrawerOpen:false});
    if (this.props.location.pathname.startsWith(uri)) {
      return;
    } else {
      if (windowOpenTarget) {
        window.open(uri, windowOpenTarget);
      } else {
        this.props.history.push(uri);
      }
    }
  }

  handleRouteToUserProfile = () => {
    this.props.history.push(GetUserProfilePath());
    this.handleAppBarMenuClose();
  }

  handleAddQuickBooksCompany = () => {
    HandleSignInAndConnect();
  }

  handleSwitchCompany = userCompany => {
    this.setState({ShowProgressIndicatorImmediately:true});
    this.handleAppBarMenuClose();

    if (userCompany.CompanyIntegrationType === "QuickBooks" && userCompany.IsCompanyAdmin) {
      HandleSignInAndConnect()
    } else {
      API.post(GetUserCompanyPathForApi(userCompany.CompanyID))
        .then(resp => {
          userCompany = resp.data;
          this.context.Reset();
          const homePath = GetHomePath(userCompany.IsCompanyAdmin);
          if (this.props.location.pathname === homePath) {
            window.location.reload();
          } else {
            this.props.history.push(homePath);
          }
        })
        .catch(this.handleApiError)
        .finally(() => {
          this.setState({ShowProgressIndicatorImmediately:false});
        });
    }
  }

  handleSetOtherAppBarContent = OtherAppBarContent => {
    this.setState({OtherAppBarContent});
  }

  doAutoRefresh = source => {
    if (!this.context.CompletedGET.User || !this.context.EmployeeID) {
      setTimeout(() => this.doAutoRefresh(source), 250);
      return;
    }

    const cycle = () => {
      this.AutoRefreshIntervalID = setTimeout(() => this.doAutoRefresh("doAutoRefresh"), this.AutoRefreshIntervalMS)
    };

    if (this.WindowIsFocused && this.AutoRefreshIntervalID !== null) {
      this.context.GetExpenseInfo()
        .then(expenseInfo => {
          if (this.props.funcForAutoRefresh) {
            this.props.funcForAutoRefresh(expenseInfo);
          }
          cycle();
        })
        .catch(err => {
          // Do nothing - there will be another cycle
        });
    } else if (this.AutoRefreshIntervalID === null) {
      this.context.GetExpenseInfo()
        .catch(this.handleApiError)
        .finally(() => {
          cycle();
        });
    } else {
      cycle();
    }
  }

  stopAutoRefresh = () => {
    if (this.AutoRefreshIntervalID) {
      clearTimeout(this.AutoRefreshIntervalID);
      this.AutoRefreshIntervalID = null;
    }
  }

  handleResetAutoRefresh = () => {
    this.stopAutoRefresh();
    this.doAutoRefresh("handleResetAutoRefresh");
  }

  handleWindowFocused = focused => {
    this.WindowIsFocused = true;
  }

  handleWindowBlured = () => {
    this.WindowIsFocused = false;
  }

  handleCompleteExpenseUpload = reservation => {
    this.props.history.push(GetReceiptCapturePath(), {
      initialReservation: reservation,
    });
  }

  componentDidMount() {
    window.addEventListener('blur', this.handleWindowBlured);
    window.addEventListener('focus', this.handleWindowFocused);

    this.doAutoRefresh("componentDidMount");

    if (this.props.forcedApiError) {
      this.handleApiError(this.props.forcedApiError, this.props.apiErrorTitle);
    }
    if (typeof this.props.showProgressIndicator !== "undefined") {
      this.setState({ShowProgressIndicator: this.props.showProgressIndicator});
    }
    if (typeof this.props.showProgressIndicatorImmediately !== "undefined") {
      this.setState({ShowProgressIndicatorImmediately: this.props.showProgressIndicatorImmediately});
    }

    if (this.props.onSetFuncToSetOtherAppBarContent) {
      this.props.onSetFuncToSetOtherAppBarContent(this.handleSetOtherAppBarContent);
    }
    if (this.props.setScreenContentRef) {
      this.props.setScreenContentRef(this.ScreenContentRef);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('blur', this.handleWindowBlured);
    window.removeEventListener('focus', this.handleWindowFocused);

    this.stopAutoRefresh();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.apiError !== this.props.apiError) {
      if (this.props.apiError) {
        this.handleApiError(this.props.apiError, this.props.apiErrorTitle);
      }
    }
    if (prevProps.alert !== this.props.alert) {
      this.handleShowDialog(this.props.alert);
    }
    if (prevProps.confirmation !== this.props.confirmation) {
      this.handleShowDialog(Object.assign(this.props.confirmation, { IsConfirmation: true }));
    }
    if (prevProps.showProgressIndicatorImmediately !== this.props.showProgressIndicatorImmediately) {
      let showProgressIndicator = (this.props.showProgressIndicatorImmediately === true);
      this.setState({ShowProgressIndicator: showProgressIndicator, ShowProgressIndicatorImmediately: this.props.showProgressIndicatorImmediately});
    }
    if (prevProps.showProgressIndicatorAfter2s !== this.props.showProgressIndicatorAfter2s) {
      let showProgressIndicator = (this.props.showProgressIndicatorAfter2s === true);
      this.setState({ShowProgressIndicator: showProgressIndicator, ShowProgressIndicatorAfter2s: this.props.showProgressIndicatorAfter2s});
    }
    if (prevProps.showProgressIndicator !== this.props.showProgressIndicator) {
      this.setState({ShowProgressIndicator: this.props.showProgressIndicator, ShowProgressIndicatorImmediately: false});
    }
  }

  render() {
    const {
      history,
      location,
      cookies,
      classes,
      theme,
      content,
      title,
      constrainedProgressIndicator,
      showFabOnMobileForReceiptCapture,
    } = this.props;
    const { 
      anchorEl,
      NavDrawerOpen,
      DialogDetails,
      // SupportMenuOpen,
      SupportMenu_MouseX,
      SupportMenu_MouseY,
      ShowProgressIndicator,
      ShowProgressIndicatorAfter2s,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      User,
      UserCompanies,
      EmployeeID,
    } = this.context;

//     const supportAdminListContent = (User && User.IsSupportAdmin)
//       ? (
//         <React.Fragment>
//           <Divider />
//           <ListItem button onClick={() => {
//             let supportMenuOpen = !SupportMenuOpen;
//             this.setState({SupportMenuOpen: supportMenuOpen});
//             setTimeout(() => { if (supportMenuOpen) { this.endOfSupportMenuRef.scrollIntoView({behavior:"smooth"}); }}, 250);
//           }}>
//             <ListItemIcon>
//               <SupportIcon />
//             </ListItemIcon>
//             <ListItemText primary="Support" />
//             {SupportMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//           </ListItem>
//           <Collapse in={SupportMenuOpen} timeout="auto" unmountOnExit>
//             <List className={classes.nestedNavDrawerList}>
// 
//               <ListItem button onClick={this.handleSupportMenuClick("stats")}>
//                 <ListItemIcon>
//                   <StatsIcon />
//                 </ListItemIcon>
//                 <ListItemText primary="Stats" />
//               </ListItem>
//               
//               <ListItem button onClick={this.handleSupportMenuClick("organizations")}>
//                 <ListItemIcon>
//                   <CompanyIcon />
//                 </ListItemIcon>
//                 <ListItemText primary="Organizations" />
//               </ListItem>
// 
//               <ListItem button onClick={this.handleSupportMenuClick("users")}>
//                 <ListItemIcon>
//                   <UsersIcon />
//                 </ListItemIcon>
//                 <ListItemText primary="Users" />
//               </ListItem>
// 
//               <ListItem button onClick={this.handleSupportMenuClick("userActivity")}>
//                 <ListItemIcon>
//                   <UserActivityIcon />
//                 </ListItemIcon>
//                 <ListItemText primary="User Activity" />
//               </ListItem>
// 
//               <ListItem button onClick={this.handleSupportMenuClick("errorEvents")}>
//                 <ListItemIcon>
//                   <ErrorEventsIcon />
//                 </ListItemIcon>
//                 <ListItemText primary="Error Events" />
//               </ListItem>
// 
//               <span ref={instance => this.endOfSupportMenuRef = instance} />
//             </List>
//           </Collapse>
//         </React.Fragment>
//       ) : null;

    const getNotificationDot = (expenseInfoPropNames, style) => {
      let notificationDot;
      if (expenseInfoPropNames && expenseInfoPropNames.length && this.context.ExpenseInfo) {
        for (let i = 0; i < expenseInfoPropNames.length; i++) {
          if (this.context.ExpenseInfo[expenseInfoPropNames[i]]) {
            notificationDot = (<div className={classes.notificationDot} style={{...style}} />);
            break;
          }
        }
      }
      return notificationDot;
    }

    const getNavListItem = (path, title, expenseInfoPropNames) => {
      const selected = this.props.match.url === path;
      return (
        <ListItem
          key={`n_${title}`}
          button
          selected={selected}
          classes={{
            selected: classes.navListItemSelected,
            root: classes.navListItemRoot,
          }}
          onClick={this.handleNavClick(path)}
        >
          <div className={classes.navListItemContent}>
            <ListItemText
              primary={title}
              classes={{
                root: classes.navListItemTextRoot,
              }}
            />
            {getNotificationDot(expenseInfoPropNames)}
          </div>
        </ListItem>
      );
    }

    let navListItems = [];
    if (User) {
      if (User.IsCompanyAdmin) {
        const billingNavListItem = (!cookies.get("native_app_mode"))
          ? getNavListItem(GetBillingPath(), "Billing") : null;
        navListItems.push(
          getNavListItem(GetDashboardPath(), "Dashboard"),
          billingNavListItem,
          getNavListItem(GetSettingsPath(), "Settings"),
          getNavListItem(GetHelpPath(), "Help"),
        );
      }

      if (User.Employees.length) {
        if (navListItems.length) {
          navListItems.push(
            <Divider key="divider_expenses" style={{marginTop:theme.spacing(2)}} />,
            <div key="div_expenses" style={{
              marginTop:theme.spacing(4),
              marginBottom:theme.spacing(1),
              fontSize:10,
              fontWeight:500,
              textAlign:"center",
              color:(!IsMobile()) ? "white" : undefined,

            }}>
              EXPENSES
            </div>
          );
        }
        navListItems.push(
          getNavListItem(GetReceiptCapturePath(), "Capture"),
          getNavListItem(GetExpenseReviewPath(), "Review", ["HasPageProcessingItems", "HasReviewItems"]),
        );
      }
      if (User.Employees.length && !User.IsDemoCompany) {
        navListItems.push(
          getNavListItem(GetExpenseApprovalPath(), "Approval", ["HasApprovalItems"]),
        );
      }
      if (User.Employees.length) {
        navListItems.push(
          getNavListItem(GetExpenseHistoryPath(), "History"),
        );
      }
    }
    navListItems.push(
    );
    let navList;
    let navDrawerList;
    navList = (
      <List className={classes.navList}>
        {navListItems}
      </List>  
    );
    
    // case "Partner":
    //   navList = (
    //     <List className={classes.navList}>
    //       {getNavListItem(GetPartnerDashboardPath(), "Dashboard")}
    //       {getNavListItem(GetPartnerBillingPath(), "Billing")}
    //     </List>
    //   );
    //   break;
       
    navDrawerList = (
      <div key="k_nav_root">
        <Button onClick={() => this.handleRouteTo(GetDashboardPath())}
          style={{
            width:"100%",
            margin:0,
            marginTop:theme.spacing(2),
            marginBottom:theme.spacing(2),
          }}
        >
          <img style={{width:120}} src="/apcentral.png" alt="Dashboard" />
        </Button>

        <Divider />

        {navList}

        {/* {supportAdminListContent} */}
      </div>
    );

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately || ShowProgressIndicatorAfter2s) {
      progressIndicator = (
        <ProgressIndicator constrained={constrainedProgressIndicator}
          showImmediately={ShowProgressIndicatorImmediately} showAfter2s={ShowProgressIndicatorAfter2s} />
      );
    }

    const userMenuOpen = Boolean(anchorEl);
    const supportMenuItems = [
      <MenuItem key="support_stats" onClick={this.handleSupportMenuClick("stats")}>
        <ListItemIcon>
          <StatsIcon />
        </ListItemIcon>
        <ListItemText primary="Stats" />
      </MenuItem>,
      <MenuItem key="support_organizations" onClick={this.handleSupportMenuClick("organizations")}>
        <ListItemIcon>
          <CompanyIcon />
        </ListItemIcon>
        <ListItemText primary="Organizations" />
      </MenuItem>,
      <MenuItem key="support_organizationsRequiringSubscription" onClick={this.handleSupportMenuClick("organizationsRequiringSubscription")}>
        <ListItemIcon>
          <SubscriptionsIcon />
        </ListItemIcon>
        <ListItemText primary="Subscriptions" />
      </MenuItem>,
      <MenuItem key="support_users" onClick={this.handleSupportMenuClick("users")}>
        <ListItemIcon>
          <UsersIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </MenuItem>,
      <MenuItem key="support_userActivity" onClick={this.handleSupportMenuClick("userActivity")}>
        <ListItemIcon>
          <UserActivityIcon />
        </ListItemIcon>
        <ListItemText primary="User Activity" />
      </MenuItem>,
      <MenuItem key="support_errorEvents" onClick={this.handleSupportMenuClick("errorEvents")}>
        <ListItemIcon>
          <ErrorEventsIcon />
        </ListItemIcon>
        <ListItemText primary="Error Events" />
      </MenuItem>
    ];
    const supportMenu = (
      <Menu
        id="supportMenu"
        keepMounted
        open={SupportMenu_MouseY !== null}
        anchorReference="anchorPosition"
        anchorPosition={
          SupportMenu_MouseY !== null && SupportMenu_MouseX !== null
            ? { top: SupportMenu_MouseY, left: SupportMenu_MouseX }
            : undefined
        }
        onClose={this.handleSetSupportMenuVisibility(false)}
      >
        {supportMenuItems}
      </Menu>
    );

    // const supportTab = (User.IsSupportAdmin)
    //   ? (
    //     <Tab key="support" className={classes.railTab}
    //       label="Support"
    //       icon={
    //         <SupportIcon />
    //       }
    //       value={GetSupportPath()}
    //     />
    //   ) : null;

    // const manageOrgsTab = (
    //   <Tab key="manageOrgs" className={classes.railTab}
    //     style={{whiteSpace:"normal"}}
    //     label="Manage Orgs"
    //     icon={
    //       <CompanyIcon />
    //     }
    //     value={GetOrganizationManagementPath()}
    //   />
    // );

    // Placing a tab at the top of the list that matches the current location
    // prevents warnings when there is no real tab for the location 
    // const alwaysMatchesTab = (
    //   <Tab key="dummy" value={GetTopPathSegment(this.props.location.pathname)} style={{display:"none"}} />
    // );

    // const desktopNavRail = (!hideNavDrawer && !IsMobile())
    //   ? (
    //     <Tabs
    //       value={GetTopPathSegment(this.props.location.pathname)}
    //       className={classes.railTabs}
    //       variant="scrollable"
    //       orientation="vertical"
    //       onChange={this.handleRailTabChange}
    //     >
    //       {alwaysMatchesTab}
    //       {homeTab}

    //       <Tab key="growTab" disabled style={{
    //         flexGrow: 1,
    //       }} />

    //       {/*{resourcesTab}*/}
    //       {manageOrgsTab}
    //       {supportTab}
    //     </Tabs>
    //   ) : null;

    const mobileNavDrawer = (IsMobile())
      ? (
        <SwipeableDrawer
          classes={{ paper: classes.navDrawerPaper }}
          open={NavDrawerOpen}
          onClose={this.toggleNavDrawer(false)}
          onOpen={this.toggleNavDrawer(true)}
        >
          <div
            tabIndex={-1}
            role="button"
            // onClick={this.toggleNavDrawer(false)}
            // onKeyDown={this.toggleNavDrawer(false)}
          >
            {navDrawerList}
          </div>
        </SwipeableDrawer>
      ) : null;

    // const themeUserMenuItem = (!hideThemeSwitcher)
    //   ? (
    //     <MenuItem key="themeSwitcher" onClick={() => this.handleThemeCycle()}>
    //       <ListItemIcon>
    //         {GetDarkMode(cookies) ? <LightModeIcon /> : <DarkModeIcon /> }
    //       </ListItemIcon>
    //       {GetDarkMode(cookies) ? "Light Mode" : "Dark Mode" }
    //     </MenuItem>
    //   ) : null

    const mobileNavDrawerButton = (IsMobile())
      ? (
        <IconButton 
          className={classes.navDrawerButton}
          color="inherit" aria-label="Open drawer"
          onClick={this.toggleNavDrawer(true)}>
          <MenuIcon />
        </IconButton>
      ) : null;

    // const closeButton = (window.opener)
    //   ? (
    //     <IconButton 
    //       edge="end"
    //       color="inherit" aria-label="Close tab"
    //       onClick={() => window.close()}
    //     >
    //       <CloseIcon />
    //     </IconButton>
    //   ) : null;

    const mobileTitle = (IsMobile()) ? (
      <div style={{fontSize:20,fontWeight:500,alignSelf:"center"}}>
        {title}
      </div>
    ) : null;

    const bottomNavApprovalAction = (
      <BottomNavigationAction label="Approval" value={GetExpenseApprovalPath()}
        style={{
          visibility: !(User && User.Employees.length && !User.IsDemoCompany) ? "hidden" : undefined,
        }}
        icon={
          <div style={{position:"relative"}}>
            <ApprovalIcon />
            {getNotificationDot(["HasApprovalItems"], {top:0})}
          </div>
        }
      />
    );
    const mobileBottomNavigation = (IsMobile() && User && User.Employees.length) ? (
      <BottomNavigation
        value={this.props.match.url}
        onChange={(e, newPath) => this.handleNavClick(newPath)(e)}
        style={{
          borderTop: "1px solid #ccc",
          backgroundColor:theme.palette.background.default,
        }}
      >
        <BottomNavigationAction label="Capture" value={GetReceiptCapturePath()}
          icon={<CaptureIcon />}
        />
        <BottomNavigationAction label="Review" value={GetExpenseReviewPath()}
          icon={
            <div style={{position:"relative"}}>
              <ReviewIcon />
              {getNotificationDot(["HasPageProcessingItems","HasReviewItems"], {top:0})}
            </div>
          }
        />
        {bottomNavApprovalAction}
        <BottomNavigationAction label="History" value={GetExpenseHistoryPath()}
          icon={<HistoryIcon />}
        />
      </BottomNavigation>
    ) : null;

    const receiptCaptureFab = (IsMobile() && showFabOnMobileForReceiptCapture) ? (
      <Fab 
        color="secondary"
        aria-label="Add" 
        className={classes.fab}
        size="large"
        onClick={() => this.BeginFileUploadFunc()}
      >
        <AddIcon />
      </Fab>
    ) : null;

    let companyListItems = [];
    companyListItems.push(
      <ListItem key="currentCompany">
        <ListItemIcon>
          <CompanyIcon />
        </ListItemIcon>
        <div style={{display:"flex",width:"100%",alignItems:"center"}}>
          <div style={{flexGrow:1,fontWeight:500}}>
            {User && User.CompanyName}
          </div>
          <div>
            <CheckIcon color="primary"
              style={{
                fontSize:22,
                marginTop:-4,
                marginBottom:-4,
                marginLeft:theme.spacing(3),
              }}
            />
          </div>
        </div>
      </ListItem>
    );
    if (User && UserCompanies && UserCompanies.length) {
      UserCompanies.forEach(uc => {
        if (uc.CompanyID === User.CompanyID) {
          return;
        }
        companyListItems.push(
          <MenuItem key={`c_${uc.ID}`} onClick={() => this.handleSwitchCompany(uc)}>
            <ListItemIcon>
              <CompanyIcon />
            </ListItemIcon>
              {uc.CompanyName}
          </MenuItem>
        );
      });
    }
    companyListItems.push(
      <MenuItem key="addCompany" onClick={() => this.handleAddQuickBooksCompany()}>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
          Add QuickBooks company
      </MenuItem>
    );

    const appBarContent = (
      <div style={{display:"flex"}}>
        {mobileNavDrawerButton}
        {mobileTitle}
        <div className={classes.userMenu} style={{flexGrow:1}}>
          <IconButton
            aria-owns={userMenuOpen ? 'menu-appbar' : undefined}
            aria-haspopup="true"
            size="small"
            onClick={this.handleMenu}
            color="inherit"
          >
            <UserProfileIcon style={{fontSize:(IsMobile()) ? 36 : 48}} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={userMenuOpen}
            onClose={this.handleAppBarMenuClose}
          >
            <MenuItem onClick={this.handleRouteToUserProfile}>
              <ListItemIcon>
                <UserProfileIcon />
              </ListItemIcon>
              {User && User.Email}
            </MenuItem>
            <Divider style={{margin:4}} />
            {companyListItems}
            <Divider style={{margin:4}} />
            <MenuItem onClick={this.handleSignOut}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              Sign Out
            </MenuItem>
          </Menu>
        </div>
      </div>
    );

    let desktopNavPane = null;
    if (!IsMobile()) {
      desktopNavPane = (
        <CollapsibleLeftPane 
          paneInnerStyle={{
            textAlign: "center",
            padding:0,
          }}
          paneStyle={{
            backgroundImage:"url(/pane.png)",
            backgroundSize:"contain",
          }}
        >
          <div
            onClick={() => this.handleRouteTo(GetHomePath(User.IsCompanyAdmin))}
            style={{
              cursor:"pointer",
              marginTop: 16,
              marginBottom: 30,
            }}
          >
            <img src="/apcentral_white.png" style={{width:"55%"}} alt="" />
          </div>
          {navList}
        </CollapsibleLeftPane>
      );
    }

    const mobileExpenseCaptureCore = (IsMobile()) ? (
      <ExpenseCaptureCore
        history={history}
        location={location}
        noContent
        employeeId={EmployeeID}
        // nextPageNumber={nextPageNumber}
        onSetBeginFileUploadFunc={f => this.BeginFileUploadFunc = f}
        onComplete={this.handleCompleteExpenseUpload}
        reservationParams={GetNewReservationParams()}
        onApiError={this.handleApiError}
        onAlert={this.handleAlert}
      />
    ) : null;

    return (
      <div className={classes.root}>
        <div className={classes.navPaneAndTheRest}>
          
          {desktopNavPane}

          <div className={classes.theRest}>
            <AppBar position="static" color="transparent" className={classes.appBar} elevation={0}>
              {appBarContent}
            </AppBar>

            {mobileNavDrawer}

            <MultiUseDialog Details={DialogDetails} />

            {supportMenu}
            
            <div className={classes.screenContent} ref={instance => this.ScreenContentRef = instance}>
              {content}
              {progressIndicator}
            </div>
          </div>

        </div>

        {mobileBottomNavigation}
        {receiptCaptureFab}
        {mobileExpenseCaptureCore}
      </div>
    );
  }
}

UiCore.propTypes = {
  classes: PropTypes.object.isRequired,
  constrainedProgressIndicator: PropTypes.bool,
  title: PropTypes.string,
  funcForAutoRefresh: PropTypes.func,
  setScreenContentRef: PropTypes.func,
  showFabOnMobileForReceiptCapture: PropTypes.bool,
};

export default withRouter(withCookies(withStyles(styles, { withTheme: true })(UiCore)));