const cardTitleBase = {
  fontWeight:500,
};
export const CardClasses = theme => {
  return {
    infoCardContainer: {
      display:"flex",
      flexDirection:"column",
      textAlign:"center",
      marginTop:theme.spacing(1),
    },
    infoCardData: {
      fontSize:40,
      fontWeight:500,
    },
    infoCardTitle: {
      ...cardTitleBase,
      height:72,
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
    },  
    cardTitle: {
      ...cardTitleBase,
      fontSize:24,
    },
  };
}